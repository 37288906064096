import { Component } from '@angular/core';

@Component({
  selector: 'app-rest-days',
  templateUrl: './rest-days.component.html',
  styleUrls: ['./rest-days.component.scss']
})
export class RestDaysComponent {

}
