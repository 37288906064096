import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-update-areas',
  templateUrl: './update-areas.component.html',
  styleUrls: ['./update-areas.component.scss'],
})
export class UpdateAreasComponent implements OnInit {
  breadcrumbs: any = [];
  submitted: boolean = false;
  message: string = '';
  isSaved: boolean = false;
  areasUpdate: any = FormGroup;
  paramOfficeId: string | null = '';
  paramAreaId: string | null = '';
  areaData: any = [];

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramOfficeId = this.router.snapshot.paramMap.get('officeId');
    this.paramAreaId = this.router.snapshot.paramMap.get('areaId');

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de Areas',
        path: '/areas/list',
        link: true,
      },
      {
        name: 'Editar area',
        link: false,
      },
    ];

    this.validations();
    this.loadAreaData();
  }

  validations() {
    this.areasUpdate = this.fb.group({
      areasName: ['', [Validators.required]],
      areasSize: ['', [Validators.required]],
      price: ['', [Validators.required]],
      durationMin: ['', [Validators.required]],
      durationHour: ['', [Validators.required]],
      fullBody: [''],
      officeId: ['', [Validators.required]],
      areaId: ['', [Validators.required]],
    });

    this.areasUpdate.patchValue({
      areaId: this.paramAreaId,
      officeId: this.paramOfficeId,
    });
  }

  private durationOffice(countryCode: string, obj: any) {
    let duration = '';

    switch (countryCode) {
      case 'jal':
        duration = obj.duration;
        break;
      case 'sin':
        duration = obj.dueation_sin;
        break;
      case 'bc':
        duration = obj.duration_tj;
        break;
    }

    const splitTime = duration.split(':');

    return { hours: splitTime[0], minutes: splitTime[1] };
  }

  private priceOffice(countryCode: string, obj: any): Number {
    let price = 0;

    switch (countryCode) {
      case 'jal':
        price = obj.price;
        break;
      case 'sin':
        price = obj.price_sin;
        break;
      case 'bc':
        price = obj.price_tj;
        break;
    }

    return price;
  }

  loadAreaData(): void {
    this.api
      .get(`areas/singles/${this.paramAreaId}/${this.paramOfficeId}`)
      .subscribe({
        next: (data) => {
          this.areaData = data.area;
          const { hours, minutes } = this.durationOffice(
            this.areaData.office.country_code,
            this.areaData
          );
          const price = this.priceOffice(
            this.areaData.office.country_code,
            this.areaData
          );

          this.areasUpdate.patchValue({
            areasName: this.areaData.name,
            areasSize: this.areaData.area_type,
            price: price,
            durationMin: minutes,
            durationHour: hours,
          });
        },
        error: (err) => {},
      });
  }

  get f() {
    return this.areasUpdate.controls;
  }

  updateAreas(): void {
    this.submitted = true;

    if (this.areasUpdate.invalid) {
      this.submitted = false;
      return;
    }

    const timeAreaCompleted = `${this.areasUpdate.value.durationHour}:${this.areasUpdate.value.durationMin}`;

    const obj = {
      officeId: this.areasUpdate.value.officeId,
      areasName: this.areasUpdate.value.areasName,
      areasSize: this.areasUpdate.value.areasSize,
      price: this.areasUpdate.value.price,
      duration: timeAreaCompleted,
      fullBody: this.areasUpdate.value.fullBody ? 1 : 0,
    };

    this.api
      .update('areas/catalog', this.areasUpdate.value.areaId, obj)
      .subscribe({
        next: (data) => {
          this.submitted = false;
          this.isSaved = true;
          this.message = data.message;
        },
        error: (err) => {},
      });

    setTimeout(() => {
      this.message = '';
      this.isSaved = false;
    }, 3000);
  }
}
