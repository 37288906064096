import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeEs from '@angular/common/locales/es';
import {
  NgbModalModule,
  NgbModule,
  NgbAlertModule,
  NgbAccordionModule,
  NgbTypeaheadModule,
  NgbPaginationModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { Select2Module } from 'ng-select2-component';
import { AuthInterceptor } from './util/auth.interceptor';
import { CurrencyPipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CalendarUtilModule } from './util/calendar-util/calendar-util.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CalendarComponent } from './components/dashboard/calendar/calendar.component';

import { ClientsComponent } from './components/clients/clients.component';

import { EmployeesComponent } from './components/employees/employees.component';

import { CitiesComponent } from './components/cities/cities.component';
import { CreateCitiesComponent } from './components/cities/create-cities/create-cities.component';
import { ListCitiesComponent } from './components/cities/list-cities/list-cities.component';
import { EditCitiesComponent } from './components/cities/edit-cities/edit-cities.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { registerLocaleData } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeLayoutComponent } from './components/layouts/home-layout/home-layout.component';
import { LoginLayoutComponent } from './components/layouts/login-layout/login-layout.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { ListPaymentMethodsComponent } from './components/payment-methods/list-payment-methods/list-payment-methods.component';
import { CreatePaymentMethodsComponent } from './components/payment-methods/create-payment-methods/create-payment-methods.component';
import { UpdatePaymentMethodsComponent } from './components/payment-methods/update-payment-methods/update-payment-methods.component';
import { PackagesComponent } from './components/packages/packages.component';
import { CreatePackagesComponent } from './components/packages/create-packages/create-packages.component';
import { ListPackagesComponent } from './components/packages/list-packages/list-packages.component';
import { UpdatePackagesComponent } from './components/packages/update-packages/update-packages.component';
import { BreadcrumbsComponent } from './util/breadcrumbs/breadcrumbs.component';
import { CreatePaymentsComponent } from './components/payments/create-payments/create-payments.component';
import { ListPaymentsComponent } from './components/payments/list-payments/list-payments.component';
import { UpdatePaymentsComponent } from './components/payments/update-payments/update-payments.component';
import { RolesComponent } from './components/roles/roles.component';
import { CreateRolesComponent } from './components/roles/create-roles/create-roles.component';
import { ListRolesComponent } from './components/roles/list-roles/list-roles.component';
import { UpdateRolesComponent } from './components/roles/update-roles/update-roles.component';
import { CyclesComponent } from './components/cycles/cycles.component';
import { CreateCycleComponent } from './components/cycles/create-cycle/create-cycle.component';
import { ListCycleComponent } from './components/cycles/list-cycle/list-cycle.component';
import { UpdateCycleComponent } from './components/cycles/update-cycle/update-cycle.component';
import { BonusComponent } from './components/bonus/bonus.component';
import { CreateBonusComponent } from './components/bonus/create-bonus/create-bonus.component';
import { ListBonusComponent } from './components/bonus/list-bonus/list-bonus.component';
import { UpdateBonusComponent } from './components/bonus/update-bonus/update-bonus.component';
import { OfficesComponent } from './components/offices/offices.component';
import { CreateOfficeComponent } from './components/offices/create-office/create-office.component';
import { ListOfficeComponent } from './components/offices/list-office/list-office.component';
import { UpdateOfficeComponent } from './components/offices/update-office/update-office.component';
import { CreateEmployeeComponent } from './components/employees/create-employee/create-employee.component';
import { ListEmployeesComponent } from './components/employees/list-employees/list-employees.component';
import { UpdateEmployeesComponent } from './components/employees/update-employees/update-employees.component';
import { CreateClientComponent } from './components/clients/create-client/create-client.component';
import { UpdateClientComponent } from './components/clients/update-client/update-client.component';
import { ListClientComponent } from './components/clients/list-client/list-client.component';
import { ShowClientComponent } from './components/clients/show-client/show-client.component';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { CreateComponent } from './components/promotions/create/create.component';
import { ListComponent } from './components/promotions/list/list.component';
import { ProspectsComponent } from './components/prospects/prospects.component';
import { CreateComponent as CreateProspect } from './components/prospects/create/create.component';
import { ListsProspectsComponent } from './components/prospects/lists-prospects/lists-prospects.component';
import { SellsComponent } from './components/sells/sells.component';
import { ListSellsComponent } from './components/sells/list-sells/list-sells.component';
import { DisplaySellsComponent } from './components/sells/display-sells/display-sells.component';
import { ShowPackageComponent } from './components/packages/show-package/show-package.component';
import { BlackDebtsComponent } from './components/black-debts/black-debts.component';
import { ListBlackDebtsComponent } from './components/black-debts/list-black-debts/list-black-debts.component';
import { CreateBlackDebtsComponent } from './components/black-debts/create-black-debts/create-black-debts.component';
import { ShowBlackDebtsComponent } from './components/black-debts/show-black-debts/show-black-debts.component';
import { ShowPromotionComponent } from './components/promotions/show-promotion/show-promotion.component';
import { CabinsComponent } from './components/cabins/cabins.component';
import { ListCabinsComponent } from './components/cabins/list-cabins/list-cabins.component';
import { EditCabinsComponent } from './components/cabins/edit-cabins/edit-cabins.component';
import { CreateCabinsComponent } from './components/cabins/create-cabins/create-cabins.component';
import { UpdatePasswordComponent } from './components/employees/update-password/update-password.component';
import { SetPermissionsComponent } from './components/employees/set-permissions/set-permissions.component';
import { DailyPaymentsComponent } from './components/daily-payments/daily-payments.component';
import { ShowDailyPaymentsComponent } from './components/daily-payments/show-daily-payments/show-daily-payments.component';
import { ShowNextdayPaymentsComponent } from './components/daily-payments/show-nextday-payments/show-nextday-payments.component';
import { ListDailyPaymentsComponent } from './components/daily-payments/list-daily-payments/list-daily-payments.component';
import { WeeklySellsComponent } from './components/weekly-sells/weekly-sells.component';
import { ListWeeklySellsComponent } from './components/weekly-sells/list-weekly-sells/list-weekly-sells.component';
import { ShowWeeklySellsComponent } from './components/weekly-sells/show-weekly-sells/show-weekly-sells.component';
import { ProductsComponent } from './components/products/products.component';
import { CreateProductComponent } from './components/products/create-product/create-product.component';
import { ListProductsComponent } from './components/products/list-products/list-products.component';
import { ShowProductComponent } from './components/products/show-product/show-product.component';
import { CommissionsComponent } from './components/commissions/commissions.component';
import { ListCommissionsComponent } from './components/commissions/list-commissions/list-commissions.component';
import { CreateCommissionsComponent } from './components/commissions/create-commissions/create-commissions.component';
import { UpdateCommissionsComponent } from './components/commissions/update-commissions/update-commissions.component';
import { PayrollsComponent } from './components/payrolls/payrolls.component';
import { CreatePayrollComponent } from './components/payrolls/create-payroll/create-payroll.component';
import { ListPayrollComponent } from './components/payrolls/list-payroll/list-payroll.component';
import { ShowPayrollComponent } from './components/payrolls/show-payroll/show-payroll.component';
import { VacationsComponent } from './components/vacations/vacations.component';
import { ListComponent as ListVacationsComponent } from './components/vacations/list/list.component';
import { CreateComponent as CreateVacationsComponents } from './components/vacations/create/create.component';
import { UpdateComponent } from './components/vacations/update/update.component';
import { NextDayDisplayComponent } from './components/sells/next-day-display/next-day-display.component';
import { SmallBoxComponent } from './components/small-box/small-box.component';
import { ListComponent as ListSmallBoxComponent } from './components/small-box/list/list.component';
import { CreateComponent as CreateSmallBoxComponent } from './components/small-box/create/create.component';
import { AdvancePaymentsComponent } from './components/packages/advance-payments/advance-payments.component';
import { AreasComponent } from './components/areas/areas.component';
import { ListAreasComponent } from './components/areas/list-areas/list-areas.component';
import { CreateAreasComponent } from './components/areas/create-areas/create-areas.component';
import { UpdateAreasComponent } from './components/areas/update-areas/update-areas.component';
import { ShowComponent } from './components/areas/show/show.component';
import { ChangeOfficeComponent } from './components/clients/change-office/change-office.component';
import { RestDaysComponent } from './components/employees/rest-days/rest-days.component';
import { UsersCabinsComponent } from './components/cabins/users-cabins/users-cabins.component';

interface NgxSpinnerConfig {
  type?: any;
}

registerLocaleData(localeEs);
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CalendarComponent,
    ClientsComponent,
    EmployeesComponent,
    CitiesComponent,
    CreateCitiesComponent,
    ListCitiesComponent,
    EditCitiesComponent,
    LoginComponent,
    LogoutComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    PaymentMethodsComponent,
    PaymentsComponent,
    ListPaymentMethodsComponent,
    CreatePaymentMethodsComponent,
    UpdatePaymentMethodsComponent,
    PackagesComponent,
    CreatePackagesComponent,
    ListPackagesComponent,
    UpdatePackagesComponent,
    BreadcrumbsComponent,
    CreatePaymentsComponent,
    ListPaymentsComponent,
    UpdatePaymentsComponent,
    RolesComponent,
    CreateRolesComponent,
    ListRolesComponent,
    UpdateRolesComponent,
    CyclesComponent,
    CreateCycleComponent,
    ListCycleComponent,
    UpdateCycleComponent,
    BonusComponent,
    CreateBonusComponent,
    ListBonusComponent,
    UpdateBonusComponent,
    OfficesComponent,
    CreateOfficeComponent,
    ListOfficeComponent,
    UpdateOfficeComponent,
    CreateEmployeeComponent,
    ListEmployeesComponent,
    UpdateEmployeesComponent,
    CreateClientComponent,
    UpdateClientComponent,
    ListClientComponent,
    ShowClientComponent,
    PromotionsComponent,
    CreateComponent,
    ListComponent,
    ProspectsComponent,
    ListsProspectsComponent,
    CreateProspect,
    ListSellsComponent,
    DisplaySellsComponent,
    SellsComponent,
    ShowPackageComponent,
    BlackDebtsComponent,
    ListBlackDebtsComponent,
    CreateBlackDebtsComponent,
    ShowBlackDebtsComponent,
    ShowPromotionComponent,
    CabinsComponent,
    ListCabinsComponent,
    EditCabinsComponent,
    CreateCabinsComponent,
    UpdatePasswordComponent,
    SetPermissionsComponent,
    DailyPaymentsComponent,
    ShowDailyPaymentsComponent,
    ShowNextdayPaymentsComponent,
    ListDailyPaymentsComponent,
    WeeklySellsComponent,
    ListWeeklySellsComponent,
    ShowWeeklySellsComponent,
    ProductsComponent,
    CreateProductComponent,
    ListProductsComponent,
    ShowProductComponent,
    CommissionsComponent,
    ListCommissionsComponent,
    CreateCommissionsComponent,
    UpdateCommissionsComponent,
    PayrollsComponent,
    CreatePayrollComponent,
    ListPayrollComponent,
    ShowPayrollComponent,
    VacationsComponent,
    UpdateComponent,
    ListVacationsComponent,
    CreateVacationsComponents,
    NextDayDisplayComponent,
    SmallBoxComponent,
    ListSmallBoxComponent,
    CreateSmallBoxComponent,
    AdvancePaymentsComponent,
    AreasComponent,
    ListAreasComponent,
    CreateAreasComponent,
    UpdateAreasComponent,
    ShowComponent,
    ChangeOfficeComponent,
    RestDaysComponent,
    UsersCabinsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    NgbModalModule,
    NgbAlertModule,
    NgbAccordionModule,
    CalendarUtilModule,
    NgbModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    DragAndDropModule,
    Select2Module,
    NgbTypeaheadModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgxPaginationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
