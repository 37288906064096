import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  TemplateRef,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
  CalendarEvent,
  CalendarView,
  CalendarMonthViewDay,
  CalendarWeekViewBeforeRenderEvent,
  CalendarEventTimesChangedEvent,
  CalendarDayViewBeforeRenderEvent,
} from 'angular-calendar';
import { WeekViewHourColumn, EventColor } from 'calendar-utils';
import { Router } from '@angular/router';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SELLS_FOR, SOCIAL_NETWORK } from 'src/app/util/library';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { SharedService } from 'src/app/services/shared.service';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';

const colores: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#D1E8FF',
    secondary: '#D1E8FF',
  },
  blue_strong: {
    primary: '#1773CD',
    secondary: '#1773CD',
  },
  blue_light: {
    primary: '#1E90FF',
    secondary: '#1E90FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  purple: {
    primary: '#E0B0FF',
    secondary: '#E6CCEF',
  },
  purple_strong: {
    primary: '#BB6AEE',
    secondary: '#C191D2',
  },
  purple_light: {
    primary: '#DBAAFA',
    secondary: '#CDA2E8',
  },
  orange: {
    primary: '#ffb703',
    secondary: '#ff7f00',
  },
  cian: {
    primary: '#00ffff',
    secondary: '#19E5E6',
  },
  green: {
    primary: '#C7F1C2', //'#88bf76',
    secondary: '#C7F1C2', //'#88bf76',
  },
  green_strong: {
    primary: '#217e17',
    secondary: '#217e17',
  },
  green_light: {
    primary: '#559f46',
    secondary: '#559f46',
  },
};

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [NgbTimepickerConfig],
  styles: [
    `
      .bg-disabled {
        background-color: #cccccc !important;
        cursor: not-allowed;
      }
      .drag-active {
        position: relative;
        z-index: 1;
        pointer-events: none;
      }
      .drag-over {
        background-color: #eee;
      }
    `,
  ],
})
export class CalendarComponent implements OnInit {
  // Modals
  @ViewChild('modalContent', { static: false }) modalContent!: TemplateRef<any>;
  @ViewChild('createSession', { static: false })
  createSession!: TemplateRef<any>;
  @ViewChild('modalCloseBox', { static: false })
  modalCloseBox!: TemplateRef<any>;
  @ViewChild('changeOffice', { static: false }) changeOffice!: TemplateRef<any>;
  @ViewChild('confirmAssited', { static: false })
  confirmAssited!: TemplateRef<any>;
  @ViewChild('sessionsPerClient', { static: false })
  sessionsPerClient!: TemplateRef<any>;
  @ViewChild('closeCapacitation', { static: false })
  closeCapacitation!: TemplateRef<any>;
  @ViewChild('changeTime', { static: false }) changeTime!: TemplateRef<any>;
  @ViewChild('modalVacations', { static: false })
  modalVacations!: TemplateRef<any>;
  modalClose: any;

  // Variables Calendar
  locale: string = 'es';
  view: CalendarView = CalendarView.Day;
  viewDate: Date = new Date();

  /**
   * TEST
   */
  externalEvents: any[] = [
    {
      title: 'test',
      cabin: '1',
      color: 'red',
      id: 1,
      start: new Date(),
      end: new Date(),
      allDay: false,
      draggable: true,
    },
    {
      title: 'test 2',
      cabin: '1',
      color: 'red',
      id: 1,
      start: new Date(),
      end: new Date(),
      allDay: false,
      draggable: true,
    },
  ];

  // create events variables
  events1$!: Observable<CalendarEvent<any>[]>;
  events2$!: Observable<CalendarEvent<any>[]>;
  events3$!: Observable<CalendarEvent<any>[]>;
  events4$!: Observable<CalendarEvent<any>[]>;
  events5$!: Observable<CalendarEvent<any>[]>;
  events6$!: Observable<CalendarEvent<any>[]>;
  events7$!: Observable<CalendarEvent<any>[]>;
  events8$!: Observable<CalendarEvent<any>[]>;
  cabinName1: string = '';
  cabinName2: string = '';
  cabinName3: string = '';
  cabinName4: string = '';
  cabinName5: string = '';
  cabinName6: string = '';
  cabinName7: string = '';
  cabinName8: string = '';
  cabinStyleFlag1: boolean = false;
  cabinStyleFlag2: boolean = false;
  cabinStyleFlag3: boolean = false;
  cabinStyleFlag4: boolean = false;
  cabinStyleFlag5: boolean = false;
  cabinStyleFlag6: boolean = false;
  cabinStyleFlag7: boolean = false;
  cabinStyleFlag8: boolean = false;
  // Add type style to disabled calendar
  cabinStyleType1: string = '';
  cabinStyleType2: string = '';
  cabinStyleType3: string = '';
  cabinStyleType4: string = '';
  cabinStyleType5: string = '';
  cabinStyleType6: string = '';
  cabinStyleType7: string = '';
  cabinStyleType8: string = '';
  // create  events variables

  refresh = new Subject<void>();

  activeDayIsOpen: boolean = false;
  hourStartAppt: number = 8;
  hourEndAppt: number = 22;
  hourColumns!: WeekViewHourColumn[];
  selectedDayViewDate!: Date;
  selectedMonthViewDay!: CalendarMonthViewDay;
  selectedDays: any = [];
  timeStart: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  startSession: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  startVacations: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  startCapacitation: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  startMeeting: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  timeEnd: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  timeEnd2: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  endSession: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  endVacations: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  endCapacitation: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  endMeeting: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  changeTimeStart: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  changeTimeEnd: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  step: any;

  // Variables system
  appoinmentForm: any = FormGroup;
  appoinmentExists: any = FormGroup;
  appoinmentExistsV1: any = FormGroup;
  appoinmentValoration: any = FormGroup;
  paymentsForm: any = FormGroup;
  extraPaymentsForm: any = FormGroup;
  missingForm: any = FormGroup;
  finalComment: any = FormGroup;
  changeForm: any = FormGroup;
  rescheduleForm: any = FormGroup;
  formSearch: any = FormGroup;
  formCalendarSearch: any = FormGroup;
  formAddedPayments: any = FormGroup;
  formVacations: any = FormGroup;
  formCapacitation: any = FormGroup;
  formMeetings: any = FormGroup;
  cancelForm: any = FormGroup;
  updateFormSession: any = FormGroup;
  modalData!: {
    action: string;
    event: CalendarEvent;
  };
  clients: any;
  packages: any;
  employees: any;
  payments: any;
  offices: any;
  payment_methods: any;
  clientsFilter: any = [];
  packagesFilter: any = [];
  employeesFilter: any = [];
  isClientExists: boolean | null = null;
  isClientSection: boolean = true;
  packageValidation: boolean = false;
  paymentsValidation: boolean = false;
  totalAreas: number = 0;
  clientSection: boolean = true;
  appoinmentSection: boolean = false;
  paymentSection: boolean = false;
  clientValidation: boolean = false;
  submitted: boolean = false;
  submittedExist: boolean = false;
  displayInfoAreas: any = [];
  totalCostAreas: number = 0;
  totalHours: number = 0;
  arrayNumber: number[] = [];
  arrayPayments: any[] = [];
  classNumber: number = 12;
  socialNetworkFlag: boolean = false;
  referalFlag: boolean = false;
  inputRecurrentFlag: boolean = false;
  socialNetworks: any = [];
  sellsFor: any = [];
  isVisiblePayment: boolean = false;
  enabledPayments: any = [];
  loggedEmployee: any = [];
  isExistsAppoinment: boolean = false;
  isNewAppoinment: boolean = false;
  sessionAppExists: any = [];
  sessionsData: any = [];
  paymentsData: any = [];
  showTables: boolean = false;
  displayInfoAppoinment: any = [];
  allAppoinments: any = [];
  completeAppoinments: any = [];
  sessionInfo: any = [];
  paymentsInfo: any = [];
  disableButton: boolean = false;
  isActiveForm: boolean = false;
  isPaid: boolean = true;
  paymentsFilter: any = [];
  submitedPayments: boolean = false;
  messagesPayments: string = '';

  //new variables
  missingAreasSubmitted: boolean = false;
  packageFilter: any = [];
  flagClientOpen: boolean = false;
  clientColSize: number = 12;
  timeSession: string = '';
  assistedFlag: boolean = true;
  submitAssisted: boolean = false;
  isPaidFlag: boolean = false;
  disabledCheck: boolean = false;
  disabledAll: boolean = false;
  idsArray: any = [];
  flagMessageAreas: boolean = false;
  messageAreas: string = '';
  areasWorked: any = [];
  areasWorkedFlag: boolean = false;
  perClients: any = [];
  perClientsOptions: any = [];
  submitComment: boolean = false;
  messageGeneralComment: string = '';
  saldoTotal: any;
  saldoTotalExtra: any = 0;
  completeTransaction: boolean = false;
  disabledNewSession: boolean = false;
  messageNewSession: string = '';
  flagSessionClose: boolean = false;
  flagPaymentDone: boolean = false;
  flagAreasWorked: boolean = false;
  flagIsPayment: boolean = false;
  flagDisabledButton: boolean = false;
  sellsPerOffice: any = [];
  cabins: any = [];
  columnsSizes: number = 12;
  totalCabins: number = 0;
  lastProduct: number = 0;
  areasInfoWorked: any = [];
  closeBox: any = [];
  closeBoxChange: any = [];
  flagSessionActive: boolean = false;
  submit: boolean = false;
  flagTypeSession: number = 0;
  flagTypeClient: number = 0;
  flagEndTimeValidation: boolean = false;
  keyRole: string = '';
  typeColor: string = '';
  disabledAcceptanceButton: boolean = false;
  messageAcceptance: string = '';

  // new flags
  acceptedSessions: boolean = false; // display payments and extra products sell and hide buttons true
  messageTextAssisted: string = '';
  paymentDone: boolean = false; // display areas worked and true hide payments
  messageTextPaymentDone: string = '';
  areasWorkedDone: boolean = false; // displat finished sessionn form and true hide areas worked
  messageAreasWorked: string = '';
  finishSession: boolean = false; // display finished form and true hide form
  messageTextFinishSession: string = '';
  reschedule: boolean = false;
  submitReschedule: boolean = false;
  messageTextRescchedule: string = '';
  submitExtraPayments: boolean = false;
  extraPaymentsSessions: boolean = false;
  messageExtraPaymentSession: string = '';
  extraShowFlag: boolean = false;
  paymentMethodFlag: boolean = false;
  newPaymentsAdded: boolean = false;
  submittedApf: boolean = false;
  messageAdvancePayment: string = '';
  submittedVacationsForm: boolean = false;
  submittedCapacitationForm: boolean = false;
  submittedMeetings: boolean = false;
  flagDisabledSection: boolean = false;
  dynamicMessage: string = 'Agenda una sesión de depilación';
  totalAreasWorked: number = 0;
  totalAreasMarked: number = 0;
  disabledScheduleButton: boolean = false;
  flagSimpleAreas: boolean = false;
  flagSimpleCheck: boolean = false;
  areasPackagesSelected: any;
  storeAreasPackageId: any = [];
  dateToSend: string = '';
  hasRemaining: boolean = false;
  typeSession: boolean = false;
  newExtraPays: boolean = false;
  clientName: string = '';
  clientDataSess: any = [];
  perClientDepilation: any = [];
  perClientValoration: any = [];
  perClientsChecks: any = [];
  flagSearchModal: boolean = false;
  flagValZero: boolean = false;
  messageZero: string = '';
  disabledAmountField: boolean = true;
  oldPayment: string = '';
  displayAdvancePaymentsButton: boolean = true;
  noAssistFlag: boolean = false;
  // temporal data
  tempCompleteSession: any = '';
  tempPackageSelected: any = [];
  tempCompleteTime: any = '';
  valorationFlag: boolean = false;
  showScheduleSession: number = 0;
  editHourSession: boolean = false;
  updateSessionFlag: boolean = false;

  // New Variables
  packagesAreasArray: any = [];
  packagesAreasSelected: any = [];
  changeTimeForm: any = FormGroup;
  disabledChangeTimeMessage: boolean = false; // check if its required

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private fb: FormBuilder,
    private fb2: FormBuilder,
    private fb3: FormBuilder,
    private fb4: FormBuilder,
    private fb5: FormBuilder,
    private fb6: FormBuilder,
    private fb7: FormBuilder,
    private fb8: FormBuilder,
    private fb9: FormBuilder,
    private fv: FormBuilder,
    private cfc: FormBuilder,
    private aev1: FormBuilder,
    private fm: FormBuilder,
    private commentForm: FormBuilder,
    private ma: FormBuilder,
    private modal: NgbModal,
    private currencyPipe: CurrencyPipe,
    private router: Router,
    private sharedService: SharedService,
    private fs: FormBuilder,
    private fcs: FormBuilder,
    private usf: FormBuilder,
    public config: NgbTimepickerConfig,
    private ctf: FormBuilder
  ) {
    this.getEmployees();
    this.getVacations();
  }

  ngOnInit(): void {
    this.socialNetworks = SOCIAL_NETWORK;
    this.sellsFor = SELLS_FOR;

    this.fetchEvents();
    this.getCabins();
    this.getCompleteSessionsPerOffice();
    this.getClients();
    this.getOffices();
    this.getPayments();
    this.getPaymentMethods();
    this.loggedInEmployee();
    this.perClient();
    this.sellsData();

    this.validations();
    this.validationsExists();
    this.validationsPayments();
    this.validationMissingAreas();
  }

  /**
   * Call to all the init functions **  start **
   */

  fetchEvents() {
    const totalCabins = this.api.getTotalCabinsPerOffice;
    for (let i = 1; i <= Number(totalCabins); i++) {
      this.getsCabinsData(i);
    }
  }

  getCabins() {
    this.api.get('cabin').subscribe({
      next: (cabins) => {
        this.cabins = cabins.cabins;
        const total = this.cabins.length;
        this.columnSizesCabins(Number(total));
      },
      error: (err) => {},
    });
  }

  loadData(): void {
    this.getEmployees();
    this.getClients();
    //this.getOffices();
    this.getPayments();
    this.getPaymentMethods();
    this.loggedInEmployee();
    this.perClient();
    this.sellsData();
  }

  perClient() {
    this.api.get('clients').subscribe({
      next: (client) => {
        this.perClients = client.clients;
        this.perClientsChecks = client.clients;

        const perClientsFormat = this.perClients.map((perClientData: any) => {
          return {
            value: perClientData.id,
            label: perClientData.complete,
          };
        });

        console.log('Clients Format: ', perClientsFormat);
        this.perClientsOptions = [{ options: perClientsFormat }];
      },
      error: (err) => {},
    });
  }

  async sessionInformation(clientId: number) {
    return await firstValueFrom(
      this.api.getById('appoinments/clients', clientId)
    );
    /*this.api.get('appoinments/sessions').subscribe({
      next: (appInfo) => {
        this.sessionAppExists = appInfo.appoinments;
      },
      error: (err) => {},
    });*/
  }

  async getPackages(clientId: number) {
    return await firstValueFrom(this.api.getById('packages/clients', clientId));
    /* this.api.get('packages').subscribe({
      next: (packages) => {
        this.packages = packages.packages;
        const packagesFormat = this.packages.map((packageName: any) => {
          console.log('packages: ', packageName);
          return {
            value: packageName.id,
            label: packageName.session,
          };
        });
        this.packagesFilter = [{ options: packagesFormat }];
      },
      error: (err) => {},
    }); */
  }

  async getDataPerSessId(sess_id: any) {
    const dateFormated = this.splitDate();
    return await firstValueFrom(
      this.api.get(`sessions/agenda/${sess_id}`)
      //this.api.get(`appoinments/all/offices/${dateFormated}/${sess_id}`)
    );
  }

  getEmployees() {
    this.api.get('employees').subscribe({
      next: (employees) => {
        this.employees = employees.employees.filter(
          (data: any) => data.is_displayed
        );
        const employeesPackage = this.employees.map((employeeData: any) => {
          return {
            value: employeeData.id,
            label: `${employeeData.first_name} ${employeeData.last_name}`,
          };
        });
        this.employeesFilter = employeesPackage;
      },
      error: (err) => {},
    });
  }

  getClients() {
    this.api.get('clients').subscribe({
      next: (clients) => {
        this.clients = clients.clients;
        const clientsFormat = this.clients.map((client: any) => {
          return {
            value: client.id,
            label: `${client.first_name} ${client.last_name}`,
          };
        });
        this.clientsFilter = [{ options: clientsFormat }];
      },
      error: (err) => {},
    });
  }

  vacations: any = [];
  getVacations() {
    this.api.get('vacations').subscribe({
      next: (data) => {
        this.vacations = data.data;
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  getPayments() {
    this.api.get('payments').subscribe({
      next: (payments) => {
        this.payments = payments.payments;
      },
      error: (err) => {},
    });
  }

  getPaymentMethods() {
    this.api.get('payment_methods').subscribe({
      next: (paymentMethods) => {
        this.payment_methods = paymentMethods.payment_methods;
      },
      error: (err) => {},
    });
  }

  sellsData() {
    this.api.getById('remaining', Date.now()).subscribe({
      next: (sells) => {
        this.sellsPerOffice = sells.data_sells;
      },
      error: (err) => {},
    });
  }

  getCompleteSessionsPerOffice() {
    const dateFormated = this.splitDate();
    this.api.get(`appoinments/all/offices/${dateFormated}`).subscribe({
      next: (data) => {
        console.log('datos amigos: ', data);
        this.completeAppoinments = data.appoinments;
      },
      error: (err) => {},
    });
  }

  loggedInEmployee() {
    this.api.get('employee').subscribe({
      next: (employee) => {
        if (typeof employee !== undefined) {
          const startHour = employee.employee.office.start.split(':');
          const endHour = employee.employee.office.end.split(':');

          this.hourStartAppt = Number(startHour[0]);
          this.hourEndAppt = Number(endHour[0]);
          this.loggedEmployee = employee.employee;
          this.keyRole = employee.employee.roles.key.toLowerCase();
        }
      },
      error: (err) => {},
    });
  }

  close() {
    this.resetAppoinment();
    this.resetFlags();
    this.flagTypeSession = 0;
    this.flagTypeClient = 0;
    this.flagSearchModal = false;

    this.modalClose.close();
  }
  /**
   * Call to all the init functions ** end **
   */

  validations(): void {
    this.formSearch = this.fs.group({
      searchClient: [''],
    });

    this.formCalendarSearch = this.fcs.group({
      cal_search: [''],
    });

    this.finalComment = this.commentForm.group({
      final_comment: [''],
      employee_id: ['', [Validators.required]],
    });

    this.appoinmentForm = this.fb.group({
      //section 1
      client_id: ['', Validators.required],
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      first_last_name: ['', [Validators.required, Validators.minLength(3)]],
      last_last_name: ['', [Validators.required, Validators.minLength(3)]],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(12),
        ],
      ],
      dob: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      cp: ['', []],
      //section 2
      area_session: ['', [Validators.required]],
      sessions: ['', [Validators.required]],
      duration: ['', [Validators.required]],
      duration_format: ['', [Validators.required]],
      start_date_threatment: ['', [Validators.required]],
      price: ['', [Validators.required]],
      observations: ['', [Validators.required, Validators.minLength(5)]],
      firstSession: ['', [Validators.required]],
      // section 3
      total_cost: ['', [Validators.required]],
      advance: ['', [Validators.required]],
      remaining_payments: ['', [Validators.required]],
      total_payments: ['', [Validators.required]],
      number_payments: ['', [Validators.required]],
      sells: ['', []],
      social_network: ['', []],
      client_referer: ['', []],
      turno: ['', []],
      enfermera: ['', []],
      officeId: ['', [Validators.required]],
      officeName: ['', [Validators.required]],
      employeeId: ['', [Validators.required]],
    });

    this.changeForm = this.fb5.group({
      office_id_change: ['', [Validators.required]],
    });

    this.rescheduleForm = this.fb6.group({
      new_day: ['', [Validators.required]],
      sess_id: ['', [Validators.required]],
    });

    this.formVacations = this.fv.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      officeId: [''],
      cabinId: [''],
      employeeId: ['', [Validators.required]],
      tempEmployee: [''],
    });

    this.formCapacitation = this.fb9.group({
      startDate: ['', [Validators.required]],
      officeId: ['', [Validators.required]],
      cabinId: ['', [Validators.required]],
      employeeId: ['', [Validators.required]],
      tempEmployee: [''],
    });

    this.formMeetings = this.fm.group({
      startDate: ['', [Validators.required]],
      cabinId: ['', [Validators.required]],
      cabinNum: ['', [Validators.required]],
      isrepetitive: [''],
      repeatDay: [''],
    });

    this.changeTimeForm = this.ctf.group({
      sess_id: ['', [Validators.required]],
    });

    this.updateFormSession = this.usf.group({
      sess_id: ['', [Validators.required]],
      cabinNumOrigin: [''],
      cabinNum: [''],
      duration: [''],
    });
  }

  validationsExists() {
    this.appoinmentExists = this.fb2.group({
      type: ['', [Validators.required]],
      client_id: ['', [Validators.required]],
      appoinmentId: ['', [Validators.required]],
      package_id: ['', [Validators.required]],
      cabinId: ['', [Validators.required]],
      dateSession: ['', [Validators.required]],
      sessionNumber: ['', [Validators.required]],
      employee_id: ['', [Validators.required]],
      employee_id_temp: [''],
      office_id: ['', [Validators.required]],
      total_sessions: ['', [Validators.required]],
      comments: [''],
      cabinNum: [''],
      flagCustom: [''],
      customAreasSessions: [''],
    });

    this.appoinmentExistsV1 = this.aev1.group({
      client_id: ['', [Validators.required]],
      package_id: ['', [Validators.required]],
      cabinId: ['', [Validators.required]],
      dateSession: ['', [Validators.required]],
      employee_id: ['', [Validators.required]],
      employee_id_temp: [''],
      comments: [''],
      cabinNum: [''],
    });

    this.appoinmentValoration = this.fb4.group({
      type_user: [''],
      client_id_val: [''],
      appoinmentId_val: [''],
      name_no_client: [''],
      employee_id_val: ['', [Validators.required]],
      employee_id_val_temp: [''],
      dateSession_val: ['', [Validators.required]],
      office_id_val: ['', [Validators.required]],
      comments_val: [''],
      cabinNum_val: [''],
      cabinId_val: ['', [Validators.required]],
    });
  }

  validationsPayments() {
    this.paymentsForm = this.fb3.group({
      paymentMethodId: ['', [Validators.required]],
      sessionId: ['', [Validators.required]],
      paymentId: ['0', [Validators.required]],
      remainingPaymentId: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      terminal: [''],
      apptId: ['', [Validators.required]],
      change_amount: [''],
      original_amount: [''],
    });

    this.extraPaymentsForm = this.fb7.group({
      extra: [''],
      sessionId: ['', [Validators.required]],
      paymentMethodId: ['', [Validators.required]],
      terminal: [''],
      paymentId: ['0', [Validators.required]],
      amount: ['', [Validators.required]],
      remainingPayment: ['', [Validators.required]],
    });

    this.formAddedPayments = this.fb8.group({
      sessionId: ['', [Validators.required]],
      //remainingPaymentId: [''],
      paymentMethodId: ['', [Validators.required]],
      terminal: [''],
      paymentId: ['0', [Validators.required]],
      amount: ['', [Validators.required]],
      apptId: ['', [Validators.required]],
    });

    this.cancelForm = this.cfc.group({
      comments: [''],
      id: [''],
      type: [''],
    });
  }

  validationMissingAreas() {
    this.missingForm = this.ma.group({
      session_id: ['', [Validators.required]],
      areas: ['', [Validators.required]],
      appnt_id: ['', [Validators.required]],
      package_id: ['', [Validators.required]],
    });
  }

  get f() {
    return this.appoinmentForm.controls;
  }

  get fe() {
    return this.appoinmentExists.controls;
  }

  get fe2() {
    return this.appoinmentValoration.controls;
  }

  get fvf() {
    return this.formVacations.controls;
  }

  get cf() {
    return this.changeForm.controls;
  }

  get ms() {
    return this.missingForm.controls;
  }

  get reg() {
    return this.rescheduleForm.controls;
  }

  get apf() {
    return this.formAddedPayments.controls;
  }

  get fc() {
    return this.formCapacitation.controls;
  }

  buttonClear(): void {
    const currentDate = new Date();
    const data = {
      value: 'calendarPerClientDate',
      date: `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}`,
    };

    this.sharedService.callMethodName(data);
    this.formSearch.get('searchClient').reset();
  }

  changeElement(evt: any): void {
    const numberData = evt.target.value;

    if (numberData == 1 || numberData == 2) {
      this.saldoTotalExtra =
        Number(this.saldoTotalExtra) + 50 - Number(this.lastProduct);

      if (this.lastProduct == 0 || this.lastProduct == 100) {
        this.lastProduct = 50;
      }

      this.extraPaymentsForm.patchValue({
        amount: 50,
      });
    } else {
      this.saldoTotalExtra =
        Number(this.saldoTotalExtra) + 100 - Number(this.lastProduct);
      if (this.lastProduct == 0 || this.lastProduct == 50) {
        this.lastProduct = 100;
      }
      this.extraPaymentsForm.patchValue({
        amount: 100,
      });
    }
  }

  calendarPerClientDate(id: any): void {
    if (id !== undefined) {
      this.api.getById('sessions/client', id).subscribe({
        next: (dataSess) => {
          const data: any = {
            value: 'calendarPerClientDate',
            date: dataSess.session.date_start,
          };

          this.modalClose.close();
          this.sharedService.callMethodName(data);
          this.getCompleteSessionsPerOffice();
          this.formSearch.get('searchClient').reset();
        },
        error: (err) => {},
      });
    }
  }

  showDataPerClient(evt: any): void {
    if (evt.value === undefined) return;

    this.flagSearchModal = false;
    console.log('cliente seleccion: ', evt.value);
    const clientDataSess = this.perClientsChecks.filter(
      (client: any) => client.id === evt.value
    );

    if (clientDataSess.length !== 0) {
      this.clientName = clientDataSess[0].complete;
      console.log(this.clientName);

      this.api.get(`sessions/perclient/${evt.value}`).subscribe({
        next: (clients) => {
          this.perClients = clients.sessions;

          this.modalClose = this.modal.open(this.sessionsPerClient, {
            size: 'xl',
          });

          /*  const perClientsFormat = this.perClients.map((perClientData: any) => {
            return {
              value: perClientData.id,
              label: `${perClientData.client.name} - Sesion ${
                perClientData.type_session == 1
                  ? perClientData.number_session
                  : 'de Valoración'
              }`,
            };
          }); */
          //this.perClientsOptions = [{ options: perClientsFormat }];
        },
        error: (err) => {},
      });
    } else {
      this.flagSearchModal = true;
      this.modalClose = this.modal.open(this.sessionsPerClient, {
        size: 'xxl',
      });
    }
  }

  createAppoinment() {
    this.disableButton = true;
    this.submitted = true;

    if (this.appoinmentForm.invalid) {
      return;
    }

    const obj = {
      clients: {
        first_name: this.appoinmentForm.value.first_name,
        last_name: `${this.appoinmentForm.value.first_last_name} ${this.appoinmentForm.value.last_last_name}`,
        email: this.appoinmentForm.value.email,
        phone: this.appoinmentForm.value.phone,
        dob: this.appoinmentForm.value.dob,
        cp: this.appoinmentForm.value.cp,
      },
      client_id: this.appoinmentForm.value.client_id,
      area_session: this.appoinmentForm.value.area_session,
      sessions: this.appoinmentForm.value.sessions,
      duration: this.appoinmentForm.value.duration,
      duration_format: this.appoinmentForm.value.duration_format,
      start_date_threatment: this.appoinmentForm.value.start_date_threatment,
      price: this.appoinmentForm.value.price,
      observations: this.appoinmentForm.value.observations,
      startTime: this.timeStart,
      endTime: this.timeEnd,
      total_cost: this.appoinmentForm.value.total_cost
        .replace('$', '')
        .replace(',', ''),
      advance: this.appoinmentForm.value.advance,
      remaining_payments: this.appoinmentForm.value.remaining_payments
        .replace('$', '')
        .replace(',', ''),
      total_payments: this.appoinmentForm.value.total_payments,
      number_payments: this.appoinmentForm.value.number_payments,
      sells: this.appoinmentForm.value.sells,
      social_network: this.appoinmentForm.value.social_network,
      client_referer: this.appoinmentForm.value.client_referer,
      turno: this.appoinmentForm.value.turno,
      enfermera: this.appoinmentForm.value.enfermera,
      officeId: this.appoinmentForm.value.officeId,
      employeeId: this.appoinmentForm.value.employeeId,
      firstSession: this.appoinmentForm.value.firstSession,
    };

    this.submitted = false;

    this.api.create('appoinments', obj).subscribe({
      next: (appoinment) => {
        this.submitted = false;

        this.close();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['redirect-calendar']);
      },
      error: (err) => {},
    });
  }

  typeUserForm(evt: any): void {
    if (evt.target.value == 1) {
      this.flagTypeClient = 1;
    } else if (evt.target.value == 2) {
      this.flagTypeClient = 2;
      this.appoinmentValoration.patchValue({
        client_id_val: 0,
      });
    } else {
      this.flagTypeClient = 0;
      this.appoinmentValoration.patchValue({
        client_id_val: 0,
      });
    }
  }

  valorationSession() {
    this.flagValZero = false;
    this.submit = true;
    this.disableButton = true;
    this.submittedExist = true;
    this.flagEndTimeValidation = false;
    this.disabledNewSession = false;
    this.disabledScheduleButton = false;

    if (this.appoinmentValoration.invalid) {
      this.submittedExist = true;
      this.disableButton = false;
      return;
    }

    /*console.log(Object.keys(this.endSession.hour).length, this.endSession.hour);
    if (this.endSession.hour == '' || this.endSession.minute == '') {
      console.log('holas', this.endSession);
      this.flagEndTimeValidation = true;
      this.disableButton = false;
      return;
    }*/

    if (this.appoinmentValoration.value.type_user == 1) {
      if (this.appoinmentValoration.value.client_id_val == 0) {
        this.flagValZero = true;
        this.disableButton = false;
        this.messageZero =
          'El cliente registrado seleccionado es incorrecto. Favor de verificarlo o recargar la pagina e intentar registar la sesion de nueva cuenta.';
        return;
      }
    } else {
      if (this.appoinmentValoration.value.name_no_client.trim() == '0') {
        this.flagValZero = true;
        this.disableButton = false;
        this.messageZero =
          'El valor de cliente no registrado es incorrecto. Favor de verificarlo o recargar la pagina e intentar registar la sesion de nueva cuenta.';
        return;
      }
    }

    const obj = {
      type_valoration: Number(this.appoinmentValoration.value.type_user),
      client_id: this.appoinmentValoration.value.client_id_val,
      nameValoration: this.appoinmentValoration.value.name_no_client,
      user_id: this.appoinmentValoration.value.employee_id_val,
      dateSession: this.appoinmentValoration.value.dateSession_val,
      office_id: this.appoinmentValoration.value.office_id_val,
      cabinId: this.appoinmentValoration.value.cabinId_val,
      startTime: this.startSession,
      endTime: this.endSession,
      assisted: 2,
      comments: this.appoinmentValoration.value.comments_val,
    };

    console.log('obj valoracion: ', obj);
    this.api.create('sessions/valorations', obj).subscribe({
      next: (valoration) => {
        if (valoration.error == true) {
          this.flagValZero = true;
          this.disableButton = false;
          this.messageZero = valoration.message;
        } else {
          this.submittedExist = false;
          this.submit = false;

          if (valoration.session.is_error == true) {
            this.disableButton = false;
            this.messageNewSession = valoration.message;
            this.disabledNewSession = true;
            this.disabledScheduleButton = true;
          } else {
            this.disabledNewSession = false;
            this.flagValZero = false;
            this.close();
            this.fetchEvents();
            this.getCompleteSessionsPerOffice();
            this.refresh.next();
          }
        }
      },
      error: (err) => {},
    });
  }

  isEnabledCustomAreas(event: any) {
    if (event.target.value == 0) {
      this.showTables = true;
    } else {
      this.showTables = false;
      console.log('this.timeSession: ', this.timeSession);
      this.endSession = this.tempCompleteTime;
      this.flagSimpleAreas = false;
      this.storeAreasPackageId = [];

      this.appoinmentExists.patchValue({
        customAreasSessions: this.storeAreasPackageId,
        flagCustom: 0,
      });
    }
  }

  // Nnew Method
  existsAppoinmentV1() {
    this.submit = true;
    this.disableButton = true;
    this.submittedExist = false;
    this.flagSessionActive = false;
    this.disabledNewSession = false;
    this.disabledScheduleButton = false;

    if (this.appoinmentExistsV1.invalid) {
      this.submittedExist = true;
      this.disabledScheduleButton = true;

      return;
    }

    const obj = {
      clientId: this.appoinmentExistsV1.value.client_id,
      cabinId: this.appoinmentExistsV1.value.cabinId,
      cabinNum: this.appoinmentExistsV1.value.cabinNum,
      comments: this.appoinmentExistsV1.value.comments,
      userId: this.appoinmentExistsV1.value.employee_id,
      packages: this.appoinmentExistsV1.value.package_id,
      dateSession: this.appoinmentExistsV1.value.dateSession,
      startHour: this.startSession,
      endHour: this.endSession,
    };

    console.table(obj);
    this.api.create('v1/sessions', obj).subscribe({
      next: (sessions) => {
        if (sessions.sessions.is_error) {
          this.disableButton = false;
          this.disabledNewSession = true;
          this.disabledScheduleButton = true;
          this.messageNewSession = sessions.message;
        } else {
          this.disableButton = true;
          this.disabledNewSession = false;
          this.flagTypeSession = 0;
          this.flagTypeClient = 0;
          this.submittedExist = false;
          this.submit = false;
          this.close();
          this.fetchEvents();
          this.getCompleteSessionsPerOffice();
          this.resetAppoinment();
          this.resetFlags();
          this.refresh.next();
        }
        this.showTables = false;
        this.flagSimpleAreas = false;
      },
      error: (err) => {},
    });
  }

  existAppoinment() {
    this.submit = true;
    this.disableButton = true;
    this.submittedExist = false;
    this.flagSessionActive = false;
    this.disabledNewSession = false;
    this.disabledScheduleButton = false;

    console.log('caniba numero: ', this.appoinmentExists.value.cabinNum);
    const checkHours = this.isFoodTimes(
      this.startSession,
      this.endSession,
      this.appoinmentExists.value.cabinId,
      this.appoinmentExists.value.employee_id
    );
    console.log('holas: ', checkHours, this.endSession.hour == '');
    /*if (checkHours) {
      console.log('entro al checkhours');
      this.flagSessionActive = true;
      return;
    } */

    if (this.appoinmentExists.invalid) {
      console.log('Invalid', this.appoinmentExists.invalid);
      this.submittedExist = true;
      this.disabledScheduleButton = true;
      return;
    }

    const obj = {
      client_id: this.appoinmentExists.value.client_id,
      appoinmentId: this.appoinmentExists.value.appoinmentId,
      dateSession: this.appoinmentExists.value.dateSession,
      cabinId: this.appoinmentExists.value.cabinId,
      timeStart: this.startSession,
      endTime: this.endSession,
      sessionNumber: this.appoinmentExists.value.sessionNumber,
      user_id: this.appoinmentExists.value.employee_id,
      assisted: 2,
      comments: this.appoinmentExists.value.comments,
      customAreas: this.appoinmentExists.value.customAreasSessions,
      flagCustom: this.appoinmentExists.value.flagCustom,
      completeSession: this.appoinmentExists.value.type,
    };

    console.log('object: ', obj);
    this.api.create('sessions', obj).subscribe({
      next: (sessions) => {
        if (sessions.sessions.is_error) {
          this.disableButton = false;
          this.disabledNewSession = true;
          this.disabledScheduleButton = true;
          this.messageNewSession = sessions.message;
        } else {
          this.disableButton = true;
          this.disabledNewSession = false;
          this.flagTypeSession = 0;
          this.flagTypeClient = 0;
          this.submittedExist = false;
          this.submit = false;
          this.close();
          this.fetchEvents();
          this.getCompleteSessionsPerOffice();
          this.resetAppoinment();
          this.resetFlags();
          this.refresh.next();
        }
        this.showTables = false;
        this.flagSimpleAreas = false;
      },
      error: (err) => {},
    });
  }

  private isFoodTimes(
    startSession: any,
    endSession: any,
    cabinId: number,
    userId: number
  ): boolean {
    const startHour = `${startSession.hour}:${startSession.minute}:00`;
    const endHour = `${endSession.hour}:${endSession.minute}:00`;
    let flagCheckHours: boolean = false;
    let employeeStartFood = '',
      employeeEndFood = '';

    const userData = this.employees.filter((emp: any) => {
      if (emp.id == userId) {
        employeeStartFood = emp.schedule.food_start;
        employeeEndFood = emp.schedule.food_end;

        return emp;
      }

      return;
    });

    if (userData.length == 0) {
      return true;
    }

    if (
      employeeStartFood >= startHour &&
      employeeStartFood <= startHour &&
      employeeEndFood <= endHour
    ) {
      console.log('entro');
      flagCheckHours = true;
    }

    return flagCheckHours;
  }

  deleteSession(idSession: number): void {
    this.api.delete('sessions', idSession).subscribe({
      next: (session) => {
        this.fetchEvents();
        this.getCompleteSessionsPerOffice();
        this.modalClose.close();
        this.refresh.next();
      },
      error: (err) => {},
    });
  }

  missingAreasSession() {
    this.missingAreasSubmitted = true;

    if (this.missingForm.invalid) {
      console.log(this.missingForm);
      return;
    }

    const obj = {
      areas: this.missingForm.value.areas,
      appoinment: this.missingForm.value.appnt_id,
      session: this.missingForm.value.session_id,
      package: this.missingForm.value.package_id,
    };

    this.api.create('sessions/worked', obj).subscribe({
      next: (missing) => {
        this.areasWorkedDone = true;
        this.missingAreasSubmitted = false;
        this.flagMessageAreas = true;
        this.messageAreas = missing.message;
        this.messageAreasWorked = missing.message;
        this.flagAreasWorked = true;

        setTimeout(() => {
          this.messageAreasWorked = '';
        }, 3000);

        this.fetchEvents();
        this.getCompleteSessionsPerOffice();

        this.sessionInfo[0] = missing.areas.map((areaData: any) => {
          return {
            price: areaData.price,
            name: areaData.name,
            duration: areaData.duration,
            total: areaData.number,
            remaining: areaData.number - areaData.remaining,
            finished: areaData.finished,
            active: areaData.active,
          };
        });
      },
      error: (err) => {},
    });
  }

  resetAppoinment() {
    this.appoinmentForm.reset();
    this.totalAreas = 0;
    this.displayInfoAreas = [];
    this.appoinmentForm.controls['sessions'].setValue('');
    this.appoinmentForm.controls['total_payments'].setValue('');
    this.appoinmentForm.controls['sells'].setValue('');
    this.appoinmentForm.controls['social_network'].setValue('');
    this.classNumber = 12;
    this.arrayNumber = [];

    this.appoinmentExists.reset();
    this.appoinmentExists.controls['appoinmentId'].setValue('');

    this.appoinmentValoration.reset();
    this.appoinmentValoration.controls['type_user'].setValue('');
    this.appoinmentValoration.controls['employee_id_val'].setValue('');

    this.showTables = false;
  }

  resetFlags() {
    this.isExistsAppoinment = false;
    this.isNewAppoinment = false;
    this.submittedExist = false;
    this.isExistsAppoinment = false;
    this.isNewAppoinment = false;
    this.appoinmentSection = false;
    this.paymentSection = false;
    this.submitted = false;
    this.isClientExists = false;
    this.disableButton = false;
    this.socialNetworkFlag = false;
    this.referalFlag = false;
    this.inputRecurrentFlag = false;
    this.flagEndTimeValidation = false;
  }

  /**
   * Method that is used to close session
   *
   * @param integer id
   */
  updateSession(id: any) {
    this.submitComment = true;

    const obj = {
      final_comment: this.finalComment.value.final_comment,
      employee_id: this.finalComment.value.employee_id,
      closed: 1,
    };

    this.api.update('sessions/final/comments', id, obj).subscribe({
      next: (comments) => {
        this.finishSession = true;
        this.messageTextFinishSession = comments.message;
        this.messageGeneralComment = comments.message;
        this.flagSessionClose = true;

        setTimeout(() => {
          this.messageTextFinishSession = '';
          this.submitComment = false;
        }, 3000);

        this.getCompleteSessionsPerOffice();
      },
      error: (err) => {},
    });
  }

  /** Private set columns per cabins */
  private columnSizesCabins(total: Number): void {
    this.columnsSizes =
      total === 0
        ? this.columnsSizes
        : Math.floor(this.columnsSizes / Number(total));
  }

  getEndTime(duration: any) {
    console.log('duracion 2: ', duration);
    const totalTime = duration.split(' ');
    console.log('total duracion: ', totalTime);
    const initialTime = this.startSession.hour * 60 + this.startSession.minute;
    const finalTime =
      Number(totalTime[0]) * 60 + Number(totalTime[2]) + initialTime;

    let endHour = Math.floor(finalTime / 60);
    let endMinute = Number(finalTime % 60);

    return `${endHour}:${endMinute}:00`;
  }

  /**
   * Function to add if assit or not
   */
  assistedUser(value: number, sessionId: number): void {
    const params = Number(sessionId) + '/' + Number(value);
    this.api.getById('sessions/checks', params).subscribe({
      next: (session) => {
        if (session.session.assisted) {
          this.acceptedSessions = true;
        }

        this.assistedFlag = true;
        this.submitAssisted = true;
        this.messageTextAssisted = session.session.assisted
          ? 'Se confirmo la asistencia.'
          : 'Se confirmo la no asistencia.';
        this.flagDisabledButton = session.session.assisted == 0 ? true : false;

        setTimeout(() => {
          this.assistedFlag = false;
          this.submitAssisted = false;
        }, 3000);
        this.fetchEvents();
        this.getCompleteSessionsPerOffice();
      },
      error: (err) => {},
    });
  }

  /**
   * Function to know  if areas missing to work
   * or all the areas were worked
   *
   * @param evt
   * @param id
   * @param status
   *
   * @return void
   */
  workingAreas(evt: any, id: any, status: any) {
    if (status === 1 && evt.target.checked) {
      this.disabledCheck = true;

      this.missingForm.patchValue({
        areas: id,
      });
    }
    if (status === 2 && evt.target.checked) {
      this.idsArray.push(id);
      this.disabledAll = true;

      this.missingForm.patchValue({
        areas: this.idsArray,
      });
    }

    if (!evt.target.checked) {
      if (status === 1) {
        this.disabledCheck = false;

        this.missingForm.patchValue({
          areas: '',
        });
      }

      if (status === 2) {
        this.disabledAll = false;
        const index = this.idsArray.indexOf(id);

        if (!evt.target.checked && index !== -1) {
          this.idsArray.splice(index, 1);
        }

        this.missingForm.patchValue({
          areas: this.idsArray,
        });
      }
    }
  }

  /***/
  // Update information when client already exists
  loadInformationExistsV1(evt: any) {
    this.appoinmentExistsV1.patchValue({
      package_id: evt.value,
    });
    const times: any = [];

    console.log('evento: ', evt);
    evt.value.filter((first: any) => {
      console.log('first: ', first);
      const result = this.packages.filter((inner: any) => {
        console.log(': ', inner);
        if (first == inner.id) {
          if (inner.shared_flag == true) {
            times.push(inner.durationSharedNoFormat);
            return inner.durationSharedNoFormat;
          } else {
            times.push(inner.durationNoFormat);
            return inner.durationNoFormat;
          }
        }
      });
    });

    console.log('aqui se va  a  hacer el calculo del tiempo: ', times);
    this.calculateTimesMultiplesPackages(times);
    this.disabledScheduleButton = true;
  }

  calculateTimesMultiplesPackages(times: any) {
    let sum = 0;
    for (let i = 0; i < times.length; i++) {
      const splitTimes = times[i].split(':');
      sum += Number(splitTimes[0]) * 60 + Number(splitTimes[1]);
    }

    const hoursToMinutes =
      +this.startSession.hour * 60 + +this.startSession.minute;
    const final = this.calculateHoursAndMinutes(hoursToMinutes, sum);

    this.endSession = {
      hour: final.hours,
      minute: final.minutes,
      second: 0,
    };
  }

  loadInformationExists(evt: any) {
    //this.showTables = true;
    let totalSessions = 1;

    console.log(this.packageFilter);
    const appoinmentData = this.packageFilter.filter(
      (key: any) => key.id == evt.target.value
    );
    console.log('app data:  ', appoinmentData);
    this.tempPackageSelected = appoinmentData;

    console.log('areas: ', appoinmentData[0].package);
    this.areasPackagesSelected = appoinmentData[0].package;
    console.log(this.areasPackagesSelected);

    console.log('paquetes: ', this.packages);

    if (this.sessionAppExists.length !== 0) {
    }

    //console.log('duracion: ', appoinmentData[0].duration);
    const timeEndSession = this.getEndTime(appoinmentData[0].duration);
    //console.log('Time Start: ', this.timeSession);
    //console.log('Time End: ', timeEndSession);
    const dateTest = `${this.timeSession} ${timeEndSession}`;
    //console.log('Date Test: ', dateTest);
    //console.log('new Date:', new Date(dateTest));
    const dateToSet = new Date(`${this.timeSession} ${timeEndSession}`);
    //console.log('dataToSet: ', dateToSet);
    const timeStarted = dateToSet
      .toLocaleTimeString('es-ES', { hour12: false })
      .split(':');
    //console.log('time Started: ', timeStarted);

    this.timeEnd = {
      hour: parseInt(timeStarted[0]),
      minute: parseInt(timeStarted[1]),
      second: parseInt(timeStarted[2]),
    };
    this.tempCompleteTime = this.timeEnd;
    this.endSession = this.timeEnd;
    //console.log('first time session');

    /*  this.sessionsData = appoinmentData[0].sessions;
    this.paymentsData = appoinmentData[0].payments; */
    this.disabledScheduleButton = true;
    this.appoinmentExists.patchValue({
      total_sessions: appoinmentData[0].remaining_sessions, //sessionsNumber,
      sessionNumber:
        appoinmentData[0].total_sessions == 0
          ? totalSessions
          : appoinmentData[0].total_sessions,
      office_id: appoinmentData[0].client.office_id,
      appoinmentId: appoinmentData[0].appoinment_package,
    });
  }

  updateClientReferer(evt: any) {
    const value = evt.value;

    this.packageFilter = this.packages.filter(
      (data: any) => data.client.id === evt.value
    );
    console.log(this.packageFilter);

    this.appoinmentForm.patchValue({
      client_referer: evt.value,
    });
  }

  updateClientsVacations(evt: any): void {
    this.formVacations.patchValue({
      employeeId: evt.options[0].value,
    });
  }

  setCondition(data: any) {
    if (this.flagTypeSession == 2) {
      return data.type_id == 1 || data.type_id == 2 || data.type_id == 3;
    } else if (this.flagTypeSession == 5) {
      return data.type_id == 4;
    } else {
      return 1 == 1;
    }
  }

  // Update Function V1
  async updateClientPackageV1(evt: any) {
    this.disabledScheduleButton = false;

    if (evt.value === undefined) {
      this.showScheduleSession = 0;

      return;
    }

    const packages = await this.getPackages(evt.value);
    this.packages = packages.packages;
    const packagesFormat = this.packages.map((packageName: any) => {
      return {
        value: packageName.id,
        label: `${packageName.type} ${packageName.packageShared} (${packageName.packageName} - ${packageName.sessionNumber} sesiones)`,
      };
    });
    this.packageFilter = [{ options: packagesFormat }];

    if (this.packageFilter.length == 0) {
      this.flagClientOpen = false;
    }

    console.log('package data: ', this.packages);
    this.flagClientOpen = true;
    this.appoinmentExistsV1.patchValue({
      client_id: this.packages[0].client.id,
    });
  }

  async updateClientPackage(evt: any) {
    this.disabledScheduleButton = false;

    if (evt.value === undefined) {
      this.showScheduleSession = 0;
      return;
    }

    // call async request
    const packages = await this.getPackages(evt.value);
    const sessionsInformation = await this.sessionInformation(evt.value);

    this.packages = packages.packages;
    console.log('paquetes: ', this.packages);
    this.sessionAppExists = sessionsInformation.appoinments;

    const packagesFormat = this.packages.map((packageName: any) => {
      return {
        value: packageName.id,
        label: packageName.session,
      };
    });
    this.packageFilter = [{ options: packagesFormat }];
    this.packageFilter = this.packages.filter(
      (data: any) => data.client.id === evt.value && this.setCondition(data)
    );

    if (this.packageFilter.length == 0) {
      this.flagClientOpen = false;
      this.clientColSize = 12;
      this.appoinmentExists.controls['total_sessions'].setValue('');
      this.appoinmentExists.controls['sessionNumber'].setValue('');
      this.endSession = { hour: '', minute: '', second: '' };
      this.showScheduleSession = 2;

      return;
    }

    this.showScheduleSession = 1;
    console.log('package  Filter: ', this.packageFilter);
    const sessionActive = this.sessionAppExists.find((data: any) => {
      if (data.sessions.length != 0) {
        if (data.id === this.packageFilter[0].appoinment_package) {
          return data;
        }

        return;
      }

      return;
    });
    console.log('session Active: ', sessionActive);

    if (
      sessionActive !== undefined &&
      sessionActive.sessions.information &&
      !sessionActive.sessions.information[0].session_close &&
      sessionActive.sessions.information[0].assisted == 2
    ) {
      this.disabledNewSession = true;
      this.disabledScheduleButton = false;
      this.messageNewSession =
        'No puedes agendar nueva sesion. El cliente seleccionado tiene una sesion no finalizada.';
    } else {
      this.disabledNewSession = false;

      if (this.packageFilter.length !== 0) {
        this.flagClientOpen = true;
        this.clientColSize = 6;

        this.appoinmentExists.patchValue({
          client_id: this.packageFilter[0].client.id,
        });
      } else {
        this.flagClientOpen = false;
        this.clientColSize = 12;
      }
    }
  }

  updateClientValoration(evt: any): void {
    this.appoinmentValoration.patchValue({
      client_id_val: evt.value,
    });
  }

  // DEPRECATED
  updateClient(evt: any) {
    if (typeof evt.value !== 'undefined') {
      const clientsData = this.clients.filter(
        (client: any) => client.id === evt.value
      );

      const dob = clientsData[0]['date_birthday'].date.split(' ')[0];
      this.appoinmentForm.patchValue({
        client_id: clientsData[0]['id'],
        first_name: clientsData[0]['first_name'],
        first_last_name: clientsData[0]['last_name'],
        last_last_name: clientsData[0]['last_name'],
        email: clientsData[0]['email'],
        phone: clientsData[0]['phone'],
        cp: clientsData[0]['cp'],
        dob: dob,
      });
    } else {
      this.appoinmentForm.patchValue({
        client_id: '',
        first_name: '',
        first_last_name: '',
        last_last_name: '',
        email: '',
        phone: '',
        cp: '',
        dob: '',
      });
    }
  }

  updateClientAppoinment(evt: any) {
    this.appoinmentForm.patchValue({
      employeeId: evt.value,
    });
  }

  updatePackages(evt: any) {
    if (evt.options.length >= this.totalAreas) {
      this.totalAreas = evt.options.length;
      const currentId = evt.options[evt.options.length - 1].value;
      const newArea = this.packages.filter(
        (area: any) => area.id === currentId
      );

      this.appoinmentForm.patchValue({ area_session: evt.value });
      const durationValue = this.getDurationFormat(newArea[0].duration);
      this.totalCostAreas += Number(newArea[0].price);

      this.displayInfoAreas.push({
        id: newArea[0].id,
        price: newArea[0].price,
        duration_format: durationValue,
        type: newArea[0].type,
        area: newArea[0].session,
      });
    } else {
      this.totalAreas = evt.options.length;
      if (this.totalAreas !== 0) {
        const dataNotRemoved = this.displayInfoAreas.filter((areas: any) => {
          return evt.options.some((opt: any) => opt.value === areas.id);
        });
        this.displayInfoAreas = [];
        this.displayInfoAreas = dataNotRemoved;
      } else {
        this.displayInfoAreas = [];
      }
    }
  }

  setValuePays(evt: any): void {
    this.arrayPayments.push(evt.target.value);

    this.appoinmentForm.patchValue({
      number_payments: this.arrayPayments,
    });
  }

  // check if will be new package or new session
  checkIsNewSession(evt: any) {
    if (evt.target.value === 'exists') {
      this.isClientExists = false;
      this.isClientSection = false;
      this.isNewAppoinment = false;
      this.isExistsAppoinment = true;
    } else {
      this.isClientExists = true;
      this.isClientSection = true;
      this.isNewAppoinment = true;
      this.isExistsAppoinment = false;
    }
  }

  // Check if the client  is new or already exists in new package
  checkClientExists(evt: any) {
    if (evt.target.checked) {
      this.isClientSection = false;
      this.appoinmentForm.patchValue({ client_id: '' });
    } else {
      this.isClientSection = true;
      this.appoinmentForm.patchValue({ client_id: 0 });
    }
  }

  paymentPerSession(evt: any): void {
    const percentage = (evt.target.value * 100) / 8;
    const costService = (this.totalCostAreas * percentage) / 100;

    this.appoinmentForm.patchValue({
      price: this.currencyPipe.transform(costService, '$'),
      total_cost: this.currencyPipe.transform(costService, '$'),
    });
  }

  updateRemainingPayments(evt: any) {
    const advance = evt.target.value;
    const total_cost = this.appoinmentForm.controls['total_cost'].value;
    const total = total_cost.replace('$', '').replace(',', '');
    const remaining = total - Number(advance);

    this.appoinmentForm.patchValue({
      remaining_payments: this.currencyPipe.transform(remaining, '$'),
    });
  }

  generateArray(numberPays: string) {
    const numbersPayments = Number(numberPays);
    this.arrayNumber = [];

    for (let i = 1; i <= numbersPayments; i++) {
      this.arrayNumber.push(i);
    }
  }

  reloadNewInfo(evt: any) {
    const value = evt.target.value;
    if (value === 'Reventa o Ingreso') {
      this.appoinmentForm.patchValue({
        social_network: 'Facebook',
        client_referer: 'NA',
        enfermera: '',
        turno: '',
      });

      this.classNumber = 4;
      this.socialNetworkFlag = false;
      this.referalFlag = false;
      this.inputRecurrentFlag = true;
    } else if (value === 'Redes Sociales') {
      this.appoinmentForm.patchValue({
        turno: 'NA',
        enfermera: 'NA',
        client_referer: 'NA',
        social_network: '',
      });

      this.classNumber = 6;
      this.socialNetworkFlag = true;
      this.referalFlag = false;
      this.inputRecurrentFlag = false;
    } else {
      this.appoinmentForm.patchValue({
        turno: 'NA',
        enfermera: 'NA',
        social_network: 'Facebook',
        client_referer: '',
      });

      this.classNumber = 6;
      this.socialNetworkFlag = false;
      this.referalFlag = true;
      this.inputRecurrentFlag = false;
    }
  }

  verifyMethod(evt: any) {
    const paymentData = this.payments.filter(
      (key: any) =>
        key.payment_method_id && key.payment_method_id == evt.target.value
    );

    if (paymentData.length > 0) {
      this.enabledPayments = paymentData;
      this.isVisiblePayment = true;
    } else {
      this.isVisiblePayment = false;
      this.appoinmentForm.patchValue({
        paymentId: 'NA',
      });
    }
  }

  private getDurationFormat(duration: string) {
    const durationSplit = duration.split(':');
    let formatMessageHours: string = '';
    this.totalHours += Number(durationSplit[0]) * 60 + Number(durationSplit[1]);

    if (durationSplit[0] !== '00') {
      formatMessageHours =
        durationSplit[0] !== '01'
          ? `${durationSplit[0]} Horas`
          : `${durationSplit[0]} Hora`;
    }

    const hours = Math.floor(this.totalHours / 60);
    const minutes = this.totalHours % 60;
    const formatoHoras = hours === 1 ? 'hora' : 'horas';
    const formatoMinutos = minutes == 1 ? 'minuto' : 'minutos';

    this.appoinmentForm.patchValue({
      duration: this.totalHours,
      duration_format: `${hours} ${formatoHoras} ${minutes} ${formatoMinutos}`,
    });

    const completeMessage = `${formatMessageHours} ${durationSplit[1]} Minutos`;

    return completeMessage;
  }

  /**
   * Funnction will call every cabin available
   */
  getsCabinsData(id: number) {
    switch (id) {
      case 1:
        this.cabin1(id);
        break;
      case 2:
        this.cabin2(id);
        break;
      case 3:
        this.cabin3(id);
        break;
      case 4:
        this.cabin4(id);
        break;
      case 5:
        this.cabin5(id);
        break;
      case 6:
        this.cabin6(id);
        break;
      case 7:
        this.cabin7(id);
        break;
      case 8:
        this.cabin8(id);
        break;
    }
  }

  fetchEventsButton() {
    this.fetchEvents();
    this.getCompleteSessionsPerOffice();
  }

  tagCabinName(cabNumber: number) {
    const cabin = this.cabins[cabNumber - 1];

    if (cabin === undefined) {
      return;
    }

    switch (cabNumber) {
      case 1:
        this.cabinName1 = cabin.name;
        break;
      case 2:
        this.cabinName2 = cabin.name;
        break;
      case 3:
        this.cabinName3 = cabin.name;
        break;
      case 4:
        this.cabinName4 = cabin.name;
        break;
      case 5:
        this.cabinName5 = cabin.name;
        break;
      case 6:
        this.cabinName6 = cabin.name;
        break;
      case 7:
        this.cabinName7 = cabin.name;
        break;
      case 8:
        this.cabinName8 = cabin.name;
        break;
    }
  }

  private typePackageText(type: boolean): string {
    if (type) {
      return '** (Paquete compartido) **';
    }

    return '';
  }

  /**
   * Cabins calls
   */

  private splitDate(): string {
    const dates = new Date(this.viewDate); //.toISOString();
    const dates2 = new Date(this.viewDate);
    const date2 = dates2.getDate() + 1;

    console.log(
      'dates 123: ',
      dates.getFullYear(),
      dates.getMonth() + 1,
      dates.getDate()
    );
    console.log(`${dates2.getFullYear()}-${dates2.getMonth() + 1}-${date2}`);

    return `${dates.getFullYear()}-${
      dates.getMonth() + 1
    }-${dates.getDate()}.${dates2.getFullYear()}-${
      dates2.getMonth() + 1
    }-${date2}`;
  }

  /**
   * Method for edit the hour of the session to know the information
   * that  will be used to set the hour
   *
   * @param id
   * @param string
   * @param string
   */
  openEditTime(id: number, dateStart: string, dateEnd: string) {
    this.disabledChangeTimeMessage = false;
    console.log(id, dateStart, dateEnd);
    const inicio = dateStart.split(' ')[1];
    const final = dateEnd.split(' ')[1];
    const fechaInicio = inicio.split(':');
    const fechaFin = final.split(':');

    this.changeTimeForm.setValue({
      sess_id: id,
    });
    this.changeTimeStart = {
      hour: parseInt(fechaInicio[0]),
      minute: parseInt(fechaInicio[1]),
      second: 0,
    };
    this.changeTimeEnd = {
      hour: parseInt(fechaFin[0]),
      minute: parseInt(fechaFin[1]),
      second: 0,
    };

    this.modalClose = this.modal.open(this.changeTime, { size: 'lg' });
  }

  /**
   * Method will be useed to change the hours
   *
   * @return void
   */
  changeHourSession(): void {
    this.disabledChangeTimeMessage = true;
    const obj = {
      start: this.changeTimeStart,
      end: this.changeTimeEnd,
    };

    console.log(this.changeTimeForm.value.sess_id, obj);
    this.api
      .update('update/change-time', this.changeTimeForm.value.sess_id, obj)
      .subscribe({
        next: (data) => {
          this.disabledChangeTimeMessage = false;
          this.modalClose.close();

          this.fetchEvents();
          this.getCompleteSessionsPerOffice();
          this.resetAppoinment();
          this.refresh.next();
        },
        error: (err) => {},
      });
  }

  employees1: any;
  employees2: any;
  employees3: any;
  employees4: any;
  employees5: any;
  employees6: any;
  employees7: any;
  employees8: any;
  public cabin1(id: number) {
    const dateFormated = this.splitDate();
    this.events1$ = this.http
      .get(
        `${this.api.API_SERVICE}/api/appoinments/offices/${dateFormated}/${id}`
      )
      .pipe(
        map((appoinments: any) => {
          this.employees1 = appoinments.employees;
          this.allAppoinments = appoinments.appoinments;
          if (
            this.allAppoinments === undefined ||
            this.allAppoinments.length === 0
          ) {
            this.tagCabinName(1);
          }

          return appoinments.appoinments.map((data: any) => {
            if (!data.cabins.is_enabled) {
              return;
            }

            const cabinId = data.cabins.id;
            this.cabinName1 = data.cabins.name;
            let color_cita: any = {};
            let cobranzaText: string = '';
            let appntData;
            let confirmedData;

            if (typeof data.payments === 'object') {
              appntData = data.payments.filter(
                (pId: any) =>
                  Number(pId.session_number) === data.sessions.numberSession
              );
            }

            let typePackageData = '';
            if (data.packages.type_id == 4) {
              typePackageData = data.packages.type_name.toUpperCase();
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_strong'] };
                  }
                } else {
                  color_cita = { ...colores['blue_strong'] };
                }
              }
            } else if (data.packages.type_id == 5) {
              typePackageData = data.packages.type_name.toUpperCase();
              console.log('holas: ', typePackageData);
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_light'] };
                  }
                } else {
                  color_cita = { ...colores['blue_light'] };
                }
              }
            } else {
              typePackageData = '';
              if (typeof data.payments === 'object') {
                typePackageData = 'DEPILACIÓN';
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue'] };
                  }
                } else {
                  color_cita = { ...colores['blue'] };
                }
              } else {
                if (data.sessions.type_session == 3) {
                  color_cita = { ...colores['orange'] };
                } else if (data.sessions.type_session == 4) {
                  color_cita = { ...colores['cian'] };
                } else {
                  cobranzaText = '<br/><h6>Sesión de Valoración</h6>';
                  color_cita = { ...colores['blue'] };
                }
              }
            }

            if (data.sessions.confirmed == 1) {
              confirmedData =
                '<i class="bi bi-check-circle-fill text-success"></i>';
            } else {
              confirmedData = '<i class="bi bi-x-circle text-danger"></i>';
            }

            // Check if needs to disabled the employee rest day class
            const status = this.typeStatus(Number(data.sessions.assisted));
            const shared = this.typePackageText(data.shared);
            let title;
            //console.log('typePackage:', typePackageData);

            if (data.sessions.type_session == 3) {
              title = `<h6 class='text-white'>Capacitación</h6>`;
            } else if (data.sessions.type_session == 4) {
              title = `<h6 class='text-white'>Junta de Trabajo</h6>`;
            } else {
              console.log(data.client);
              const identifier =
                data.sessions.type_session == 2 ? '' : data.client.identifier;
              title = `${confirmedData} ${identifier} ${typePackageData} <strong>${data.client.shared_names}</strong> ${shared} (${data.sessions.timeStart} a ${data.sessions.timeEnd}, Num: ${data.sessions.id}, ${status},
                        Tel: ${data.client.phone}) - ${data.employees.first_name} ${data.employees.last_name} - ${data.sessions.packageNames}, Agendado: ${data.sessions.created}
                        ${cobranzaText}`;
            }

            //console.log('data de cada sesion: ', data);
            return {
              title: title,
              cabin: cabinId,
              color: color_cita,
              id: data.sessions.id,
              start: new Date(data.sessions.dateStart),
              end: new Date(data.sessions.dateEnd),
              allDay: false,
              draggable: true,
              actions: [
                {
                  label: '<i class="bi bi-folder2-open"></i>',
                  onClick: () => this.handleEvent3(data.sessions.id),
                },
                {
                  label: '<i class="bi bi-pencil ms-1"></i>',
                  onClick: () =>
                    this.openEditTime(
                      data.sessions.id,
                      data.sessions.dateStart,
                      data.sessions.dateEnd
                    ),
                },
              ],
            };
          });
        })
      );
  }

  public cabin2(id: number) {
    const dateFormated = this.splitDate();
    this.events2$ = this.http
      .get(
        `${this.api.API_SERVICE}/api/appoinments/offices/${dateFormated}/${id}`
      )
      .pipe(
        map((appoinments: any) => {
          this.employees2 = appoinments.employees;
          this.allAppoinments = appoinments.appoinments;

          if (appoinments.appoinments == undefined) {
            return;
          }

          if (
            this.allAppoinments === undefined ||
            this.allAppoinments.length === 0
          ) {
            this.tagCabinName(2);
          }

          return appoinments.appoinments.map((data: any) => {
            if (!data.cabins.is_enabled) {
              return;
            }

            const cabinId = data.cabins.id;
            this.cabinName2 = data.cabins.name;
            let color_cita: any = {};
            let cobranzaText: string = '';
            let appntData;
            let confirmedData;

            if (typeof data.payments === 'object') {
              appntData = data.payments.filter(
                (pId: any) =>
                  Number(pId.session_number) === data.sessions.numberSession
              );
            }

            let typePackageData = '';
            if (data.packages.type_id == 4) {
              typePackageData = data.packages.type_name.toUpperCase();
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_strong'] };
                  }
                } else {
                  color_cita = { ...colores['blue_strong'] };
                }
              }
            } else if (data.packages.type_id == 5) {
              typePackageData = data.packages.type_name.toUpperCase();
              console.log('holas: ', typePackageData);
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_light'] };
                  }
                } else {
                  color_cita = { ...colores['blue_light'] };
                }
              }
            } else {
              typePackageData = '';
              if (typeof data.payments === 'object') {
                typePackageData = 'DEPILACIÓN';
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue'] };
                  }
                } else {
                  color_cita = { ...colores['blue'] };
                }
              } else {
                if (data.sessions.type_session == 3) {
                  color_cita = { ...colores['orange'] };
                } else if (data.sessions.type_session == 4) {
                  color_cita = { ...colores['cian'] };
                } else {
                  cobranzaText = '<br/><h6>Sesión de Valoración</h6>';
                  color_cita = { ...colores['blue'] };
                }
              }
            }

            const status = this.typeStatus(Number(data.sessions.assisted));
            const shared = this.typePackageText(data.shared);
            let title;

            if (data.sessions.confirmed == 1 && data.sessions.assisted != 0) {
              confirmedData =
                '<i class="bi bi-check-circle-fill text-success"></i>';
            } else if (data.sessions.type_session == 4) {
              title = `<h6 class='text-white'>Junta de Trabajo</h6>`;
            } else {
              confirmedData = '<i class="bi bi-x-circle text-danger"></i>';
            }

            if (data.sessions.type_session == 3) {
              title = `<h6 class='text-white'>Capacitación</h6>`;
            } else if (data.sessions.type_session == 4) {
              title = `<h6 class='text-white'>Junta de Trabajo</h6>`;
            } else {
              const identifier =
                data.sessions.type_session == 2 ? '' : data.client.identifier;
              title = `${confirmedData} ${identifier} ${typePackageData} <strong>${data.client.shared_names}</strong> ${shared} (${data.sessions.timeStart} a ${data.sessions.timeEnd}, Num: ${data.sessions.id}, ${status},
                        Tel: ${data.client.phone}) - ${data.employees.first_name} ${data.employees.last_name} - ${data.sessions.packageNames}, Agendado: ${data.sessions.created}
                        ${cobranzaText}`;
            }

            return {
              title: title,
              cabin: cabinId,
              color: color_cita,
              id: data.sessions.id,
              start: new Date(data.sessions.dateStart),
              end: new Date(data.sessions.dateEnd),
              allDay: false,
              draggable: true,
              actions: [
                {
                  label: '<i class="bi bi-folder2-open"></i>',
                  onClick: () => this.handleEvent3(data.sessions.id),
                },
                {
                  label: '<i class="bi bi-pencil ms-1"></i>',
                  onClick: () =>
                    this.openEditTime(
                      data.sessions.id,
                      data.sessions.dateStart,
                      data.sessions.dateEnd
                    ),
                },
              ],
            };
          });
        })
      );
  }

  public cabin3(id: number) {
    const dateFormated = this.splitDate();
    this.events3$ = this.http
      .get(
        `${this.api.API_SERVICE}/api/appoinments/offices/${dateFormated}/${id}`
      )
      .pipe(
        map((appoinments: any) => {
          this.employees3 = appoinments.employees;
          this.allAppoinments = appoinments.appoinments;

          if (appoinments.appoinments == undefined) {
            return;
          }

          if (
            this.allAppoinments === undefined ||
            this.allAppoinments.length === 0
          ) {
            this.tagCabinName(3);
          }

          return appoinments.appoinments.map((data: any) => {
            if (!data.cabins.is_enabled) {
              return;
            }

            const cabinId = data.cabins.id;
            this.cabinName3 = data.cabins.name;
            let color_cita: any = {};
            let cobranzaText: string = '';
            let appntData;
            let confirmedData;

            if (typeof data.payments === 'object') {
              appntData = data.payments.filter(
                (pId: any) =>
                  Number(pId.session_number) === data.sessions.numberSession
              );
            }

            let typePackageData = '';
            if (data.packages.type_id == 4) {
              typePackageData = data.packages.type_name.toUpperCase();
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_strong'] };
                  }
                } else {
                  color_cita = { ...colores['blue_strong'] };
                }
              }
            } else if (data.packages.type_id == 5) {
              typePackageData = data.packages.type_name.toUpperCase();
              console.log('holas: ', typePackageData);
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_light'] };
                  }
                } else {
                  color_cita = { ...colores['blue_light'] };
                }
              }
            } else {
              typePackageData = '';
              if (typeof data.payments === 'object') {
                typePackageData = 'DEPILACIÓN';
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue'] };
                  }
                } else {
                  color_cita = { ...colores['blue'] };
                }
              } else {
                if (data.sessions.type_session == 3) {
                  color_cita = { ...colores['orange'] };
                } else if (data.sessions.type_session == 4) {
                  color_cita = { ...colores['cian'] };
                } else {
                  cobranzaText = '<br/><h6>Sesión de Valoración</h6>';
                  color_cita = { ...colores['blue'] };
                }
              }
            }

            if (data.sessions.confirmed == 1) {
              confirmedData =
                '<i class="bi bi-check-circle-fill text-success"></i>';
            } else {
              confirmedData = '<i class="bi bi-x-circle text-danger"></i>';
            }

            const status = this.typeStatus(Number(data.sessions.assisted));
            const shared = this.typePackageText(data.shared);
            let title;

            if (data.sessions.type_session == 3) {
              title = `<h6 class='text-white'>Capacitación</h6>`;
            } else if (data.sessions.type_session == 4) {
              title = `<h6 class='text-white'>Junta de Trabajo</h6>`;
            } else {
              const identifier =
                data.sessions.type_session == 2 ? '' : data.client.identifier;
              title = `${confirmedData} ${identifier} ${typePackageData} <strong>${data.client.shared_names}</strong> ${shared} (${data.sessions.timeStart} a ${data.sessions.timeEnd}, Num: ${data.sessions.id}, ${status},
                        Tel: ${data.client.phone}) - ${data.employees.first_name} ${data.employees.last_name} - ${data.sessions.packageNames}, Agendado: ${data.sessions.created}
                        ${cobranzaText}`;
            }

            return {
              title: title,
              cabin: cabinId,
              color: color_cita,
              id: data.sessions.id,
              start: new Date(data.sessions.dateStart),
              end: new Date(data.sessions.dateEnd),
              allDay: false,
              draggable: true,
              actions: [
                {
                  label: '<i class="bi bi-folder2-open"></i>',
                  onClick: () => this.handleEvent3(data.sessions.id),
                },
                {
                  label: '<i class="bi bi-pencil ms-1"></i>',
                  onClick: () =>
                    this.openEditTime(
                      data.sessions.id,
                      data.sessions.dateStart,
                      data.sessions.dateEnd
                    ),
                },
              ],
            };
          });
        })
      );
  }

  public cabin4(id: number) {
    const dateFormated = this.splitDate();
    this.events4$ = this.http
      .get(
        `${this.api.API_SERVICE}/api/appoinments/offices/${dateFormated}/${id}`
      )
      .pipe(
        map((appoinments: any) => {
          this.employees4 = appoinments.employees;
          this.allAppoinments = appoinments.appoinments;

          if (appoinments.appoinments == undefined) {
            return;
          }

          if (
            this.allAppoinments === undefined ||
            this.allAppoinments.length === 0
          ) {
            this.tagCabinName(4);
          }

          return appoinments.appoinments.map((data: any) => {
            if (!data.cabins.is_enabled) {
              return;
            }

            const cabinId = data.cabins.id;
            this.cabinName4 = data.cabins.name;
            let color_cita: any = {};
            let cobranzaText: string = '';
            let appntData;
            let confirmedData;

            if (typeof data.payments === 'object') {
              appntData = data.payments.filter(
                (pId: any) =>
                  Number(pId.session_number) === data.sessions.numberSession
              );
            }

            let typePackageData = '';
            if (data.packages.type_id == 4) {
              typePackageData = data.packages.type_name.toUpperCase();
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_strong'] };
                  }
                } else {
                  color_cita = { ...colores['blue_strong'] };
                }
              }
            } else if (data.packages.type_id == 5) {
              typePackageData = data.packages.type_name.toUpperCase();
              console.log('holas: ', typePackageData);
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_light'] };
                  }
                } else {
                  color_cita = { ...colores['blue_light'] };
                }
              }
            } else {
              typePackageData = '';
              if (typeof data.payments === 'object') {
                typePackageData = 'DEPILACIÓN';
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue'] };
                  }
                } else {
                  color_cita = { ...colores['blue'] };
                }
              } else {
                if (data.sessions.type_session == 3) {
                  color_cita = { ...colores['orange'] };
                } else if (data.sessions.type_session == 4) {
                  color_cita = { ...colores['cian'] };
                } else {
                  cobranzaText = '<br/><h6>Sesión de Valoración</h6>';
                  color_cita = { ...colores['blue'] };
                }
              }
            }

            if (data.sessions.confirmed == 1) {
              confirmedData =
                '<i class="bi bi-check-circle-fill text-success"></i>';
            } else {
              confirmedData = '<i class="bi bi-x-circle text-danger"></i>';
            }

            const status = this.typeStatus(Number(data.sessions.assisted));
            const shared = this.typePackageText(data.shared);
            let title;

            if (data.sessions.type_session == 3) {
              title = `<h6 class='text-white'>Capacitación</h6>`;
            } else if (data.sessions.type_session == 4) {
              title = `<h6 class='text-white'>Junta de Trabajo</h6>`;
            } else {
              const identifier =
                data.sessions.type_session == 2 ? '' : data.client.identifier;
              title = `${confirmedData} ${identifier} ${typePackageData} <strong>${data.client.shared_names}</strong> ${shared} (${data.sessions.timeStart} a ${data.sessions.timeEnd}, Num: ${data.sessions.id}, ${status},
                        Tel: ${data.client.phone}) - ${data.employees.first_name} ${data.employees.last_name} - ${data.sessions.packageNames}, Agendado: ${data.sessions.created}
                        ${cobranzaText}`;
            }

            return {
              title: title,
              cabin: cabinId,
              color: color_cita,
              id: data.sessions.id,
              start: new Date(data.sessions.dateStart),
              end: new Date(data.sessions.dateEnd),
              allDay: false,
              draggable: true,
              actions: [
                {
                  label: '<i class="bi bi-folder2-open"></i>',
                  onClick: () => this.handleEvent3(data.sessions.id),
                },
                {
                  label: '<i class="bi bi-pencil ms-1"></i>',
                  onClick: () =>
                    this.openEditTime(
                      data.sessions.id,
                      data.sessions.dateStart,
                      data.sessions.dateEnd
                    ),
                },
              ],
            };
          });
        })
      );
  }

  public cabin5(id: number) {
    const dateFormated = this.splitDate();
    this.events5$ = this.http
      .get(
        `${this.api.API_SERVICE}/api/appoinments/offices/${dateFormated}/${id}`
      )
      .pipe(
        map((appoinments: any) => {
          this.employees5 = appoinments.employees;
          this.allAppoinments = appoinments.appoinments;

          if (appoinments.appoinments == undefined) {
            return;
          }

          if (
            this.allAppoinments === undefined ||
            this.allAppoinments.length === 0
          ) {
            this.tagCabinName(5);
          }

          return appoinments.appoinments.map((data: any) => {
            if (!data.cabins.is_enabled) {
              return;
            }

            const cabinId = data.cabins.id;
            this.cabinName5 = data.cabins.name;
            let color_cita: any = {};
            let cobranzaText: string = '';
            let appntData;
            let confirmedData;

            if (typeof data.payments === 'object') {
              appntData = data.payments.filter(
                (pId: any) =>
                  Number(pId.session_number) === data.sessions.numberSession
              );
            }

            let typePackageData = '';
            if (data.packages.type_id == 4) {
              typePackageData = data.packages.type_name.toUpperCase();
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_strong'] };
                  }
                } else {
                  color_cita = { ...colores['blue_strong'] };
                }
              }
            } else if (data.packages.type_id == 5) {
              typePackageData = data.packages.type_name.toUpperCase();
              console.log('holas: ', typePackageData);
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_light'] };
                  }
                } else {
                  color_cita = { ...colores['blue_light'] };
                }
              }
            } else {
              typePackageData = '';
              if (typeof data.payments === 'object') {
                typePackageData = 'DEPILACIÓN';
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue'] };
                  }
                } else {
                  color_cita = { ...colores['blue'] };
                }
              } else {
                if (data.sessions.type_session == 3) {
                  color_cita = { ...colores['orange'] };
                } else if (data.sessions.type_session == 4) {
                  color_cita = { ...colores['cian'] };
                } else {
                  cobranzaText = '<br/><h6>Sesión de Valoración</h6>';
                  color_cita = { ...colores['blue'] };
                }
              }
            }

            if (data.sessions.confirmed == 1) {
              confirmedData =
                '<i class="bi bi-check-circle-fill text-success"></i>';
            } else {
              confirmedData = '<i class="bi bi-x-circle text-danger"></i>';
            }

            const status = this.typeStatus(Number(data.sessions.assisted));
            const shared = this.typePackageText(data.shared);
            let title;

            if (data.sessions.type_session == 3) {
              title = `<h6 class='text-white'>Capacitación</h6>`;
            } else if (data.sessions.type_session == 4) {
              title = `<h6 class='text-white'>Junta de Trabajo</h6>`;
            } else {
              const identifier =
                data.sessions.type_session == 2 ? '' : data.client.identifier;
              title = `${confirmedData} ${identifier} ${typePackageData} <strong>${data.client.shared_names}</strong> ${shared} (${data.sessions.timeStart} a ${data.sessions.timeEnd}, Num: ${data.sessions.id}, ${status},
                        Tel: ${data.client.phone}) - ${data.employees.first_name} ${data.employees.last_name} - ${data.sessions.packageNames}, Agendado: ${data.sessions.created}
                        ${cobranzaText}`;
            }

            return {
              title: title,
              cabin: cabinId,
              color: color_cita,
              id: data.sessions.id,
              start: new Date(data.sessions.dateStart),
              end: new Date(data.sessions.dateEnd),
              allDay: false,
              draggable: true,
              actions: [
                {
                  label: '<i class="bi bi-folder2-open"></i>',
                  onClick: () => this.handleEvent3(data.sessions.id),
                },
                {
                  label: '<i class="bi bi-pencil ms-1"></i>',
                  onClick: () =>
                    this.openEditTime(
                      data.sessions.id,
                      data.sessions.dateStart,
                      data.sessions.dateEnd
                    ),
                },
              ],
            };
          });
        })
      );
  }

  public cabin6(id: number) {
    const dateFormated = this.splitDate();
    this.events6$ = this.http
      .get(
        `${this.api.API_SERVICE}/api/appoinments/offices/${dateFormated}/${id}`
      )
      .pipe(
        map((appoinments: any) => {
          this.employees6 = appoinments.employees;
          this.allAppoinments = appoinments.appoinments;

          if (appoinments.appoinments == undefined) {
            return;
          }

          if (
            this.allAppoinments === undefined ||
            this.allAppoinments.length === 0
          ) {
            this.tagCabinName(6);
          }

          return appoinments.appoinments.map((data: any) => {
            if (!data.cabins.is_enabled) {
              return;
            }

            const cabinId = data.cabins.id;
            this.cabinName6 = data.cabins.name;
            let color_cita: any = {};
            let cobranzaText: string = '';
            let appntData;
            let confirmedData;

            if (typeof data.payments === 'object') {
              appntData = data.payments.filter(
                (pId: any) =>
                  Number(pId.session_number) === data.sessions.numberSession
              );
            }

            let typePackageData = '';
            if (data.packages.type_id == 4) {
              typePackageData = data.packages.type_name.toUpperCase();
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_strong'] };
                  }
                } else {
                  color_cita = { ...colores['blue_strong'] };
                }
              }
            } else if (data.packages.type_id == 5) {
              typePackageData = data.packages.type_name.toUpperCase();
              console.log('holas: ', typePackageData);
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_light'] };
                  }
                } else {
                  color_cita = { ...colores['blue_light'] };
                }
              }
            } else {
              typePackageData = '';
              if (typeof data.payments === 'object') {
                typePackageData = 'DEPILACIÓN';
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue'] };
                  }
                } else {
                  color_cita = { ...colores['blue'] };
                }
              } else {
                if (data.sessions.type_session == 3) {
                  color_cita = { ...colores['orange'] };
                } else if (data.sessions.type_session == 4) {
                  color_cita = { ...colores['cian'] };
                } else {
                  cobranzaText = '<br/><h6>Sesión de Valoración</h6>';
                  color_cita = { ...colores['blue'] };
                }
              }
            }

            if (data.sessions.confirmed == 1) {
              confirmedData =
                '<i class="bi bi-check-circle-fill text-success"></i>';
            } else {
              confirmedData = '<i class="bi bi-x-circle text-danger"></i>';
            }

            const status = this.typeStatus(Number(data.sessions.assisted));
            const shared = this.typePackageText(data.shared);
            let title;

            if (data.sessions.type_session == 3) {
              title = `<h6 class='text-white'>Capacitación</h6>`;
            } else if (data.sessions.type_session == 4) {
              title = `<h6 class='text-white'>Junta de Trabajo</h6>`;
            } else {
              const identifier =
                data.sessions.type_session == 2 ? '' : data.client.identifier;
              title = `${confirmedData} ${identifier} ${typePackageData} <strong>${data.client.shared_names}</strong> ${shared} (${data.sessions.timeStart} a ${data.sessions.timeEnd}, Num: ${data.sessions.id}, ${status},
                        Tel: ${data.client.phone}) - ${data.employees.first_name} ${data.employees.last_name} - ${data.sessions.packageNames}, Agendado: ${data.sessions.created}
                        ${cobranzaText}`;
            }

            return {
              title: title,
              cabin: cabinId,
              color: color_cita,
              id: data.sessions.id,
              start: new Date(data.sessions.dateStart),
              end: new Date(data.sessions.dateEnd),
              allDay: false,
              draggable: true,
              actions: [
                {
                  label: '<i class="bi bi-folder2-open"></i>',
                  onClick: () => this.handleEvent3(data.sessions.id),
                },
                {
                  label: '<i class="bi bi-pencil ms-1"></i>',
                  onClick: () =>
                    this.openEditTime(
                      data.sessions.id,
                      data.sessions.dateStart,
                      data.sessions.dateEnd
                    ),
                },
              ],
            };
          });
        })
      );
  }

  public cabin7(id: number) {
    const dateFormated = this.splitDate();
    this.events7$ = this.http
      .get(
        `${this.api.API_SERVICE}/api/appoinments/offices/${dateFormated}/${id}`
      )
      .pipe(
        map((appoinments: any) => {
          this.employees7 = appoinments.employees;
          this.allAppoinments = appoinments.appoinments;

          if (appoinments.appoinments == undefined) {
            return;
          }

          if (
            this.allAppoinments === undefined ||
            this.allAppoinments.length === 0
          ) {
            this.tagCabinName(7);
          }

          return appoinments.appoinments.map((data: any) => {
            if (!data.cabins.is_enabled) {
              return;
            }

            const cabinId = data.cabins.id;
            this.cabinName7 = data.cabins.name;
            let color_cita: any = {};
            let cobranzaText: string = '';
            let appntData;
            let confirmedData;

            if (typeof data.payments === 'object') {
              appntData = data.payments.filter(
                (pId: any) =>
                  Number(pId.session_number) === data.sessions.numberSession
              );
            }

            let typePackageData = '';
            if (data.packages.type_id == 4) {
              typePackageData = data.packages.type_name.toUpperCase();
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_strong'] };
                  }
                } else {
                  color_cita = { ...colores['blue_strong'] };
                }
              }
            } else if (data.packages.type_id == 5) {
              typePackageData = data.packages.type_name.toUpperCase();
              console.log('holas: ', typePackageData);
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_light'] };
                  }
                } else {
                  color_cita = { ...colores['blue_light'] };
                }
              }
            } else {
              typePackageData = '';
              if (typeof data.payments === 'object') {
                typePackageData = 'DEPILACIÓN';
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue'] };
                  }
                } else {
                  color_cita = { ...colores['blue'] };
                }
              } else {
                if (data.sessions.type_session == 3) {
                  color_cita = { ...colores['orange'] };
                } else if (data.sessions.type_session == 4) {
                  color_cita = { ...colores['cian'] };
                } else {
                  cobranzaText = '<br/><h6>Sesión de Valoración</h6>';
                  color_cita = { ...colores['blue'] };
                }
              }
            }

            if (data.sessions.confirmed == 1) {
              confirmedData =
                '<i class="bi bi-check-circle-fill text-success"></i>';
            } else {
              confirmedData = '<i class="bi bi-x-circle text-danger"></i>';
            }

            const status = this.typeStatus(Number(data.sessions.assisted));
            const shared = this.typePackageText(data.shared);
            let title;

            if (data.sessions.type_session == 3) {
              title = `<h6 class='text-white'>Capacitación</h6>`;
            } else if (data.sessions.type_session == 4) {
              title = `<h6 class='text-white'>Junta de Trabajo</h6>`;
            } else {
              const identifier =
                data.sessions.type_session == 2 ? '' : data.client.identifier;
              title = `${confirmedData} ${identifier} ${typePackageData} <strong>${data.client.shared_names}</strong> ${shared} (${data.sessions.timeStart} a ${data.sessions.timeEnd}, Num: ${data.sessions.id}, ${status},
                        Tel: ${data.client.phone}) - ${data.employees.first_name} ${data.employees.last_name} - ${data.sessions.packageNames}, Agendado: ${data.sessions.created}
                        ${cobranzaText}`;
            }

            return {
              title: title,
              cabin: cabinId,
              color: color_cita,
              id: data.sessions.id,
              start: new Date(data.sessions.dateStart),
              end: new Date(data.sessions.dateEnd),
              allDay: false,
              draggable: true,
              actions: [
                {
                  label: '<i class="bi bi-folder2-open"></i>',
                  onClick: () => this.handleEvent3(data.sessions.id),
                },
                {
                  label: '<i class="bi bi-pencil ms-1"></i>',
                  onClick: () =>
                    this.openEditTime(
                      data.sessions.id,
                      data.sessions.dateStart,
                      data.sessions.dateEnd
                    ),
                },
              ],
            };
          });
        })
      );
  }

  public cabin8(id: number) {
    const dateFormated = this.splitDate();
    this.events8$ = this.http
      .get(
        `${this.api.API_SERVICE}/api/appoinments/offices/${dateFormated}/${id}`
      )
      .pipe(
        map((appoinments: any) => {
          this.employees8 = appoinments.employees;
          this.allAppoinments = appoinments.appoinments;

          if (appoinments.appoinments == undefined) {
            return;
          }

          if (
            this.allAppoinments === undefined ||
            this.allAppoinments.length === 0
          ) {
            this.tagCabinName(8);
          }

          return appoinments.appoinments.map((data: any) => {
            if (!data.cabins.is_enabled) {
              return;
            }

            const cabinId = data.cabins.id;
            this.cabinName8 = data.cabins.name;
            let color_cita: any = {};
            let cobranzaText: string = '';
            let appntData;
            let confirmedData;

            if (typeof data.payments === 'object') {
              appntData = data.payments.filter(
                (pId: any) =>
                  Number(pId.session_number) === data.sessions.numberSession
              );
            }

            let typePackageData = '';
            if (data.packages.type_id == 4) {
              typePackageData = data.packages.type_name.toUpperCase();
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_strong'] }
                        : { ...colores['purple_strong'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_strong'] };
                  }
                } else {
                  color_cita = { ...colores['blue_strong'] };
                }
              }
            } else if (data.packages.type_id == 5) {
              typePackageData = data.packages.type_name.toUpperCase();
              console.log('holas: ', typePackageData);
              if (typeof data.payments === 'object') {
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green_light'] }
                        : { ...colores['purple_light'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue_light'] };
                  }
                } else {
                  color_cita = { ...colores['blue_light'] };
                }
              }
            } else {
              typePackageData = '';
              if (typeof data.payments === 'object') {
                typePackageData = 'DEPILACIÓN';
                if (appntData.length !== 0) {
                  if (Number(appntData[0].amount_payed) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else if (Number(appntData[0].payment) >= 0) {
                    color_cita =
                      appntData[0].is_paid &&
                      (data.sessions.complete_session ||
                        data.sessions.sessionClose)
                        ? { ...colores['green'] }
                        : { ...colores['purple'] };
                    cobranzaText = appntData[0].is_paid
                      ? `<br /><h5>Pago realizado por: $ ${appntData[0].payment_original}</h5>`
                      : `<br /><h5>Pendiente cobranza: $ ${appntData[0].payment}</h5>`;
                  } else {
                    color_cita = { ...colores['blue'] };
                  }
                } else {
                  color_cita = { ...colores['blue'] };
                }
              } else {
                if (data.sessions.type_session == 3) {
                  color_cita = { ...colores['orange'] };
                } else if (data.sessions.type_session == 4) {
                  color_cita = { ...colores['cian'] };
                } else {
                  cobranzaText = '<br/><h6>Sesión de Valoración</h6>';
                  color_cita = { ...colores['blue'] };
                }
              }
            }

            if (data.sessions.confirmed == 1) {
              confirmedData =
                '<i class="bi bi-check-circle-fill text-success"></i>';
            } else {
              confirmedData = '<i class="bi bi-x-circle text-danger"></i>';
            }

            const status = this.typeStatus(Number(data.sessions.assisted));
            const shared = this.typePackageText(data.shared);
            let title;

            if (data.sessions.type_session == 3) {
              title = `<h6 class='text-white'>Capacitación</h6>`;
            } else if (data.sessions.type_session == 4) {
              title = `<h6 class='text-white'>Junta de Trabajo</h6>`;
            } else {
              const identifier =
                data.sessions.type_session == 2 ? '' : data.client.identifier;
              title = `${confirmedData} ${identifier} ${typePackageData} <strong>${data.client.shared_names}</strong> ${shared} (${data.sessions.timeStart} a ${data.sessions.timeEnd}, Num: ${data.sessions.id}, ${status},
                        Tel: ${data.client.phone}) - ${data.employees.first_name} ${data.employees.last_name} - ${data.sessions.packageNames}, Agendado: ${data.sessions.created}
                        ${cobranzaText}`;
            }

            return {
              title: title,
              cabin: cabinId,
              color: color_cita,
              id: data.sessions.id,
              start: new Date(data.sessions.dateStart),
              end: new Date(data.sessions.dateEnd),
              allDay: false,
              draggable: true,
              actions: [
                {
                  label: '<i class="bi bi-folder2-open"></i>',
                  onClick: () => this.handleEvent3(data.sessions.id),
                },
                {
                  label: '<i class="bi bi-pencil ms-1"></i>',
                  onClick: () =>
                    this.openEditTime(
                      data.sessions.id,
                      data.sessions.dateStart,
                      data.sessions.dateEnd
                    ),
                },
              ],
            };
          });
        })
      );
  }
  /*
   * Cabins calls
   */

  private typeStatus(status: number) {
    let statusLabel: string = '';
    switch (status) {
      case 0:
        statusLabel = 'No asistio';
        break;
      case 1:
        statusLabel = 'Asistio';
        break;
      case 2:
        statusLabel = 'Pendiente';
        break;
    }

    return statusLabel;
  }

  checkPaymentMethods(evt: any, data: string): void {
    if (data === 'extra') {
      this.extraShowFlag = true;
      this.paymentMethodFlag = false;
    } else {
      this.extraShowFlag = false;
      this.paymentMethodFlag = true;
    }

    this.paymentsFilter = this.payments.filter(
      (data: any) => data.payment_method_id === Number(evt.target.value)
    );
  }

  changePriceLabel(evt: any) {
    if (this.paymentsForm.value.change_amount) {
      this.saldoTotal = evt.target.value;
    }
  }

  changeAmountPayed(evt: any) {
    if (evt.target.checked) {
      this.disabledAmountField = false;
    } else {
      this.disabledAmountField = true;
      this.saldoTotal = this.paymentsForm.value.original_amount;

      this.paymentsForm.patchValue({
        amount: this.oldPayment,
      });
    }

    this.paymentsForm.patchValue({
      change_amount: evt.target.checked,
    });
  }

  // Save payments Form
  savePaymentsSession() {
    this.submitedPayments = true;

    if (this.paymentsForm.invalid) {
      return;
    }

    const obj = {
      paymentMethodId: this.paymentsForm.value.paymentMethodId,
      sessionId: this.paymentsForm.value.sessionId,
      paymentId: this.paymentsForm.value.paymentId,
      remainingPaymentId: this.paymentsForm.value.remainingPaymentId,
      amount: this.paymentsForm.value.amount,
      terminal: this.paymentsForm.value.terminal,
      apptId: this.paymentsForm.value.apptId,
      customAmount: this.paymentsForm.value.change_amount,
      originalAmount: this.paymentsForm.value.original_amount,
    };

    console.log('object: ', obj);
    this.api.create('payments_sessions', obj).subscribe({
      next: (payments) => {
        this.paymentDone = true;
        this.messageTextPaymentDone = payments.message;

        this.completeTransaction = true;
        this.submitedPayments = false;
        this.isPaid = true;
        this.messagesPayments = payments.message;
        this.isPaidFlag = payments.paymentSessions.is_paid ? true : false;
        this.flagPaymentDone = true;

        setTimeout(() => {
          this.messageTextPaymentDone = '';
        }, 3000);

        this.fetchEvents();
        this.getCompleteSessionsPerOffice();
      },
      error: (err) => {},
    });
  }

  saveExtraPayments() {
    this.submitExtraPayments = true;

    if (this.extraPaymentsForm.invalid) {
      this.submitExtraPayments = false;
      return;
    }

    const obj = {
      amount: this.extraPaymentsForm.value.amount,
      paymentMethodId: this.extraPaymentsForm.value.paymentMethodId,
      terminal: this.extraPaymentsForm.value.terminal,
      sessionId: this.extraPaymentsForm.value.sessionId,
      extra: this.extraPaymentsForm.value.extra,
      paymentId: this.extraPaymentsForm.value.paymentId,
      remainingPayment: this.extraPaymentsForm.value.remainingPayment,
    };

    this.api.create('extra_payment_session', obj).subscribe({
      next: (extraPay) => {
        this.extraPaymentsSessions = true;
        this.messageExtraPaymentSession = extraPay.message;

        setTimeout(() => {
          this.submitExtraPayments = false;
          this.extraPaymentsSessions = false;
          this.newExtraPays = false;
          location.reload();
        }, 3000);

        this.resetExtraPayments();
        this.fetchEvents();
        this.getCompleteSessionsPerOffice();
      },
      error: (err) => {},
    });
  }

  dayClicked(
    { date, events }: { date: Date; events: CalendarEvent[] },
    day: CalendarMonthViewDay
  ): void {
    console.log('day clicked???');
    this.selectedMonthViewDay = day;
    const selectedDateTime = this.selectedMonthViewDay.date.getTime();
    const dateIndex = this.selectedDays.findIndex(
      (selectedDay: any) => selectedDay.date.getTime() === selectedDateTime
    );
    if (dateIndex > -1) {
      delete this.selectedMonthViewDay.cssClass;
      this.selectedDays.splice(dateIndex, 1);
    } else {
      this.selectedDays.push(this.selectedMonthViewDay);
      day.cssClass = 'cal-day-selected';
      this.selectedMonthViewDay = day;
    }
  }

  /**
   * Check what user should be assigned to the modal
   * for the different sessions
   *
   * @params cabinData object
   * @params timeStarted string
   *
   * @return object
   */
  private employeeSelectedPerTime(cabinData: any, timeStarted: any) {
    const userEmployee = cabinData.employees.filter((dataInner: any) => {
      if (
        timeStarted >= dataInner.checking &&
        timeStarted < dataInner.checkout
      ) {
        if (
          timeStarted >= dataInner.startHour &&
          timeStarted < dataInner.endHour
        ) {
          return dataInner;
        }
        return;
      }

      return;
    });

    return userEmployee[0];
  }

  /**
   * Method will be used to add minnutes to the start date
   *
   * @param current
   * @param minutes
   * @returns
   */
  private addMoreTime(current: any, minutes: number) {
    const minutesConversion =
      Number(current.hour) * 60 + Number(current.minute) + Number(minutes);
    const minutesToHours = Math.floor(minutesConversion / 60);
    const minutesToMinutes = minutesConversion % 60;

    return { hour: minutesToHours, minute: minutesToMinutes };
  }

  private getFullDay(numberDay: number) {
    let nameDay;
    switch (numberDay) {
      case 0:
        nameDay = 'Domingo';
        break;
      case 1:
        nameDay = 'Lunes';
        break;
      case 2:
        nameDay = 'Martes';
        break;
      case 3:
        nameDay = 'Miercoles';
        break;
      case 4:
        nameDay = 'Jueves';
        break;
      case 5:
        nameDay = 'Viernes';
        break;
      case 6:
        nameDay = 'Sabado';
        break;
    }

    return nameDay;
  }

  /** Method  added to events in calendar */
  hourSegmentClicked(date: Date, evt: any, numCabin: number) {
    console.log('hour segment');
    const cabinData = this.cabins[numCabin - 1];
    let employeeInfo: any;
    const restDay = this.getRestDayNumber(date.getDay());
    let dataCanceled = false;

    console.log('Cabin Info: ', cabinData);

    if (cabinData.employees[0].schedule_v1.length > 0) {
      cabinData.employees[0].schedule_v1.map((data: any) => {
        if (
          Number(restDay) === Number(data.cabin_user_day) &&
          data.has_rest_day
        ) {
          dataCanceled = true;
        }
      });
    }

    this.flagTypeSession = 0;
    this.flagTypeClient = 0;
    this.disabledNewSession = false;
    this.showTables = false;
    this.flagClientOpen = false;
    this.flagSimpleAreas = false;
    this.selectedDayViewDate = date;
    this.clientColSize = 12;
    this.showScheduleSession = 0;

    const dateComplete = new Date(this.selectedDayViewDate);
    const tempDate = new Date(this.selectedDayViewDate);
    const tempDate2 = new Date(this.selectedDayViewDate);
    const temporalEndDate = new Date(
      tempDate.setMinutes(tempDate.getMinutes() + 15)
    );
    const temporalEndDate2 = new Date(
      tempDate2.setMinutes(tempDate2.getMinutes() + 30)
    );

    const timeStarted = dateComplete
      .toLocaleTimeString('es-ES', { hour12: false })
      .split(':');
    const dateStarted = dateComplete.toLocaleDateString('es-ES').split('/');
    const timeEnded = temporalEndDate
      .toLocaleTimeString('es-ES', { hour12: false })
      .split(':');
    const dateEnded = temporalEndDate.toLocaleDateString('es-ES').split('/');
    const timeEndedCap = temporalEndDate2
      .toLocaleTimeString('es-ES', { hour12: false })
      .split(':');
    console.log('final: ', timeEnded, timeEndedCap, timeStarted);

    if (cabinData.total_users > 1) {
      employeeInfo = this.employeeSelectedPerTime(
        cabinData,
        timeStarted.join(':')
      );
    } else {
      employeeInfo = cabinData.employees[0];
    }

    if (employeeInfo === undefined) {
      console.log('Aqui entro: ', employeeInfo);
      return;
    }

    const timeToCheck = `${timeStarted[0]}:${timeStarted[1]}:${timeStarted[2]}`;
    this.timeSession = `${dateStarted[2]}-${dateStarted[1]}-${dateStarted[0]}`;
    console.log('Time Session open session: ', this.timeSession);
    this.resetAppoinment();
    this.resetFlags();

    this.timeStart = {
      hour: parseInt(timeStarted[0]),
      minute: parseInt(timeStarted[1]),
      second: parseInt(timeStarted[2]),
    };
    this.timeEnd = {
      hour: parseInt(timeEnded[0]),
      minute: parseInt(timeEnded[1]),
      second: parseInt(timeEnded[2]),
    };
    this.timeEnd2 = {
      hour: parseInt(timeEndedCap[0]),
      minute: parseInt(timeEndedCap[1]),
      second: parseInt(timeEndedCap[2]),
    };
    this.startVacations = {
      hour: 9,
      minute: 0,
      second: 0,
    };
    this.endVacations = {
      hour: 21,
      minute: 0,
      second: 0,
    };

    this.startSession = this.timeStart;
    this.startCapacitation = this.timeStart;
    this.startMeeting = this.timeStart;
    this.endSession = this.timeEnd;
    this.endCapacitation = this.timeEnd2;
    const timeEndMeeting = this.addMoreTime(this.startMeeting, 45);

    console.log('-- EmployeeInfo: ', employeeInfo, restDay);
    // Check V1 to fill in data into dropdown
    let employeeInfoV1;
    if (employeeInfo.schedule_v1.length > 0) {
      employeeInfoV1 = employeeInfo.schedule_v1.filter(
        (innerEmp: any) => innerEmp.cabin_user_day == restDay
      );
    }

    if (employeeInfoV1.length == 0) {
      employeeInfoV1 = employeeInfo.schedule_v1;
      console.log('-- Employee Info: ', employeeInfoV1);
    }
    console.log('-- V1 Employee: ', employeeInfoV1);

    this.endMeeting = {
      hour: timeEndMeeting.hour,
      minute: timeEndMeeting.minute,
      second: 0,
    };

    this.appoinmentForm.patchValue({
      start_date_threatment: {
        year: parseInt(dateStarted[2]),
        month: parseInt(dateStarted[1]),
        day: parseInt(dateStarted[0]),
      },
    });
    this.formVacations.patchValue({
      cabinId: numCabin,
      officeId: this.loggedEmployee.office.id,
    });

    this.appoinmentExists.patchValue({
      dateSession: {
        year: parseInt(dateStarted[2]),
        month: parseInt(dateStarted[1]),
        day: parseInt(dateStarted[0]),
      },
      employee_id: employeeInfoV1[0].user.id ?? employeeInfo.employee_id,
      employee_id_temp: employeeInfoV1[0].user.id ?? employeeInfo.employee_id,
      cabinId: evt.length !== 0 ? evt[0].cabin : `${numCabin}_check`,
      cabinNum: numCabin,
    });

    this.formMeetings.patchValue({
      cabinId: evt.length !== 0 ? evt[0].cabin : `${numCabin}_check`,
      cabinNum: numCabin,
      startDate: {
        year: parseInt(dateStarted[2]),
        month: parseInt(dateStarted[1]),
        day: parseInt(dateStarted[0]),
      },
      repeatDay: this.getFullDay(dateComplete.getDay()),
    });

    // For multiple packages
    this.appoinmentExistsV1.patchValue({
      dateSession: {
        year: parseInt(dateStarted[2]),
        month: parseInt(dateStarted[1]),
        day: parseInt(dateStarted[0]),
      },
      employee_id: employeeInfoV1[0].user.id ?? employeeInfo.employee_id,
      employee_id_temp: employeeInfoV1[0].user.id ?? employeeInfo.employee_id,
      cabinId: evt.length !== 0 ? evt[0].cabin : `${numCabin}_check`,
      cabinNum: numCabin,
    });

    this.appoinmentForm.patchValue({
      officeName: this.loggedEmployee.office.name,
      officeId: this.loggedEmployee.office.id,
      client_id: 0,
    });
    this.appoinmentValoration.patchValue({
      office_id_val: this.loggedEmployee.office.id,
      cabinId_val: evt.length !== 0 ? evt[0].cabin : `${numCabin}_check`,
      client_id_val: 0,
      employee_id_val: employeeInfoV1[0].user.id ?? employeeInfo.employee_id,
      employee_id_val_temp:
        employeeInfoV1[0].user.id ?? employeeInfo.employee_id,
      cabinNum_val: numCabin,
      dateSession_val: {
        year: parseInt(dateStarted[2]),
        month: parseInt(dateStarted[1]),
        day: parseInt(dateStarted[0]),
      },
    });
    this.formCapacitation.patchValue({
      employeeId: employeeInfoV1[0].user.id ?? employeeInfo.employee_id,
      tempEmployee: employeeInfoV1[0].user.id ?? employeeInfo.employee_id,
      cabinId: evt.length !== 0 ? evt[0].cabin : `${numCabin}_check`,
      officeId: this.loggedEmployee.office.id,
    });

    /**
     *
      employee_id_val: ['', [Validators.required]],
      dateSession_val: ['', [Validators.required]]
     */

    // check if should open the modal
    const flagResult = this.verifyAddSelectedDay(
      timeToCheck,
      dateStarted,
      numCabin
    );

    //if (cabinData.total_users < 2) {
    if (flagResult || dataCanceled) {
      console.log('Aqui entro 2: ', flagResult);
      return;
    }
    //}

    // disabled fields
    this.appoinmentExists.controls['employee_id_temp'].disable();
    this.appoinmentExistsV1.controls['employee_id_temp'].disable();
    this.appoinmentValoration.controls['employee_id_val_temp'].disable();
    this.formCapacitation.controls['tempEmployee'].disable();

    this.resetFlags();

    this.modalClose = this.modal.open(this.createSession, { size: 'xl' });
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((day) => {
      if (
        this.selectedDays.some(
          (selectedDay: any) =>
            selectedDay.date.getTime() === day.date.getTime()
        )
      ) {
        day.cssClass = 'cal-day-selected';
      }
    });
  }

  beforeDayViewRender1(
    renderEvent: CalendarDayViewBeforeRenderEvent,
    cabinId: number
  ) {
    console.log('-- Cabin Id: ', cabinId);
    console.log('-- TEST INFO: ', this.employees1);
    const index = cabinId ? cabinId - 1 : -1;
    const cabinInfo = this.cabins[index];
    let employeeInfo =
      cabinInfo.total_users === 1
        ? this.getSimpleUserData(cabinInfo, this.employees1)
        : this.getSingleUserMultipleCabin(cabinInfo);
    console.log('employees: ', employeeInfo);
    console.log('vacaciones: ', this.vacations);
    const filterVacations = this.vacations.filter(
      (vac: any) => vac.user.id === employeeInfo[0].id
    );

    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          const currentDate = new Date(segment.date);
          const currentTime = `${('0' + segment.date.getHours()).slice(-2)}:${(
            '0' + segment.date.getMinutes()
          ).slice(-2)}:${('0' + segment.date.getSeconds()).slice(-2)}`;
          /*
            //segment.date.getHours() >= 2 && segment.date.getHours() <= 5) {
            segment.cssClass = 'bg-disabled';
          */

          if (
            filterVacations.length > 0 &&
            filterVacations[0].vacations.length > 0
          ) {
            filterVacations[0].vacations.forEach((vacation: any) => {
              //console.log('vacations:', vacation);
              const initialStartDate = vacation.start_date.split('-');
              const initialEndDate = vacation.end_date.split('-');
              const startDate = new Date(
                `${initialStartDate[0]}/${initialStartDate[1]}/${initialStartDate[2]}`
              );
              const endDate = new Date(
                `${initialEndDate[0]}/${initialEndDate[1]}/${initialEndDate[2]} 23:59:59`
              );

              if (currentDate >= startDate && currentDate <= endDate) {
                if (
                  currentTime >= vacation.start_hour &&
                  currentTime <= vacation.end_hour
                ) {
                  segment.cssClass = 'bg-disabled';
                }
              }
            });
          }

          if (employeeInfo[0].schedule_v1.length > 0) {
            employeeInfo[0].schedule_v1.map((restDays: any) => {
              if (
                Number(restDays.cabin_user_day) ==
                  Number(this.getRestDayNumber(segment.date.getDay())) &&
                restDays.has_rest_day
              ) {
                segment.cssClass = 'bg-disabled';
              }
            });
          }

          const startDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          const endDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          if (
            startDateSegment >= employeeInfo[0].schedule.food_start &&
            endDateSegment < employeeInfo[0].schedule.food_end
          ) {
            segment.cssClass = 'bg-disabled';
          }

          /*if (hour?.length !== 0) {
            const endDateHour = `${this.endSession.hour}:${this.endSession.minute}:00`;
            const startDateHour = `${this.startSession.hour}:${this.startSession.minute}:00`;
            if (
              (employeeInfo[0].schedule.food_start <= startDateHour &&
                employeeInfo[0].schedule.food_end > startDateHour) ||
              (employeeInfo[0].schedule.food_start <= endDateHour &&
                employeeInfo[0].schedule.food_end > endDateHour)
            ) {
              flagDisabled = true;
            }
          } */
        });
      });
    });
  }

  beforeDayViewRender2(
    renderEvent: CalendarDayViewBeforeRenderEvent,
    cabinId: number
  ) {
    console.log('-- Cabin Id: ', cabinId);
    console.log('-- TEST INFO 2: ', this.employees2);
    const index = cabinId ? cabinId - 1 : -1;
    const cabinInfo = this.cabins[index];
    let employeeInfo =
      cabinInfo.total_users === 1
        ? this.getSimpleUserData(cabinInfo, this.employees2)
        : this.getSingleUserMultipleCabin(cabinInfo);
    console.log('employees: ', employeeInfo);
    console.log('vacaciones: ', this.vacations);
    const filterVacations = this.vacations.filter(
      (vac: any) => vac.user.id === employeeInfo[0].id
    );

    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          const currentDate = new Date(segment.date);
          const currentTime = `${('0' + segment.date.getHours()).slice(-2)}:${(
            '0' + segment.date.getMinutes()
          ).slice(-2)}:${('0' + segment.date.getSeconds()).slice(-2)}`;
          /*
            //segment.date.getHours() >= 2 && segment.date.getHours() <= 5) {
            segment.cssClass = 'bg-disabled';
          */
          if (filterVacations[0].vacations.length > 0) {
            filterVacations[0].vacations.forEach((vacation: any) => {
              //console.log('vacations:', vacation);
              const initialStartDate = vacation.start_date.split('-');
              const initialEndDate = vacation.end_date.split('-');
              const startDate = new Date(
                `${initialStartDate[0]}/${initialStartDate[1]}/${initialStartDate[2]}`
              );
              const endDate = new Date(
                `${initialEndDate[0]}/${initialEndDate[1]}/${initialEndDate[2]} 23:59:59`
              );

              if (currentDate >= startDate && currentDate <= endDate) {
                if (
                  currentTime >= vacation.start_hour &&
                  currentTime <= vacation.end_hour
                ) {
                  segment.cssClass = 'bg-disabled';
                }
              }
            });
          }

          if (employeeInfo[0].schedule_v1.length > 0) {
            employeeInfo[0].schedule_v1.map((restDays: any) => {
              if (
                Number(restDays.cabin_user_day) ==
                  Number(this.getRestDayNumber(segment.date.getDay())) &&
                restDays.has_rest_day
              ) {
                console.log('entro aqio?? ', restDays);
                segment.cssClass = 'bg-disabled';
              }
            });
          }

          const startDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          const endDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          if (
            startDateSegment >= employeeInfo[0].schedule.food_start &&
            endDateSegment < employeeInfo[0].schedule.food_end
          ) {
            segment.cssClass = 'bg-disabled';
          }

          /*if (hour?.length !== 0) {
            const endDateHour = `${this.endSession.hour}:${this.endSession.minute}:00`;
            const startDateHour = `${this.startSession.hour}:${this.startSession.minute}:00`;
            if (
              (employeeInfo[0].schedule.food_start <= startDateHour &&
                employeeInfo[0].schedule.food_end > startDateHour) ||
              (employeeInfo[0].schedule.food_start <= endDateHour &&
                employeeInfo[0].schedule.food_end > endDateHour)
            ) {
              flagDisabled = true;
            }
          } */
        });
      });
    });
  }

  beforeDayViewRender3(
    renderEvent: CalendarDayViewBeforeRenderEvent,
    cabinId: number
  ) {
    console.log('-- Cabin Id: ', cabinId);
    console.log('-- TEST INFO 3: ', this.employees3);
    const index = cabinId ? cabinId - 1 : -1;
    const cabinInfo = this.cabins[index];
    let employeeInfo =
      cabinInfo.total_users === 1
        ? this.getSimpleUserData(cabinInfo, this.employees3)
        : this.getSingleUserMultipleCabin(cabinInfo);
    console.log('employees: ', employeeInfo);
    console.log('vacaciones: ', this.vacations);
    const filterVacations = this.vacations.filter(
      (vac: any) => vac.user.id === employeeInfo[0].id
    );

    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          const currentDate = new Date(segment.date);
          const currentTime = `${('0' + segment.date.getHours()).slice(-2)}:${(
            '0' + segment.date.getMinutes()
          ).slice(-2)}:${('0' + segment.date.getSeconds()).slice(-2)}`;
          /*
            //segment.date.getHours() >= 2 && segment.date.getHours() <= 5) {
            segment.cssClass = 'bg-disabled';
          */
          if (filterVacations[0].vacations.length > 0) {
            filterVacations[0].vacations.forEach((vacation: any) => {
              //console.log('vacations:', vacation);
              const initialStartDate = vacation.start_date.split('-');
              const initialEndDate = vacation.end_date.split('-');
              const startDate = new Date(
                `${initialStartDate[0]}/${initialStartDate[1]}/${initialStartDate[2]}`
              );
              const endDate = new Date(
                `${initialEndDate[0]}/${initialEndDate[1]}/${initialEndDate[2]} 23:59:59`
              );

              if (currentDate >= startDate && currentDate <= endDate) {
                if (
                  currentTime >= vacation.start_hour &&
                  currentTime <= vacation.end_hour
                ) {
                  segment.cssClass = 'bg-disabled';
                }
              }
            });
          }

          if (employeeInfo[0].schedule_v1.length > 0) {
            employeeInfo[0].schedule_v1.map((restDays: any) => {
              if (
                Number(restDays.cabin_user_day) ==
                  Number(this.getRestDayNumber(segment.date.getDay())) &&
                restDays.has_rest_day
              ) {
                segment.cssClass = 'bg-disabled';
              }
            });
          }

          const startDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          const endDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          if (
            startDateSegment >= employeeInfo[0].schedule.food_start &&
            endDateSegment < employeeInfo[0].schedule.food_end
          ) {
            segment.cssClass = 'bg-disabled';
          }

          /*if (hour?.length !== 0) {
            const endDateHour = `${this.endSession.hour}:${this.endSession.minute}:00`;
            const startDateHour = `${this.startSession.hour}:${this.startSession.minute}:00`;
            if (
              (employeeInfo[0].schedule.food_start <= startDateHour &&
                employeeInfo[0].schedule.food_end > startDateHour) ||
              (employeeInfo[0].schedule.food_start <= endDateHour &&
                employeeInfo[0].schedule.food_end > endDateHour)
            ) {
              flagDisabled = true;
            }
          } */
        });
      });
    });
  }

  beforeDayViewRender4(
    renderEvent: CalendarDayViewBeforeRenderEvent,
    cabinId: number
  ) {
    console.log('-- Cabin Id: ', cabinId);
    console.log('-- TEST INFO 4: ', this.employees4);
    const index = cabinId ? cabinId - 1 : -1;
    const cabinInfo = this.cabins[index];
    let employeeInfo =
      cabinInfo.total_users === 1
        ? this.getSimpleUserData(cabinInfo, this.employees4)
        : this.getSingleUserMultipleCabin(cabinInfo);
    console.log('employees: ', employeeInfo);
    console.log('vacaciones: ', this.vacations);
    const filterVacations = this.vacations.filter(
      (vac: any) => vac.user.id === employeeInfo[0].id
    );

    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          const currentDate = new Date(segment.date);
          const currentTime = `${('0' + segment.date.getHours()).slice(-2)}:${(
            '0' + segment.date.getMinutes()
          ).slice(-2)}:${('0' + segment.date.getSeconds()).slice(-2)}`;
          /*
            //segment.date.getHours() >= 2 && segment.date.getHours() <= 5) {
            segment.cssClass = 'bg-disabled';
          */
          if (filterVacations[0].vacations.length > 0) {
            filterVacations[0].vacations.forEach((vacation: any) => {
              //console.log('vacations:', vacation);
              const initialStartDate = vacation.start_date.split('-');
              const initialEndDate = vacation.end_date.split('-');
              const startDate = new Date(
                `${initialStartDate[0]}/${initialStartDate[1]}/${initialStartDate[2]}`
              );
              const endDate = new Date(
                `${initialEndDate[0]}/${initialEndDate[1]}/${initialEndDate[2]} 23:59:59`
              );

              if (currentDate >= startDate && currentDate <= endDate) {
                if (
                  currentTime >= vacation.start_hour &&
                  currentTime <= vacation.end_hour
                ) {
                  segment.cssClass = 'bg-disabled';
                }
              }
            });
          }

          if (employeeInfo[0].schedule_v1.length > 0) {
            employeeInfo[0].schedule_v1.map((restDays: any) => {
              if (
                Number(restDays.cabin_user_day) ==
                  Number(this.getRestDayNumber(segment.date.getDay())) &&
                restDays.has_rest_day
              ) {
                segment.cssClass = 'bg-disabled';
              }
            });
          }

          const startDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          const endDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          if (
            startDateSegment >= employeeInfo[0].schedule.food_start &&
            endDateSegment < employeeInfo[0].schedule.food_end
          ) {
            segment.cssClass = 'bg-disabled';
          }

          /*if (hour?.length !== 0) {
            const endDateHour = `${this.endSession.hour}:${this.endSession.minute}:00`;
            const startDateHour = `${this.startSession.hour}:${this.startSession.minute}:00`;
            if (
              (employeeInfo[0].schedule.food_start <= startDateHour &&
                employeeInfo[0].schedule.food_end > startDateHour) ||
              (employeeInfo[0].schedule.food_start <= endDateHour &&
                employeeInfo[0].schedule.food_end > endDateHour)
            ) {
              flagDisabled = true;
            }
          } */
        });
      });
    });
  }

  beforeDayViewRender5(
    renderEvent: CalendarDayViewBeforeRenderEvent,
    cabinId: number
  ) {
    console.log('-- Cabin Id: ', cabinId);
    console.log('-- TEST INFO 5: ', this.employees5);
    const index = cabinId ? cabinId - 1 : -1;
    const cabinInfo = this.cabins[index];
    let employeeInfo =
      cabinInfo.total_users === 1
        ? this.getSimpleUserData(cabinInfo, this.employees5)
        : this.getSingleUserMultipleCabin(cabinInfo);
    console.log('employees: ', employeeInfo);
    console.log('vacaciones: ', this.vacations);
    const filterVacations = this.vacations.filter(
      (vac: any) => vac.user.id === employeeInfo[0].id
    );

    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          const currentDate = new Date(segment.date);
          const currentTime = `${('0' + segment.date.getHours()).slice(-2)}:${(
            '0' + segment.date.getMinutes()
          ).slice(-2)}:${('0' + segment.date.getSeconds()).slice(-2)}`;
          /*
            //segment.date.getHours() >= 2 && segment.date.getHours() <= 5) {
            segment.cssClass = 'bg-disabled';
          */
          if (filterVacations[0].vacations.length > 0) {
            filterVacations[0].vacations.forEach((vacation: any) => {
              console.log('vacations:', vacation);
              const initialStartDate = vacation.start_date.split('-');
              const initialEndDate = vacation.end_date.split('-');
              const startDate = new Date(
                `${initialStartDate[0]}/${initialStartDate[1]}/${initialStartDate[2]}`
              );
              const endDate = new Date(
                `${initialEndDate[0]}/${initialEndDate[1]}/${initialEndDate[2]} 23:59:59`
              );

              if (currentDate >= startDate && currentDate <= endDate) {
                if (
                  currentTime >= vacation.start_hour &&
                  currentTime <= vacation.end_hour
                ) {
                  segment.cssClass = 'bg-disabled';
                }
              }
            });
          }

          if (employeeInfo[0].schedule_v1.length > 0) {
            employeeInfo[0].schedule_v1.map((restDays: any) => {
              if (
                Number(restDays.cabin_user_day) ==
                  Number(this.getRestDayNumber(segment.date.getDay())) &&
                restDays.has_rest_day
              ) {
                segment.cssClass = 'bg-disabled';
              }
            });
          }

          const startDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          const endDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          if (
            startDateSegment >= employeeInfo[0].schedule.food_start &&
            endDateSegment < employeeInfo[0].schedule.food_end
          ) {
            segment.cssClass = 'bg-disabled';
          }

          /*if (hour?.length !== 0) {
            const endDateHour = `${this.endSession.hour}:${this.endSession.minute}:00`;
            const startDateHour = `${this.startSession.hour}:${this.startSession.minute}:00`;
            if (
              (employeeInfo[0].schedule.food_start <= startDateHour &&
                employeeInfo[0].schedule.food_end > startDateHour) ||
              (employeeInfo[0].schedule.food_start <= endDateHour &&
                employeeInfo[0].schedule.food_end > endDateHour)
            ) {
              flagDisabled = true;
            }
          } */
        });
      });
    });
  }

  beforeDayViewRender6(
    renderEvent: CalendarDayViewBeforeRenderEvent,
    cabinId: number
  ) {
    console.log('-- Cabin Id: ', cabinId);
    console.log('-- TEST INFO 6: ', this.employees6);
    const index = cabinId ? cabinId - 1 : -1;
    const cabinInfo = this.cabins[index];
    let employeeInfo =
      cabinInfo.total_users === 1
        ? this.getSimpleUserData(cabinInfo, this.employees6)
        : this.getSingleUserMultipleCabin(cabinInfo);
    console.log('employees: ', employeeInfo);
    console.log('vacaciones: ', this.vacations);
    const filterVacations = this.vacations.filter(
      (vac: any) => vac.user.id === employeeInfo[0].id
    );

    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          const currentDate = new Date(segment.date);
          const currentTime = `${('0' + segment.date.getHours()).slice(-2)}:${(
            '0' + segment.date.getMinutes()
          ).slice(-2)}:${('0' + segment.date.getSeconds()).slice(-2)}`;
          /*
            //segment.date.getHours() >= 2 && segment.date.getHours() <= 5) {
            segment.cssClass = 'bg-disabled';
          */
          if (filterVacations[0].vacations.length > 0) {
            filterVacations[0].vacations.forEach((vacation: any) => {
              console.log('vacations:', vacation);
              const initialStartDate = vacation.start_date.split('-');
              const initialEndDate = vacation.end_date.split('-');
              const startDate = new Date(
                `${initialStartDate[0]}/${initialStartDate[1]}/${initialStartDate[2]}`
              );
              const endDate = new Date(
                `${initialEndDate[0]}/${initialEndDate[1]}/${initialEndDate[2]} 23:59:59`
              );

              if (currentDate >= startDate && currentDate <= endDate) {
                if (
                  currentTime >= vacation.start_hour &&
                  currentTime <= vacation.end_hour
                ) {
                  segment.cssClass = 'bg-disabled';
                }
              }
            });
          }

          if (employeeInfo[0].schedule_v1.length > 0) {
            employeeInfo[0].schedule_v1.map((restDays: any) => {
              if (
                Number(restDays.cabin_user_day) ==
                  Number(this.getRestDayNumber(segment.date.getDay())) &&
                restDays.has_rest_day
              ) {
                segment.cssClass = 'bg-disabled';
              }
            });
          }

          const startDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          const endDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          if (
            startDateSegment >= employeeInfo[0].schedule.food_start &&
            endDateSegment < employeeInfo[0].schedule.food_end
          ) {
            segment.cssClass = 'bg-disabled';
          }

          /*if (hour?.length !== 0) {
            const endDateHour = `${this.endSession.hour}:${this.endSession.minute}:00`;
            const startDateHour = `${this.startSession.hour}:${this.startSession.minute}:00`;
            if (
              (employeeInfo[0].schedule.food_start <= startDateHour &&
                employeeInfo[0].schedule.food_end > startDateHour) ||
              (employeeInfo[0].schedule.food_start <= endDateHour &&
                employeeInfo[0].schedule.food_end > endDateHour)
            ) {
              flagDisabled = true;
            }
          } */
        });
      });
    });
  }

  beforeDayViewRender7(
    renderEvent: CalendarDayViewBeforeRenderEvent,
    cabinId: number
  ) {
    console.log('-- Cabin Id: ', cabinId);
    console.log('-- TEST INFO 7: ', this.employees7);
    const index = cabinId ? cabinId - 1 : -1;
    const cabinInfo = this.cabins[index];
    let employeeInfo =
      cabinInfo.total_users === 1
        ? this.getSimpleUserData(cabinInfo, this.employees7)
        : this.getSingleUserMultipleCabin(cabinInfo);
    console.log('employees: ', employeeInfo);
    console.log('vacaciones: ', this.vacations);
    const filterVacations = this.vacations.filter(
      (vac: any) => vac.user.id === employeeInfo[0].id
    );

    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          const currentDate = new Date(segment.date);
          const currentTime = `${('0' + segment.date.getHours()).slice(-2)}:${(
            '0' + segment.date.getMinutes()
          ).slice(-2)}:${('0' + segment.date.getSeconds()).slice(-2)}`;
          /*
            //segment.date.getHours() >= 2 && segment.date.getHours() <= 5) {
            segment.cssClass = 'bg-disabled';
          */
          if (filterVacations[0].vacations.length > 0) {
            filterVacations[0].vacations.forEach((vacation: any) => {
              console.log('vacations:', vacation);
              const initialStartDate = vacation.start_date.split('-');
              const initialEndDate = vacation.end_date.split('-');
              const startDate = new Date(
                `${initialStartDate[0]}/${initialStartDate[1]}/${initialStartDate[2]}`
              );
              const endDate = new Date(
                `${initialEndDate[0]}/${initialEndDate[1]}/${initialEndDate[2]} 23:59:59`
              );

              if (currentDate >= startDate && currentDate <= endDate) {
                if (
                  currentTime >= vacation.start_hour &&
                  currentTime <= vacation.end_hour
                ) {
                  segment.cssClass = 'bg-disabled';
                }
              }
            });
          }

          if (employeeInfo[0].schedule_v1.length > 0) {
            employeeInfo[0].schedule_v1.map((restDays: any) => {
              if (
                Number(restDays.cabin_user_day) ==
                  Number(this.getRestDayNumber(segment.date.getDay())) &&
                restDays.has_rest_day
              ) {
                segment.cssClass = 'bg-disabled';
              }
            });
          }

          const startDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          const endDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          if (
            startDateSegment >= employeeInfo[0].schedule.food_start &&
            endDateSegment < employeeInfo[0].schedule.food_end
          ) {
            segment.cssClass = 'bg-disabled';
          }

          /*if (hour?.length !== 0) {
            const endDateHour = `${this.endSession.hour}:${this.endSession.minute}:00`;
            const startDateHour = `${this.startSession.hour}:${this.startSession.minute}:00`;
            if (
              (employeeInfo[0].schedule.food_start <= startDateHour &&
                employeeInfo[0].schedule.food_end > startDateHour) ||
              (employeeInfo[0].schedule.food_start <= endDateHour &&
                employeeInfo[0].schedule.food_end > endDateHour)
            ) {
              flagDisabled = true;
            }
          } */
        });
      });
    });
  }

  beforeDayViewRender8(
    renderEvent: CalendarDayViewBeforeRenderEvent,
    cabinId: number
  ) {
    console.log('-- Cabin Id: ', cabinId);
    console.log('-- TEST INFO 8: ', this.employees8);
    const index = cabinId ? cabinId - 1 : -1;
    const cabinInfo = this.cabins[index];
    let employeeInfo =
      cabinInfo.total_users === 1
        ? this.getSimpleUserData(cabinInfo, this.employees8)
        : this.getSingleUserMultipleCabin(cabinInfo);
    console.log('employees: ', employeeInfo);
    console.log('vacaciones: ', this.vacations);
    const filterVacations = this.vacations.filter(
      (vac: any) => vac.user.id === employeeInfo[0].id
    );

    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          const currentDate = new Date(segment.date);
          const currentTime = `${('0' + segment.date.getHours()).slice(-2)}:${(
            '0' + segment.date.getMinutes()
          ).slice(-2)}:${('0' + segment.date.getSeconds()).slice(-2)}`;
          /*
            //segment.date.getHours() >= 2 && segment.date.getHours() <= 5) {
            segment.cssClass = 'bg-disabled';
          */
          if (filterVacations[0].vacations.length > 0) {
            filterVacations[0].vacations.forEach((vacation: any) => {
              console.log('vacations:', vacation);
              const initialStartDate = vacation.start_date.split('-');
              const initialEndDate = vacation.end_date.split('-');
              const startDate = new Date(
                `${initialStartDate[0]}/${initialStartDate[1]}/${initialStartDate[2]}`
              );
              const endDate = new Date(
                `${initialEndDate[0]}/${initialEndDate[1]}/${initialEndDate[2]} 23:59:59`
              );

              if (currentDate >= startDate && currentDate <= endDate) {
                if (
                  currentTime >= vacation.start_hour &&
                  currentTime <= vacation.end_hour
                ) {
                  segment.cssClass = 'bg-disabled';
                }
              }
            });
          }

          if (employeeInfo[0].schedule_v1.length > 0) {
            employeeInfo[0].schedule_v1.map((restDays: any) => {
              if (
                Number(restDays.cabin_user_day) ==
                  Number(this.getRestDayNumber(segment.date.getDay())) &&
                restDays.has_rest_day
              ) {
                segment.cssClass = 'bg-disabled';
              }
            });
          }

          const startDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          const endDateSegment = `${segment.date.getHours()}:${segment.date.getMinutes()}:${segment.date.getSeconds()}`;
          if (
            startDateSegment >= employeeInfo[0].schedule.food_start &&
            endDateSegment < employeeInfo[0].schedule.food_end
          ) {
            segment.cssClass = 'bg-disabled';
          }

          /*if (hour?.length !== 0) {
            const endDateHour = `${this.endSession.hour}:${this.endSession.minute}:00`;
            const startDateHour = `${this.startSession.hour}:${this.startSession.minute}:00`;
            if (
              (employeeInfo[0].schedule.food_start <= startDateHour &&
                employeeInfo[0].schedule.food_end > startDateHour) ||
              (employeeInfo[0].schedule.food_start <= endDateHour &&
                employeeInfo[0].schedule.food_end > endDateHour)
            ) {
              flagDisabled = true;
            }
          } */
        });
      });
    });
  }

  async beforeWeekOrDayViewRender(
    event: CalendarWeekViewBeforeRenderEvent,
    cabinNumber: number
  ) {
    this.hourColumns = event.hourColumns;
    console.log('-- Event: ', event);
    await this.addSelectedDayViewClass('', cabinNumber);
  }

  resetFlagsCalendar(): void {
    this.cabinStyleFlag1 = false;
    this.cabinStyleFlag2 = false;
    this.cabinStyleFlag3 = false;
    this.cabinStyleFlag4 = false;
    this.cabinStyleFlag5 = false;
    this.cabinStyleFlag6 = false;
    this.cabinStyleFlag7 = false;
    this.cabinStyleFlag8 = false;
  }

  /**
   * Returns single array from multiple cabins
   *
   * @param cabinData
   * @returns object userData
   */
  private getSingleUserMultipleCabin(cabinData: any) {
    const datesStart: any = [];
    const datesStartEmp: any = [];
    const datesChecking: any = [];
    const datesEnd: any = [];
    const datesEndEmp: any = [];
    const datesCheckout: any = [];
    let userData: any;

    userData = cabinData.employees.map((dataOuter: any) => {
      return this.employees.filter((dataInner: any) => {
        if (dataInner.id === dataOuter.employee_id) {
          datesStart.push(dataOuter.startHour);
          datesEnd.push(dataOuter.endHour);
          datesChecking.push(dataOuter.checking);
          datesCheckout.push(dataOuter.checkout);
          datesStartEmp.push(dataOuter.startEmployee);
          datesEndEmp.push(dataOuter.endEmployee);

          return dataInner;
        }
      });
    })[0];

    userData[0].startHour = datesStart[0];
    userData[0].endHour = datesEnd[datesEnd.length - 1];
    userData[0].checking = datesChecking[0];
    userData[0].checkout = datesCheckout[0];
    userData[0].startEmployee = datesStartEmp[0];
    userData[0].endEmployee = datesEndEmp[0];

    return userData;
  }

  verifyAddSelectedDay(hourPeriod: String, day: any, cabinId: number): boolean {
    let status: boolean = false;
    let employeeData: any;
    const currentDay = new Date(`${day[2]}/${day[1]}/${day[0]}`);
    console.log('--Current day: ', currentDay);
    const dayNumber = currentDay.getDay() == 0 ? 7 : currentDay.getDay();
    const hour =
      hourPeriod.toLocaleLowerCase().replace('.', '').indexOf('am') !== -1 ||
      hourPeriod.toLocaleLowerCase().replace('.', '').indexOf('pm') !== -1
        ? this.timeConversion(hourPeriod)
        : hourPeriod;

    const cabin = this.cabins[cabinId - 1];
    console.log('cabina test: ', cabin);
    if (cabin.total_users > 1) {
      employeeData = this.getSingleUserMultipleCabin(cabin);
    } else {
      employeeData = this.employees.filter((emp: any) => {
        if (emp.id == cabin.employees[0].employee_id) {
          return emp;
        }

        return;
      });
    }

    console.log('empleados: ', employeeData);
    if (employeeData.length == 0 || employeeData[0].schedule.id == 0) {
      return false;
    }

    if (cabin.total_users > 1) {
      if (
        hour < employeeData[0].startEmployee ||
        hour >= employeeData[0].endEmployee
      ) {
        return true;
      }

      return false;
    } else {
      if (cabin.vacations && cabin.vacations.length != 0) {
        const result = cabin.vacations.filter((data: any) => {
          const splitsDate = data.startDate.split('-');
          const spliteDate = data.endDate.split('-');
          const dateStart = new Date(
            `${splitsDate[0]}/${('0' + splitsDate[1]).slice(-2)}/${(
              '0' + splitsDate[2]
            ).slice(-2)}`
          );
          const dateEnd = new Date(
            `${spliteDate[0]}/${('0' + spliteDate[1]).slice(-2)}/${(
              '0' + spliteDate[2]
            ).slice(-2)}`
          );

          if (currentDay >= dateStart && currentDay <= dateEnd) {
            if (hour >= data.startHour && hour <= data.endHour) {
              return data;
            }

            return;
          }
          return;
        });

        console.log('resultado: ', result);
        if (result.length != 0) {
          console.log('resultado inner: ', result);
          status = true;
        }
      }
      console.log('estatus 1: ', status, hour);
      const splitHours = hour.split(' ');
      console.log('division: ', splitHours);
      /* if (hour < employeeData[0].startHour || hour >= employeeData[0].endHour) {
        console.log(
          'entro aqui??: ',
          hour,
          employeeData[0].startHour,
          employeeData[0].endHour,
          hour < employeeData[0].startHour,
          hour >= employeeData[0].endHour
        );
        return true;
      } */

      console.log('desccanso: ', dayNumber);
      if (!status) {
        //if (employeeData[0].schedule.rest_day == dayNumber) {
        if (
          employeeData[0].schedule_v1[0].cabin_user_day == dayNumber &&
          employeeData[0].schedule_v1[0].has_rest_day
        ) {
          console.log('Entro a status data');
          status = true;
        }

        if (
          hour >= employeeData[0].schedule.food_start &&
          hour < employeeData[0].schedule.food_end
        ) {
          console.log('entro a comida');
          status = true;
        }
      }
    }

    console.log('estatius: ', status);
    return status;
  }

  /**
   * Convert 12 Hours to 24 Hours
   */
  timeConversion(time: String) {
    const [timePeriod, period] = time.split(' ');
    let [hour, minute, second] = timePeriod.split(':');

    if (
      (period.toUpperCase() === 'PM' || period.toUpperCase() === 'P.M') &&
      hour !== '12'
    ) {
      hour = String(Number(hour) + 12);
    }

    if (
      (period.toUpperCase() === 'AM' || period.toUpperCase() === 'A.M') &&
      hour === '12'
    ) {
      hour = '00';
    }

    return `${hour}:${minute}:${second}`;
  }

  // deprecated
  async addSelectedDayViewClass(hour: String, cabinId: number) {
    let flagDisabled = false;
    let flagDisabledCabin = false;
    let flagMultiple = false;
    this.resetFlagsCalendar();
    const vacations = await this.getVacations();
    console.log('vacaciones: ', vacations);

    console.log('-- Cabin Id: ', cabinId);
    const index = cabinId ? cabinId - 1 : -1;
    const cabinInfo = this.cabins[index];
    console.log('--Cabin Info: ', cabinInfo);

    let employeeInfo =
      cabinInfo.total_users === 1
        ? this.getSimpleUserData(cabinInfo, '')
        : this.getSingleUserMultipleCabin(cabinInfo);

    console.log('-- Employee Info: ', employeeInfo);
    /* const filterVacations = vacations.data.filter(
      (vac: any) => vac.user.id === employeeInfo[0].id
    ); */

    this.hourColumns.forEach((column) => {
      column.hours.forEach((segmentHour) => {
        segmentHour.segments.forEach((segment) => {
          //console.log('-- Segment: ', employeeInfo);
          const currentDate = new Date(segment.date);
          const restDay = this.getRestDayNumber(segment.date.getDay());
          const segmentInfo = `${('0' + segment.date.getHours()).slice(-2)}:${(
            '0' + segment.date.getMinutes()
          ).slice(-2)}:${('0' + segment.date.getSeconds()).slice(-2)}`;

          // Code for manage vacations
          // if (vacations.data.length > 0) {
          //   if (segment.date.getHours() >= 8 && segment.date.getHours() <= 22) {
          //     segment.cssClass = 'bg-disabled';
          //   }
          /* console.log(
              '-- Entro al primer if de vacaciones: ',
              filterVacations
            ); */
          /* filterVacations[0].vacations.forEach((vacation: any) => {
              console.log('vacations:', vacation);
              const initialStartDate = vacation.start_date.split('-');
              const initialEndDate = vacation.end_date.split('-');
              const startDate = new Date(
                `${initialStartDate[0]}/${initialStartDate[1]}/${initialStartDate[2]}`
              );
              const endDate = new Date(
                `${initialEndDate[0]}/${initialEndDate[1]}/${initialEndDate[2]} 23:59:59`
              );

              //console.log('-- Fechas: ', startDate, endDate);

              if (currentDate >= startDate && currentDate <= endDate) {
                if (segmentInfo >= '08:00:00' && segmentInfo <= '22:00:00') {
                  segment.cssClass = 'cal-disabled-hour';
                  flagDisabled = true;
                  this.disabledPointer;
                  console.log('aqui entro');
                }
              }
            }); */
          //}
          /*else {
            // old way
            cabinInfo.vacations.forEach((vaca: any) => {
              //console.log('vacations: ', vaca);
              const splitS = vaca.startDate.split('-');
              const splitE = vaca.endDate.split('-');
              const startDate = new Date(
                `${splitS[0]}/${splitS[1]}/${splitS[2]}`
              );
              const endDate = new Date(
                `${splitE[0]}/${splitE[1]}/${splitE[2]} 23:59:59`
              );

              if (currentDate >= startDate && currentDate <= endDate) {
                if (
                  segmentInfo >= vaca.startHour &&
                  segmentInfo <= vaca.endHour
                ) {
                  this.disabledPointer(cabinId);
                  segment.cssClass = 'cal-disabled-hour';
                  flagDisabled = true;
                } else {
                  //console.log('entra aqui pero si esta en  vacacion');
                  flagDisabled = false;
                }
              }
            });
          }

          if (flagDisabled) {
            console.log('hoala migos');
            this.disabledPointer(cabinId);

            this.fetchEvents();
            return true;
          } */

          // Code for block rest day per cabin
          if (employeeInfo[0].schedule_v1.length > 0) {
            employeeInfo[0].schedule_v1.map((restDays: any) => {
              if (
                Number(restDays.cabin_user_day) == Number(restDay) &&
                restDays.has_rest_day
              ) {
                //this.disabledPointer(cabinId);
                segment.cssClass = 'cal-disabled-hour';
                flagDisabledCabin = true;
              }
            });
          } else {
            if (employeeInfo[0].schedule.total_rest_day.indexOf(restDay) > -1) {
              //console.log('rest: ', employeeInfo[0]);
              this.disabledPointer(cabinId);
              segment.cssClass = 'cal-disabled-hour';
              flagDisabled = true;

              return false;
            }
          }

          if (flagDisabledCabin) {
            //console.log('-- que pointer es: ', cabinId);
            this.disabledPointer(cabinId);

            return false;
          }

          // refactor this part
          if (!flagMultiple) {
            if (
              segmentInfo >= employeeInfo[0].schedule.food_start &&
              segmentInfo < employeeInfo[0].schedule.food_end &&
              hour?.length === 0
            ) {
              segment.cssClass = 'cal-disabled-hour';
              flagDisabled = true;
            }

            if (hour?.length !== 0) {
              const endDateHour = `${this.endSession.hour}:${this.endSession.minute}:00`;
              const startDateHour = `${this.startSession.hour}:${this.startSession.minute}:00`;
              if (
                (employeeInfo[0].schedule.food_start <= startDateHour &&
                  employeeInfo[0].schedule.food_end > startDateHour) ||
                (employeeInfo[0].schedule.food_start <= endDateHour &&
                  employeeInfo[0].schedule.food_end > endDateHour)
              ) {
                flagDisabled = true;
              }
            }
          }

          return true;
        });
      });
    });

    return flagDisabled;
  }

  private getSimpleUserData(cabinInfo: any, employees: any) {
    return employees.filter((emp: any) => {
      if (emp.id === cabinInfo.employees[0].employee_id) {
        return emp;
      }

      return;
    });
  }

  // deprecated
  async addSelectedDayViewClass_old(hour?: String, cabinId?: number) {
    this.resetFlagsCalendar();
    let flagDisabled = false;
    let flagMultiple = false;
    let userData: any;
    const index = cabinId ? cabinId - 1 : -1;

    // this.getRestDayNumber

    if (index < 0) {
      return false;
    }

    const cabinData = this.cabins[index];
    console.log('cabina: ', cabinData);
    // check if cabin has more than one user or not
    if (cabinData.total_users > 1) {
      userData = this.getSingleUserMultipleCabin(cabinData);
      flagMultiple = true;
    } else {
      userData = await this.employees.filter((emp: any) => {
        if (emp.id === cabinData.employees[0].employee_id) {
          emp.checking = cabinData.employees[0].checking;
          emp.checkout = cabinData.employees[0].checkout;

          return emp;
        }

        return;
      });
      console.log('holas amigos: ', userData[0]);
      flagMultiple = false;

      if (userData.length == 0 || userData[0].schedule.id == 0) {
        return flagDisabled;
      }
    }

    /* if (flagMultiple) {
      return false;
    } */

    this.hourColumns.forEach((column) => {
      column.hours.forEach((hourSegment) => {
        hourSegment.segments.forEach((segment) => {
          const dateToday = new Date(segment.date);
          const dayRest = this.getRestDayNumber(segment.date.getDay());
          const segmentData = `${('0' + segment.date.getHours()).slice(-2)}:${(
            '0' + segment.date.getMinutes()
          ).slice(-2)}:${('0' + segment.date.getSeconds()).slice(-2)}`;

          // display calendar disabled in vacations loop
          /*cabinData.vacations.forEach((vaca: any) => {
            const splitS = vaca.startDate.split('-');
            const splitE = vaca.endDate.split('-');
            const startDate = new Date(
              `${splitS[0]}/${splitS[1]}/${splitS[2]}`
            );
            const endDate = new Date(
              `${splitE[0]}/${splitE[1]}/${splitE[2]} 23:59:59`
            );

            if (dateToday >= startDate && dateToday <= endDate) {
              if (
                segmentData >= vaca.startHour &&
                segmentData <= vaca.endHour
              ) {
                this.disabledPointer(cabinId);
                segment.cssClass = 'cal-disabled-hour';
                flagDisabled = true;
              } else {
                console.log('entra aqui pero si esta en  vacacion');
                flagDisabled = false;
              }
            }
          });*/

          //if (Number(userData[0].schedule.rest_day) === dayRest) {
          // Check if exists schedule_v1
          //console.log('usuarios: ', userData[0]);
          /*  if (userData[0].schedule_v1.length > 0) {
            console.log('aqui estoy dentro del v1', userData[0]);
          } else {*/
          if (userData[0].schedule.total_rest_day.indexOf(dayRest) > -1) {
            console.log('rest: ', userData[0]);
            this.disabledPointer(cabinId);
            segment.cssClass = 'cal-disabled-hour';
            flagDisabled = true;
          }
          //} */

          /* if (hour == 'verify' && flagMultiple == false) {
            if (
              segmentData >= userData[0].schedule.food_start &&
              segmentData < userData[0].schedule.food_end &&
              hour?.length === 0
            ) {
              segment.cssClass = 'cal-disabled-hour';
              flagDisabled = true;
            }
            flagDisabled = false;
          }

          if (flagMultiple) {
            if (
              segmentData < userData[0].checking ||
              segmentData < userData[0].startEmployee
            ) {
              segment.cssClass = 'cal-disabled-hour';
              flagDisabled = true;
            }

            if (
              segmentData >= userData[0].checkout ||
              segmentData >= userData[0].endEmployee
            ) {
              segment.cssClass = 'cal-disabled-hour';
              flagDisabled = true;
            }
          } else {
            if (segmentData < userData[0].checking) {
              segment.cssClass = 'cal-disabled-hour';
              flagDisabled = true;
            }

            if (segmentData > userData[0].checkout) {
              segment.cssClass = 'cal-disabled-hour';
              flagDisabled = true;
            }
          }

          if (!flagMultiple) {
            if (
              segmentData >= userData[0].schedule.food_start &&
              segmentData < userData[0].schedule.food_end &&
              hour?.length === 0
            ) {
              segment.cssClass = 'cal-disabled-hour';
              flagDisabled = true;
            }

            if (hour?.length !== 0) {
              const endDateHour = `${this.endSession.hour}:${this.endSession.minute}:00`;
              const startDateHour = `${this.startSession.hour}:${this.startSession.minute}:00`;
              if (
                (userData[0].schedule.food_start <= startDateHour &&
                  userData[0].schedule.food_end > startDateHour) ||
                (userData[0].schedule.food_start <= endDateHour &&
                  userData[0].schedule.food_end > endDateHour)
              ) {
                flagDisabled = true;
              }
            }
          } */

          /* if (
            this.selectedDayViewDate &&
            segment.date.getTime() === this.selectedDayViewDate.getTime()
          ) {
            console.log('open modal');
            segment.cssClass = 'cal-day-selected';
            return true;
          } */
        });
      });
    });

    return flagDisabled;
  }

  async disabledPointer(cabinNum: any) {
    console.log('-- Numero: ', cabinNum);
    switch (cabinNum) {
      case 1:
        this.cabinStyleFlag1 = true;
        break;
      case 2:
        this.cabinStyleFlag2 = true;
        break;
      case 3:
        this.cabinStyleFlag3 = true;
        break;
      case 4:
        this.cabinStyleFlag4 = true;
        break;
      case 5:
        this.cabinStyleFlag5 = true;
        break;
      case 6:
        this.cabinStyleFlag6 = true;
        break;
      case 7:
        this.cabinStyleFlag7 = true;
        break;
      case 8:
        this.cabinStyleFlag8 = true;
        break;
      default:
        break;
    }
  }

  // Get rest day number
  getRestDayNumber(restDay: number): number {
    let dayNumber = -1;
    switch (restDay) {
      case 0:
        dayNumber = 7;
        break;
      case 1:
        dayNumber = 1;
        break;
      case 2:
        dayNumber = 2;
        break;
      case 3:
        dayNumber = 3;
        break;
      case 4:
        dayNumber = 4;
        break;
      case 5:
        dayNumber = 5;
        break;
      case 6:
        dayNumber = 6;
        break;
      default:
        dayNumber = 0;
        break;
    }

    return dayNumber;
  }

  /**
   * Create function that will  be used
   * for set the new information in the
   * subscribe and apply the refresh
   *
   * @param action string
   * @param pos number
   * @param Object object
   *
   * @returns void
   */
  draggedAction: any = '';
  eventTimesChanged(
    action: string,
    pos: Number,
    { event, newStart, newEnd }: CalendarEventTimesChangedEvent
  ): void {
    this.draggedAction = action;
    event.start = newStart;
    event.end = newEnd;
    const id = event.id !== undefined ? event.id : 0;

    if (id != 0) {
      // create the object with new  data
      const startDate = new Date(newStart)
        .toLocaleDateString('es-ES')
        .split('/');
      const startHour = new Date(newStart)
        .toLocaleTimeString('es-ES', { hour12: false })
        .split(':');
      const endHour = new Date(newEnd ?? '')
        .toLocaleTimeString('es-ES', { hour12: false })
        .split(':');

      const ampmStart = Number(startHour[0]) >= 12 ? 'pm' : 'am';
      const ampmEnd = Number(endHour[0]) >= 12 ? 'pm' : 'am';

      // Update text of the date
      const startCharacter = event.title.indexOf('(');
      const endCharacter = event.title.indexOf(',');

      const string1 = event.title.slice(0, startCharacter);
      const string2 = event.title.slice(endCharacter);
      event.title = `${string1} (${startHour[0].padStart(
        2,
        '0'
      )}:${startHour[1].padStart(2, '0')} ${ampmStart} a ${endHour[0].padStart(
        2,
        '0'
      )}:${endHour[1].padStart(2, '0')} ${ampmEnd}${string2}`;

      // call to refresh  data at the moment to drag and drop
      this.refreshData(pos, event);

      const obj = {
        start: `${startDate[2]}-${startDate[1]}-${startDate[0]} ${startHour[0]}:${startHour[1]}`,
        end: `${startDate[2]}-${startDate[1]}-${startDate[0]} ${endHour[0]}:${endHour[1]}`,
      };

      // update the information
      this.api.update('update/dragDrop', Number(id), obj).subscribe({
        next: (data) => {
          this.draggedAction = '';
        },
        error: (err) => {},
      });
    }
  }

  refreshData(cabinPos: Number, event: any) {
    switch (cabinPos) {
      case 1: {
        this.events1$.subscribe({
          next: (data) => {
            data = event;
            this.refresh.next();
          },
        });
        break;
      }
      case 2: {
        this.events2$.subscribe({
          next: (data) => {
            data = event;
            this.refresh.next();
          },
        });
        break;
      }
      case 3: {
        this.events3$.subscribe({
          next: (data) => {
            data = event;
            this.refresh.next();
          },
        });
        break;
      }
      case 4: {
        this.events4$.subscribe({
          next: (data) => {
            data = event;
            this.refresh.next();
          },
        });
        break;
      }
      case 5: {
        this.events5$.subscribe({
          next: (data) => {
            data = event;
            this.refresh.next();
          },
        });
        break;
      }
      case 6: {
        this.events6$.subscribe({
          next: (data) => {
            data = event;
            this.refresh.next();
          },
        });
        break;
      }
      case 7: {
        this.events7$.subscribe({
          next: (data) => {
            data = event;
            this.refresh.next();
          },
        });
        break;
      }
      case 8: {
        this.events8$.subscribe({
          next: (data) => {
            data = event;
            this.refresh.next();
          },
        });
        break;
      }
    }
  }

  checkedAllAreasCovered(data: any): void {
    console.log('areas a ver: ', data);
    console.log(data.areas, data.areas.length, Object.keys(data.areas).length);
    this.totalAreasWorked = data.areas.length;

    data.areas.forEach((value: any) => {
      if (value.remaining === 0) {
        this.totalAreasMarked++;
      }
    });
  }

  /**
   * Method that will be used as state machine to manage
   * flags used to show or hide chunks of code
   *
   * @param void
   * @return void
   */
  setStatesFlow(): void {
    console.log('--- SetStatesFlow ---');

    console.log('--- SetStatesFlow: first if ---');
    if (this.displayInfoAppoinment.assisted != 2) {
      console.log('--- SetStatesFlow: start first if ---');
      this.acceptedSessions = true;
      this.assistedFlag = false;
      console.log('--- SetStatesFlow: end first if ---');
    }

    console.log('--- SetStatesFlow: second if ---');
    if (this.displayInfoAppoinment.areas.length !== 0) {
      console.log('--- SetStatesFlow: start work for show areas ---');
      this.areasInfoWorked = this.displayInfoAppoinment.areas.filter(
        (data: any) => data.active == 0
      );
      console.log('--- SetStatesFlow: end work for show areas ---');
    }

    console.log('--- SetStatesFlow: third if ---');
    if (this.displayInfoAppoinment.areasWorked.length !== 0) {
      console.log('--- SetStatesFlow: start areas worked ---');
      this.areasWorkedDone = true;
      console.log('--- SetStatesFlow: end areas worked ---');
    }

    console.log('--- SetStatesFlow: fourth  if ---');
    if (this.displayInfoAppoinment.typeSession === 2) {
      console.log(
        '--- SetStatesFlow: check type session status flag start ---'
      );
      this.typeSession = true;
      this.displayAdvancePaymentsButton = false;
      console.log('--- SetStatesFlow: check type session status flag end ---');
    }

    console.log('--- SetStatesFlow: flag disabled button ---');
    this.flagDisabledButton =
      this.displayInfoAppoinment.assisted == 0 ? true : false;

    console.log('--- SetStatesFlow: session close if ---');
    if (this.displayInfoAppoinment.sessionClose) {
      console.log('--- SetStatesFlow: set session flag start ---');
      this.finishSession = true;
      console.log('--- SetStatesFlow: set session flag end ---');
    }
  }

  /**
   * Set flags at the moment open the information
   * in the date
   *
   * @param void
   * @return void
   */
  setFlags(): void {
    this.messagesPayments = '';
    this.editHourSession = false;
    this.acceptedSessions = false;
    this.noAssistFlag = false;
    this.assistedFlag = false;
    this.reschedule = false;
    this.flagPaymentDone = false;
    this.areasWorkedDone = false;
    this.paymentDone = false;
    this.typeSession = false;
    this.isPaidFlag = false;
    this.flagDisabledButton = false;
    this.valorationFlag = false;
    this.finishSession = false;
  }

  /**
   * Method will be used to set values
   * in the forms used in the modal
   *
   * @param void
   * @return void
   */
  setValuesFormSession() {
    console.log('--- SetValuesFormSession ---');

    // Set values to update session
    console.log('--- SetValuesFormSession: set data for update form ---');
    this.updateFormSession.patchValue({
      sess_id: this.displayInfoAppoinment.session_id,
      cabinNum: this.displayInfoAppoinment.cabin.id,
      cabinNumOrigin: this.displayInfoAppoinment.cabin.id,
      duration: this.displayInfoAppoinment.package.duration,
    });

    // Set session to reschedule it
    console.log('--- SetValuesFormSession: set data for reschedule form ---');
    this.rescheduleForm.patchValue({
      sess_id: this.displayInfoAppoinment.session_id,
    });

    // Set values to advance payments form
    console.log('--- SetValuesFormSession: set value for advance payment ---');
    this.formAddedPayments.patchValue({
      sessionId: this.displayInfoAppoinment.session_id,
      apptId: this.displayInfoAppoinment.appoinment.id,
    });

    console.log('--- SetValuesFormSession: set values for missing form ---');
    this.missingForm.patchValue({
      session_id: this.displayInfoAppoinment.session_id,
      appnt_id: this.displayInfoAppoinment.appoinment.id,
      package_id: this.displayInfoAppoinment.package.id,
    });

    // revision  sesion  valoracion
    console.log('--- SetValuesFormSession: start if valoration flag ---');
    if (this.displayInfoAppoinment.appoinment.id == 'N/A') {
      console.log('--- SetValuesFormSession: into valoration flag ---');
      this.valorationFlag = true;
    }

    console.log('--- SetValuesFormSession: start if payments ---');
    if (this.displayInfoAppoinment.payments.length != 0) {
      // Get payments from request
      console.log('--- SetValuesFormSession: into if payments ---');
      const payments = this.displayInfoAppoinment.payments.filter(
        (payment: any) => {
          return (
            payment.session_number == this.displayInfoAppoinment.numberSession
          );
        }
      )[0];

      console.log('--- SetValuesFormSession: set extra payments value ---');
      console.log(
        '-- Values: ',
        this.displayInfoAppoinment.session_id,
        payments
      );
      console.log('--- SetValuesFormSession: check undefined payment ---');
      if (undefined !== payments) {
        this.extraPaymentsForm.patchValue({
          sessionId: this.displayInfoAppoinment.session_id,
          remainingPayment: Number(payments.payment) == 0 ? 0 : payments.id,
        });

        console.log(
          '--- SetValuesFormSession: before console log payments ---'
        );
        console.log('payments: ', payments);
        this.isPaidFlag = payments.is_paid;
        this.saldoTotal = payments.payment;
        this.oldPayment = payments.payment;

        // Get  payments form
        console.log('--- SetValuesFormSession: set value payments form ---');
        this.paymentsForm.patchValue({
          sessionId: this.displayInfoAppoinment.session_id,
          remainingPaymentId: Number(payments.payment) == 0 ? 0 : payments.id,
          amount: Number(payments.payment) == 0 ? 0 : payments.payment,
          original_amount: Number(payments.payment) == 0 ? 0 : payments.payment,
          apptId: this.displayInfoAppoinment.appoinment.id,
        });
      }
    }

    console.log('--- SetValuesFormSession: end ---');
  }

  async handleEvent3(id: Number) {
    console.dir('Sesion Id: ', id);
    this.setFlags();
    const dataSession = await this.getDataPerSessId(id);
    this.displayInfoAppoinment = dataSession.data;
    console.log('*******');
    console.log(this.displayInfoAppoinment);
    console.log('*******');

    // set values in hours for reschedule session
    const time1 = this.displayInfoAppoinment.timeStart.split(' ');
    const time2 = this.displayInfoAppoinment.timeEnd.split(' ');
    console.log('Time 1: ', time1);
    const timeStart = time1[0].split(':');
    const timeEnd = time2[0].split(':');
    this.startSession = {
      hour: parseInt(timeStart[0]),
      minute: parseInt(timeStart[1]),
      second: 0,
    };
    this.endSession = {
      hour: parseInt(timeEnd[0]),
      minute: parseInt(timeEnd[1]),
      second: 0,
    };

    // Call functions requires
    this.setStatesFlow();
    this.setValuesFormSession();

    // Confirm Assistance
    if (
      this.displayInfoAppoinment.confirmed == 0 &&
      this.displayInfoAppoinment.typeSession !== 3 &&
      this.displayInfoAppoinment.typeSession !== 4
    ) {
      this.modalClose = this.modal.open(this.confirmAssited, { size: 'lg' });
      return;
    }

    // Check capacitation session
    if (
      this.displayInfoAppoinment.typeSession === 3 ||
      this.displayInfoAppoinment.typeSession === 4
    ) {
      console.log('entro aqui');
      this.modalClose = this.modal.open(this.closeCapacitation, { size: 'md' });
      return;
    }

    this.paymentsInfo = this.displayInfoAppoinment.payments.map(
      (payment: any) => {
        console.log('data: ', payment);
        if (this.displayInfoAppoinment.typeSession == 2) {
          return;
        }

        if (
          this.displayInfoAppoinment.numberSession ==
            parseInt(payment.session_number) &&
          payment.is_paid
        ) {
          this.paymentDone = true;
          this.flagPaymentDone = true;
        }

        return {
          id: payment.id,
          sessionNumber: payment.session_number,
          payment: payment.payment,
          datePaid: payment.date_paid,
        };
      }
    );

    console.log('info ', this.paymentsInfo);

    if (this.paymentsInfo == undefined || this.paymentsInfo.length == 0) {
      this.areasWorkedDone = false;
      this.paymentDone = true;
      this.typeSession = false;
    }

    this.modalClose = this.modal.open(this.modalContent, { size: 'xl' });
  }

  async handleEvent2(id: Number) {
    console.log('holas handled', id);
    this.messagesPayments = '';
    this.submitAssisted = false;
    this.flagMessageAreas = false;
    this.assistedFlag = false;
    this.flagPaymentDone = false;
    this.flagAreasWorked = false;
    this.flagSessionClose = false;
    this.flagIsPayment = false;
    this.flagDisabledButton = false;
    // New Flags
    this.acceptedSessions = false;
    this.paymentDone = false;
    this.areasWorkedDone = false;
    this.reschedule = false;
    this.finishSession = false;
    this.submittedApf = false;
    this.newPaymentsAdded = false;
    this.messageAdvancePayment = '';
    this.typeSession = false;
    this.noAssistFlag = false;
    this.valorationFlag = false;
    this.editHourSession = false;

    console.log('appoinments: ', this.completeAppoinments);
    console.log('pacckages: ', this.packages);
    console.log('event:', id);

    const dataSession = await this.getDataPerSessId(id);
    this.displayInfoAppoinment = dataSession.appoinments;
    console.log('session selected: ', this.displayInfoAppoinment);

    if (this.displayInfoAppoinment.length == 0) {
      console.log('error appoinnments empty:', this.displayInfoAppoinment);
      return;
    }

    console.log('outside: ', this.displayInfoAppoinment);

    if (this.displayInfoAppoinment[0].sessions.type_session === 3) {
      this.modalClose = this.modal.open(this.closeCapacitation, { size: 'md' });
      return;
    }

    // revision  sesion  valoracion
    if (this.displayInfoAppoinment[0].id == 'N/A') {
      this.valorationFlag = true;
    }

    if (this.displayInfoAppoinment[0].sessions.type_session === 2) {
      this.typeSession = true;
      this.displayAdvancePaymentsButton = false;
    }

    //  show or hide directly the areas worked
    if (this.displayInfoAppoinment[0].sessions.complete_session == false) {
      this.paymentDone = true;
    }

    this.hasRemaining =
      this.displayInfoAppoinment[0].packages.remaining_total == 0;
    if (this.hasRemaining) {
      this.paymentDone = true;
    }

    // set data sess_id and cabinNum
    this.updateFormSession.patchValue({
      sess_id: this.displayInfoAppoinment[0].sessions.id,
      cabinNum: this.displayInfoAppoinment[0].sessions.cabinId,
      cabinNumOrigin: this.displayInfoAppoinment[0].sessions.cabinId,
      duration: this.displayInfoAppoinment[0].packages.duration,
    });

    const currentSessionFromComplete =
      this.displayInfoAppoinment[0].sessions.numberSession;

    if (this.displayInfoAppoinment[0].sessions.type_session != 2) {
      this.checkedAllAreasCovered(this.displayInfoAppoinment[0]);
    }
    this.rescheduleForm.patchValue({
      sess_id: this.displayInfoAppoinment[0].sessions.id,
    });
    // set values in hours for reschedule session
    const time1 = this.displayInfoAppoinment[0].sessions.timeStart.split(' ');
    const time2 = this.displayInfoAppoinment[0].sessions.timeEnd.split(' ');
    const timeStart = time1[0].split(':');
    const timeEnd = time2[0].split(':');
    this.startSession = {
      hour: parseInt(timeStart[0]),
      minute: parseInt(timeStart[1]),
      second: 0,
    };
    this.endSession = {
      hour: parseInt(timeEnd[0]),
      minute: parseInt(timeEnd[1]),
      second: 0,
    };

    // modal session confirmed
    const sessNumber = this.displayInfoAppoinment[0].sessions.numberSession;
    if (this.displayInfoAppoinment[0].sessions.confirmed == 0) {
      console.log('holas');
      this.modalClose = this.modal.open(this.confirmAssited, { size: 'lg' });
      return;
    }

    if (this.displayInfoAppoinment[0].sessions.assisted !== 2) {
      this.assistedFlag = false;
      this.acceptedSessions = true;
    }

    this.flagDisabledButton =
      this.displayInfoAppoinment[0].sessions.assisted == 0 ? true : false;
    const numberSession = this.displayInfoAppoinment[0].sessions.numberSession;
    this.flagSessionClose = this.displayInfoAppoinment[0].sessions.sessionClose;
    if (!this.displayInfoAppoinment[0].sessions.sessionClose) {
      this.finalComment.patchValue({
        final_comment: '',
      });
      this.submitComment = false;
      this.disabledCheck = false;
    }

    let remainingPayments;
    if (typeof remainingPayments === 'object') {
      remainingPayments = this.displayInfoAppoinment.map((remaining: any) => {
        return remaining.remaining.map((data: any) => {
          if (numberSession == parseInt(data.session_number)) {
            this.flagIsPayment = true;
          }

          return {
            payment: data.payment,
            session_number: data.session_number,
            id: data.id,
            appt_id: data.appt_id,
          };
        });
      });
    } else {
      remainingPayments = 0;
    }

    this.sessionInfo = this.displayInfoAppoinment.map((areaData: any) => {
      if (areaData.areas.length === 0) return;

      return areaData.areas.map((innerData: any) => {
        /* if (innerData.id === event.id) {
          this.flagAreasWorked = true;
        } */

        return {
          price: innerData.price,
          name: innerData.name,
          duration: innerData.duration,
          total: innerData.total,
          remaining: innerData.remaining,
          finished: innerData.finished,
          active: innerData.active,
          user_shared: innerData.user_shared,
        };
      });
    });

    this.paymentsInfo = this.displayInfoAppoinment.map((payment: any) => {
      console.log('payment: ', payment);
      if (
        typeof payment.payments === 'number' ||
        payment.sessions.type_session == 2
      )
        return;

      return payment.payments.map((payments: any) => {
        if (
          numberSession == parseInt(payments.session_number) &&
          payments.is_paid
        ) {
          this.flagPaymentDone = true;
          this.paymentDone = true;
        }

        return {
          id: payments.id,
          sessionNumber: payments.session_number,
          payment: payments.payment,
          datePaid: payments.date_paid,
        };
      });
    });
    console.log('payments: ', this.paymentsInfo); //, this.paymentsInfo[0].length);

    if (this.paymentsInfo[0] == undefined || this.paymentsInfo[0].length == 0) {
      this.areasWorkedDone = false;
      this.paymentDone = true;
      this.typeSession = false;
    }

    this.displayInfoAppoinment.map((numSession: any) => {
      if (
        sessNumber == numSession.sessions.numberSession &&
        this.displayInfoAppoinment[0].areasWorked.length !== 0
      ) {
        this.areasWorkedDone = true;
      }

      if (numSession.sessions.sessionClose) {
        this.finishSession = true;
      }

      return;
    });

    let paymentsFilter;
    this.formAddedPayments.patchValue({
      sessionId: this.displayInfoAppoinment[0].sessions.id,
      apptId: this.displayInfoAppoinment[0].id,
    });

    if (
      this.displayInfoAppoinment[0].payments.length !== 0 &&
      typeof this.displayInfoAppoinment[0].payments === 'object'
    ) {
      paymentsFilter = this.displayInfoAppoinment.map((payment: any) => {
        return payment.payments.filter(
          (data: any) => data.session_number == currentSessionFromComplete

          //this.completeAppoinments[0].sessions.numberSession
        );
      });

      console.log('payments: ', paymentsFilter, paymentsFilter[0].length);
      this.isPaidFlag =
        paymentsFilter[0].length == 0 ? false : paymentsFilter[0][0].is_paid;
      this.saldoTotal =
        paymentsFilter[0].length != 0 &&
        (paymentsFilter[0][0].payment != '' ||
          paymentsFilter[0][0].payment <= 0)
          ? paymentsFilter[0][0].payment
          : 0;

      this.oldPayment =
        paymentsFilter[0].length == 0 ? 0 : paymentsFilter[0][0].payment;
      this.paymentsForm.patchValue({
        sessionId: this.displayInfoAppoinment[0].sessions.id,
        remainingPaymentId:
          paymentsFilter[0].length == 0 ? 0 : paymentsFilter[0][0].id,
        amount:
          paymentsFilter[0].length == 0 ? 0 : paymentsFilter[0][0].payment,
        original_amount:
          paymentsFilter[0].length == 0 ? 0 : paymentsFilter[0][0].payment,
        apptId: this.displayInfoAppoinment[0].id,
      });

      this.extraPaymentsForm.patchValue({
        sessionId: this.displayInfoAppoinment[0].sessions.id,
        remainingPayment:
          paymentsFilter[0].length == 0 ? 0 : paymentsFilter[0][0].id,
      });
    } else {
      paymentsFilter = 0;
    }

    if (this.displayInfoAppoinment[0].areas.length !== 0) {
      this.areasInfoWorked = this.displayInfoAppoinment[0].areas.filter(
        (data: any) => data.active == 0
      );
    }

    this.missingForm.patchValue({
      session_id: this.displayInfoAppoinment[0].sessions.id,
      appnt_id: this.displayInfoAppoinment[0].id,
      package_id: this.displayInfoAppoinment[0].packages.id,
    });

    if (this.displayInfoAppoinment[0].areasWorked.length !== 0) {
      this.areasWorkedFlag = true;
      this.flagAreasWorked = true;
    }

    console.log(this.acceptedSessions, this.paymentDone, this.areasWorkedDone);

    //this.modalData = { event, action };
    this.modalClose = this.modal.open(this.modalContent, { size: 'xl' });
  }

  async handleEvent(action: string, event: CalendarEvent, evt: any) {
    console.log('holas handled');
    console.log('evennto: ', action, event, evt);
    console.log('fechas: ', event.start, event.end);

    if (this.draggedAction == 'Dragged' || action == 'Dragged') {
      console.log('holas');
      this.draggedAction = '';
      return;
    }

    this.messagesPayments = '';
    this.submitAssisted = false;
    this.flagMessageAreas = false;
    this.assistedFlag = false;
    this.flagPaymentDone = false;
    this.flagAreasWorked = false;
    this.flagSessionClose = false;
    this.flagIsPayment = false;
    this.flagDisabledButton = false;
    // New Flags
    this.acceptedSessions = false;
    this.paymentDone = false;
    this.areasWorkedDone = false;
    this.reschedule = false;
    this.finishSession = false;
    this.submittedApf = false;
    this.newPaymentsAdded = false;
    this.messageAdvancePayment = '';
    this.typeSession = false;
    this.noAssistFlag = false;
    this.valorationFlag = false;
    this.editHourSession = false;

    console.log('appoinments: ', this.completeAppoinments);
    console.log('pacckages: ', this.packages);
    console.log('event:', event.id);
    /* this.displayInfoAppoinment = this.completeAppoinments.filter(
      (appnt: any) => {
        console.log('hola amigos: ', event.id);
        return event.id === appnt.sessions.id;
      }
    ); */
    const dataSession = await this.getDataPerSessId(event.id);
    this.displayInfoAppoinment = dataSession.appoinments;
    console.log('session selected: ', this.displayInfoAppoinment);

    if (this.displayInfoAppoinment.length == 0) {
      //this.displayInfoAppoinment = this.getDataPerSessId(event.id);

      //console.log('inside: ', this.displayInfoAppoinment);

      //if (this.displayInfoAppoinment.length == 0) {
      console.log('error appoinnments empty:', this.displayInfoAppoinment);
      return;
      //}
    }

    console.log('outside: ', this.displayInfoAppoinment);

    if (this.displayInfoAppoinment[0].sessions.type_session === 3) {
      this.modalClose = this.modal.open(this.closeCapacitation, { size: 'md' });
      return;
    }

    // revision  sesion  valoracion
    if (this.displayInfoAppoinment[0].id == 'N/A') {
      this.valorationFlag = true;
    }

    if (this.displayInfoAppoinment[0].sessions.type_session === 2) {
      this.typeSession = true;
      this.displayAdvancePaymentsButton = false;
    }

    //  show or hide directly the areas worked
    if (this.displayInfoAppoinment[0].sessions.complete_session == false) {
      this.paymentDone = true;
    }

    this.hasRemaining =
      this.displayInfoAppoinment[0].packages.remaining_total == 0;
    if (this.hasRemaining) {
      this.paymentDone = true;
    }

    // set data sess_id and cabinNum
    this.updateFormSession.patchValue({
      sess_id: this.displayInfoAppoinment[0].sessions.id,
      cabinNum: this.displayInfoAppoinment[0].sessions.cabinId,
      cabinNumOrigin: this.displayInfoAppoinment[0].sessions.cabinId,
      duration: this.displayInfoAppoinment[0].packages.duration,
    });

    const currentSessionFromComplete =
      this.displayInfoAppoinment[0].sessions.numberSession;

    if (this.displayInfoAppoinment[0].sessions.type_session != 2) {
      this.checkedAllAreasCovered(this.displayInfoAppoinment[0]);
    }
    this.rescheduleForm.patchValue({
      sess_id: this.displayInfoAppoinment[0].sessions.id,
    });
    // set values in hours for reschedule session
    const time1 = this.displayInfoAppoinment[0].sessions.timeStart.split(' ');
    const time2 = this.displayInfoAppoinment[0].sessions.timeEnd.split(' ');
    const timeStart = time1[0].split(':');
    const timeEnd = time2[0].split(':');
    this.startSession = {
      hour: parseInt(timeStart[0]),
      minute: parseInt(timeStart[1]),
      second: 0,
    };
    this.endSession = {
      hour: parseInt(timeEnd[0]),
      minute: parseInt(timeEnd[1]),
      second: 0,
    };

    // modal session confirmed
    const sessNumber = this.displayInfoAppoinment[0].sessions.numberSession;
    if (this.displayInfoAppoinment[0].sessions.confirmed == 0) {
      console.log('holas');
      this.modalClose = this.modal.open(this.confirmAssited, { size: 'lg' });
      return;
    }

    if (this.displayInfoAppoinment[0].sessions.assisted !== 2) {
      this.assistedFlag = false;
      this.acceptedSessions = true;
    }

    this.flagDisabledButton =
      this.displayInfoAppoinment[0].sessions.assisted == 0 ? true : false;
    const numberSession = this.displayInfoAppoinment[0].sessions.numberSession;
    this.flagSessionClose = this.displayInfoAppoinment[0].sessions.sessionClose;
    if (!this.displayInfoAppoinment[0].sessions.sessionClose) {
      this.finalComment.patchValue({
        final_comment: '',
      });
      this.submitComment = false;
      this.disabledCheck = false;
    }

    let remainingPayments;
    if (typeof remainingPayments === 'object') {
      remainingPayments = this.displayInfoAppoinment.map((remaining: any) => {
        return remaining.remaining.map((data: any) => {
          if (numberSession == parseInt(data.session_number)) {
            this.flagIsPayment = true;
          }

          return {
            payment: data.payment,
            session_number: data.session_number,
            id: data.id,
            appt_id: data.appt_id,
          };
        });
      });
    } else {
      remainingPayments = 0;
    }

    this.sessionInfo = this.displayInfoAppoinment.map((areaData: any) => {
      if (areaData.areas.length === 0) return;

      return areaData.areas.map((innerData: any) => {
        /* if (innerData.id === event.id) {
          this.flagAreasWorked = true;
        } */

        return {
          price: innerData.price,
          name: innerData.name,
          duration: innerData.duration,
          total: innerData.total,
          remaining: innerData.remaining,
          finished: innerData.finished,
          active: innerData.active,
          user_shared: innerData.user_shared,
        };
      });
    });

    this.paymentsInfo = this.displayInfoAppoinment.map((payment: any) => {
      console.log('payment: ', payment);
      if (
        typeof payment.payments === 'number' ||
        payment.sessions.type_session == 2
      )
        return;

      return payment.payments.map((payments: any) => {
        if (
          numberSession == parseInt(payments.session_number) &&
          payments.is_paid
        ) {
          this.flagPaymentDone = true;
          this.paymentDone = true;
        }

        return {
          id: payments.id,
          sessionNumber: payments.session_number,
          payment: payments.payment,
          datePaid: payments.date_paid,
        };
      });
    });
    console.log('payments: ', this.paymentsInfo); //, this.paymentsInfo[0].length);

    if (this.paymentsInfo[0] == undefined || this.paymentsInfo[0].length == 0) {
      this.areasWorkedDone = false;
      this.paymentDone = true;
      this.typeSession = false;
    }

    this.displayInfoAppoinment.map((numSession: any) => {
      if (
        sessNumber == numSession.sessions.numberSession &&
        this.displayInfoAppoinment[0].areasWorked.length !== 0
      ) {
        this.areasWorkedDone = true;
      }

      if (numSession.sessions.sessionClose) {
        this.finishSession = true;
      }

      return;
    });

    let paymentsFilter;
    this.formAddedPayments.patchValue({
      sessionId: this.displayInfoAppoinment[0].sessions.id,
      apptId: this.displayInfoAppoinment[0].id,
    });

    if (
      this.displayInfoAppoinment[0].payments.length !== 0 &&
      typeof this.displayInfoAppoinment[0].payments === 'object'
    ) {
      paymentsFilter = this.displayInfoAppoinment.map((payment: any) => {
        return payment.payments.filter(
          (data: any) => data.session_number == currentSessionFromComplete

          //this.completeAppoinments[0].sessions.numberSession
        );
      });

      console.log('payments: ', paymentsFilter, paymentsFilter[0].length);
      this.isPaidFlag =
        paymentsFilter[0].length == 0 ? false : paymentsFilter[0][0].is_paid;
      this.saldoTotal =
        paymentsFilter[0].length != 0 &&
        (paymentsFilter[0][0].payment != '' ||
          paymentsFilter[0][0].payment <= 0)
          ? paymentsFilter[0][0].payment
          : 0;

      this.oldPayment =
        paymentsFilter[0].length == 0 ? 0 : paymentsFilter[0][0].payment;
      this.paymentsForm.patchValue({
        sessionId: this.displayInfoAppoinment[0].sessions.id,
        remainingPaymentId:
          paymentsFilter[0].length == 0 ? 0 : paymentsFilter[0][0].id,
        amount:
          paymentsFilter[0].length == 0 ? 0 : paymentsFilter[0][0].payment,
        original_amount:
          paymentsFilter[0].length == 0 ? 0 : paymentsFilter[0][0].payment,
        apptId: this.displayInfoAppoinment[0].id,
      });

      this.extraPaymentsForm.patchValue({
        sessionId: this.displayInfoAppoinment[0].sessions.id,
        remainingPayment:
          paymentsFilter[0].length == 0 ? 0 : paymentsFilter[0][0].id,
      });
    } else {
      paymentsFilter = 0;
    }

    if (this.displayInfoAppoinment[0].areas.length !== 0) {
      this.areasInfoWorked = this.displayInfoAppoinment[0].areas.filter(
        (data: any) => data.active == 0
      );
    }

    this.missingForm.patchValue({
      session_id: this.displayInfoAppoinment[0].sessions.id,
      appnt_id: this.displayInfoAppoinment[0].id,
      package_id: this.displayInfoAppoinment[0].packages.id,
    });

    if (this.displayInfoAppoinment[0].areasWorked.length !== 0) {
      this.areasWorkedFlag = true;
      this.flagAreasWorked = true;
    }

    console.log(this.acceptedSessions, this.paymentDone, this.areasWorkedDone);

    this.modalData = { event, action };
    this.modalClose = this.modal.open(this.modalContent, { size: 'xl' });
  }

  patchValuesFormPayments(appointmentInfo: any): void {
    /* const sessionNumber = appointmentInfo[0].sessions.numberSession;
    const sessionId = appointmentInfo[0].sessions.id;
    const allSessions = appointmentInfo[0].payments;
    const sessionFiltered = allSessions.find(
      (data: any) => Number(data.session_number) === Number(sessionNumber)
    );

    if (
      Math.ceil(sessionFiltered.payment) > 0 &&
      !appointmentInfo[0].payment_session.is_paid
    ) {
      this.isActiveForm = true;
      this.isPaid = false;
    }

    this.paymentsForm.setValue({
      paymentMethodId: '',
      paymentId: '0',
      sessionId: sessionId,
      remainingPaymentId: sessionFiltered.id,
      amount: sessionFiltered.payment,
    }); */
  }

  openBoxClose(): void {
    const dates = new Date(`${this.viewDate}z`).toISOString().slice(0, 10);

    this.api.get(`close-box/${dates}`).subscribe({
      next: (data) => {
        this.closeBox = data.data;
        console.log(data);
        this.closeBoxChange = data.change;

        this.modal.open(this.modalCloseBox, { size: 'xl' });
      },
      error: (err) => {},
    });
  }

  openChangeOffice(): void {
    this.modalClose = this.modal.open(this.changeOffice, { size: 'md' });
  }

  reloadNewOffice(): void {
    const obj = {
      office_id: this.changeForm.value.office_id_change,
    };

    this.api
      .update('offices/update', Number(this.loggedEmployee.id), obj)
      .subscribe({
        next: (data) => {
          this.api.setTotalCabins(data.total);
          this.columnSizesCabins(Number(data.total));
          this.modalClose.close();

          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['redirect-calendar']);
        },
        error: (err) => {},
      });
  }

  confirmData(
    status: number,
    total: number,
    id: number,
    date_send: string
  ): void {
    const parameters = `${status}/${total}/${id}/${date_send}`;
    this.api.getById('confirm', parameters).subscribe({
      next: (confirm) => {
        this.closeBox = confirm.data;
      },
      error: (err) => {},
    });
  }

  confirmDataChange(status: number, total: number, date_send: string): void {
    const parameters = `${status}/${total}/${date_send}`;
    this.api.getById('confirm_change', parameters).subscribe({
      next: (confirm) => {
        this.closeBox = confirm.data;
      },
      error: (err) => {},
    });
  }

  private setLabel(type: Number): string {
    let labelReturned: string = '';
    switch (type) {
      case 1:
        labelReturned = 'Agenda una sesión de valoración';
        break;
      case 2:
        labelReturned = 'Agenda una sesión de depilación por área';
        break;
      case 3:
        labelReturned = 'Agendar vacaciones de colaboradoras';
        break;
      case 4:
        labelReturned = 'Capacitación';
        break;
      case 5:
        labelReturned = 'Agenda una sesión de hidrafacial';
        break;
      case 7:
        labelReturned = 'Agenda una sesión de depilación';
        break;
      case 8:
        labelReturned = 'Agenda una junta de trabajo';
        break;
      default:
        labelReturned = 'Agenda una sesión de depilación';
        break;
    }

    return labelReturned;
  }

  showForms(evt: any) {
    this.flagTypeSession = evt.target.value;

    this.dynamicMessage = this.setLabel(Number(evt.target.value));

    if (evt.target.value == 1) {
      this.config.spinners = false;
      this.endSession = this.timeEnd;
    } else {
      this.config.spinners = true;
      this.endSession = {
        hour: '',
        minute: '',
        second: '',
      };
    }
  }

  /**
   * Method will open form to save comments why the user
   * does not assist to depilation date
   */
  openNoAssisstForm(type: any, form: any) {
    console.log('display info: ', this.displayInfoAppoinment.session_id);
    // comments cancel form
    if (form == 1) {
      this.cancelForm.patchValue({
        id: this.displayInfoAppoinment.session_id,
        type: 2,
        comments: '',
      });
    } else {
      this.cancelForm.patchValue({
        id: this.displayInfoAppoinment.session_id,
        type: 0,
        comments: '',
      });
    }
    this.noAssistFlag = type;
  }

  confirmAssitedToSession(type: any, sessionId: Number): void {
    const url = `${sessionId}/${type}`;
    this.api.getById('sessions/assistance', url).subscribe({
      next: (confirm) => {
        this.messageAcceptance = confirm.message;
        this.typeColor = confirm.type == 1 ? 'alert-success' : 'alert-danger';
        this.disabledAcceptanceButton = true;

        setTimeout(() => {
          this.modalClose.close();
          this.fetchEvents();
          this.getCompleteSessionsPerOffice();
          this.refresh.next();
          this.typeColor = '';
          this.disabledAcceptanceButton = false;
          this.messageAcceptance = '';
        }, 3000);
      },
      error: (err) => {},
    });
  }

  noAssitedChecks() {
    const obj = {
      id: this.cancelForm.value.id,
      status: this.cancelForm.value.type,
      comment: this.cancelForm.value.comments,
    };

    console.log('obj: ', obj);
    this.api.create('sessions/nochecks', obj).subscribe({
      next: (session) => {
        //if (session.session.assisted) {
        this.acceptedSessions = true;
        //}

        this.assistedFlag = true;
        this.submitAssisted = true;
        this.noAssistFlag = false;
        this.messageTextAssisted = session.session.assisted
          ? 'Se confirmo la asistencia.'
          : 'Se confirmo la no asistencia.';
        this.flagDisabledButton = session.session.assisted == 0 ? true : false;

        setTimeout(() => {
          this.assistedFlag = false;
          this.submitAssisted = false;
        }, 3000);
        this.fetchEvents();
        this.getCompleteSessionsPerOffice();
      },
      error: (err) => {},
    });
  }

  confirmAssitedToSessionPost() {
    const obj = {
      id: this.cancelForm.value.id,
      type: this.cancelForm.value.type,
      comment: this.cancelForm.value.comments,
    };

    this.api.create('sessions/noassistance', obj).subscribe({
      next: (confirm) => {
        this.messageAcceptance = confirm.message;
        this.typeColor = confirm.type == 1 ? 'alert-success' : 'alert-danger';
        this.disabledAcceptanceButton = true;

        setTimeout(() => {
          this.modalClose.close();
          this.fetchEvents();
          this.getCompleteSessionsPerOffice();
          this.refresh.next();
          this.typeColor = '';
          this.disabledAcceptanceButton = false;
          this.messageAcceptance = '';
          this.noAssistFlag = false;
        }, 3000);
      },
      error: (err) => {},
    });
    console.log('obj cancel: ', obj);
  }

  changeHour(boolValue: boolean): void {
    if (boolValue) {
      const splitData = this.updateFormSession.value.duration.split(':');
      const separationMinutes = +splitData[0] * 60 + +splitData[1];

      const hoursToMinutes =
        +this.startSession.hour * 60 + +this.startSession.minute;
      const initial = this.calculateHoursAndMinutes(hoursToMinutes, 0);
      const final = this.calculateHoursAndMinutes(
        hoursToMinutes,
        separationMinutes
      );

      this.startSession = {
        hour: initial.hours,
        minute: initial.minutes,
        second: 0,
      };
      this.endSession = {
        hour: final.hours,
        minute: final.minutes,
        second: 0,
      };
    } else {
      const hoursToMinutes =
        +this.startSession.hour * 60 + +this.startSession.minute;
      const initial = this.calculateHoursAndMinutes(hoursToMinutes, 0);
      const final = this.calculateHoursAndMinutes(hoursToMinutes, 15);

      this.startSession = {
        hour: initial.hours,
        minute: initial.minutes,
        second: 0,
      };
      this.endSession = {
        hour: final.hours,
        minute: final.minutes,
        second: 0,
      };
    }
  }

  private calculateHoursAndMinutes(timeObject: any, sum: Number) {
    const totalminutes = timeObject + sum;
    const hours = Math.floor(totalminutes / 60);
    const minutes = totalminutes % 60;

    return { hours, minutes };
  }

  showRescheduleForm(flag: boolean): void {
    this.reschedule = flag;
  }

  rescheduleSession(): void {
    this.submitReschedule = true;

    if (this.rescheduleForm.invalid) {
      return;
    }

    const obj = {
      newDay: this.rescheduleForm.value.new_day,
      timeStart: this.startSession,
      timeEnd: this.endSession,
    };
    const id = this.rescheduleForm.value.sess_id;

    this.api.update('sessions/reschedule', Number(id), obj).subscribe({
      next: (updateSess) => {
        this.fetchEvents();
        this.getCompleteSessionsPerOffice();
        this.showRescheduleForm(false);
        this.messageTextRescchedule = updateSess.message;

        setTimeout(() => {
          this.messageTextRescchedule = '';
          this.submitReschedule = false;
          this.close();
          this.rescheduleForm.reset();
          this.refresh.next();
        }, 3000);
      },
      error: (err) => {},
    });
  }

  goToDay(): void {
    const dateGo = this.formCalendarSearch.value.cal_search;
    const dateToGo = `${dateGo.year}-${dateGo.month}-${dateGo.day}`;
    const data: any = {
      value: 'calendarPerClientDate',
      date: dateToGo,
    };
    this.sharedService.callMethodName(data);
    this.getCompleteSessionsPerOffice();
  }

  clearDay(): void {
    this.formCalendarSearch.reset();
    const currentDate = new Date();
    const data = {
      value: 'calendarPerClientDate',
      date: `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}`,
    };

    this.sharedService.callMethodName(data);
  }

  resetExtraPayments() {
    this.extraPaymentsForm.get('paymentMethodId').setValue('');
    this.extraPaymentsForm.get('terminal').setValue('');
    this.extraPaymentsForm.get('paymentId').setValue('');
    this.extraPaymentsForm.get('extra').reset();
    this.newExtraPays = false;
  }

  newPaymentsAddedSession(type: boolean) {
    this.newPaymentsAdded = type ? true : false;

    if (!this.newPaymentsAdded) {
      this.resetAdvancePayments();
    }
  }

  newExtraResources(type: boolean) {
    console.log('newExtraResorces(): ', type);
    this.newExtraPays = type ? true : false;
    console.log('flag =', this.newExtraPays);

    if (!this.newExtraPays) {
      this.resetExtraPayments();
    }
  }

  confirmNewPayment(): void {
    this.submittedApf = true;

    if (this.formAddedPayments.invalid) {
      console.log(this.formAddedPayments);
      this.submittedApf = false;
      return;
    }

    const obj = {
      sessionId: this.formAddedPayments.value.sessionId,
      //remainingPaymentId: this.formAddedPayments.value.remainingPaymentId,
      paymentMethodId: this.formAddedPayments.value.paymentMethodId,
      terminal: this.formAddedPayments.value.terminal,
      paymentId: this.formAddedPayments.value.paymentId,
      amount: this.formAddedPayments.value.amount,
      apptId: this.formAddedPayments.value.apptId,
    };

    this.api.create('payments_sessions/advance', obj).subscribe({
      next: (data) => {
        this.messageAdvancePayment = data.message;
        setTimeout(() => {
          this.resetAdvancePayments();
          this.submittedApf = false;
          this.newPaymentsAdded = false;
          this.messageAdvancePayment = '';
          location.reload();
        }, 3000);
      },
      error: (err) => {},
    });
  }

  resetAdvancePayments() {
    this.formAddedPayments.get('paymentMethodId').setValue('0');
    this.formAddedPayments.get('terminal').setValue('0');
    this.formAddedPayments.get('paymentId').setValue('0');
    this.formAddedPayments.reset();
    this.paymentMethodFlag = false;
  }

  resetExtraPays() {
    this.extraPaymentsForm.get('extra').setValue('');
    this.extraPaymentsForm.get('paymentMethodId').setValue('');
    this.extraPaymentsForm.get('paymentId').setValue('0');
    this.extraPaymentsForm.get('terminal').setValue('0');
  }

  scheduleVacations() {
    this.submittedVacationsForm = true;

    if (this.formVacations.invalid) {
      console.log('holas: ', this.formVacations);
      this.submittedVacationsForm = false;
      return;
    }

    // change to string dates and hours
    const startDate = `${this.formVacations.value.startDate.year}-${this.formVacations.value.startDate.month}-${this.formVacations.value.startDate.day}`;
    const endDate = `${this.formVacations.value.endDate.year}-${this.formVacations.value.endDate.month}-${this.formVacations.value.endDate.day}`;
    const startHour = `${this.startVacations.hour}:${this.startVacations.minute}:${this.startVacations.second}`;
    const endHour = `${this.endVacations.hour}:${this.endVacations.minute}:${this.endVacations.second}`;

    const obj = {
      startDate: startDate,
      endDate: endDate,
      officeId: this.formVacations.value.officeId,
      cabinId: this.formVacations.value.cabinId,
      employeeId: this.formVacations.value.employeeId,
      startHour: startHour,
      endHour: endHour,
    };

    this.api.create('schedule/vacations', obj).subscribe({
      next: (data) => {
        //setTimeout(() => {
        //this.submittedVacationsForm = false;
        //this.close();
        //this.fetchEvents();
        //this.getCompleteSessionsPerOffice();
        //this.refresh.next();
        location.reload();
        //}, 3000);
      },
      error: (err) => {},
    });
  }

  scheduleCapacitation() {
    this.submittedCapacitationForm = true;

    if (this.formCapacitation.invalid) {
      this.submittedCapacitationForm = false;
      return;
    }

    const obj = {
      startDate: this.formCapacitation.value.startDate,
      startHour: this.startCapacitation,
      endHour: this.endCapacitation,
      officeId: this.formCapacitation.value.officeId,
      cabinId: this.formCapacitation.value.cabinId,
      employeeId: this.formCapacitation.value.employeeId,
    };

    this.api.create('sessions/capacitation', obj).subscribe({
      next: (session) => {
        setTimeout(() => {
          this.submittedCapacitationForm = false;
          this.close();
          this.fetchEvents();
          this.getCompleteSessionsPerOffice();
          this.refresh.next();
        }, 3000);
      },
      error: (err) => {},
    });
  }

  scheduleMeeting() {
    this.submittedMeetings = true;

    if (this.formMeetings.invalid) {
      console.log('holas: ', this.formMeetings);
      this.submittedMeetings = false;
      return;
    }

    const obj = {
      cabinId: this.formMeetings.value.cabinId,
      cabinNum: this.formMeetings.value.cabinNum,
      startHour: this.startMeeting,
      endHour: this.endMeeting,
      startDate: this.formMeetings.value.startDate,
      isRepetitive: this.formMeetings.value.isrepetitive ? 1 : 0,
      repeatDay: this.formMeetings.value.repeatDay,
    };

    console.log(obj);
    this.api.create('session/meetings', obj).subscribe({
      next: (session) => {
        setTimeout(() => {
          this.submittedMeetings = false;
          this.close();
          this.fetchEvents();
          this.getCompleteSessionsPerOffice();
          this.refresh.next();
        }, 3000);
      },
      error: (err) => {},
    });
  }

  showHideSimpleAreas(evt: any) {
    this.flagSimpleAreas = !this.flagSimpleAreas;
    console.log('Areas: ', this.flagSimpleAreas);
    let valueFlag = 1;

    if (this.flagSimpleAreas === false) {
      this.storeAreasPackageId = [];
      valueFlag = 0;
    }

    // get time per area
    console.log('areas: ', this.areasPackagesSelected);
    console.log('paquete: ', this.displayInfoAppoinment[0]);

    this.appoinmentExists.patchValue({
      customAreasSessions: this.storeAreasPackageId,
      flagCustom: valueFlag,
    });
  }

  private getDurationSum(data: any) {
    let sumTime = 0;
    for (let timeData of data) {
      console.log('datos: ', timeData[0].duration);
      const [hours, minutes] = timeData[0].duration.split(':');
      sumTime += +hours * 60 + +minutes;
    }

    const initialTime = this.startSession.hour * 60 + this.startSession.minute;
    console.log('minutos: ', sumTime, initialTime);
    const finalTime = sumTime + initialTime;

    let endHour = Math.floor(finalTime / 60);
    let endMinute = Number(finalTime % 60);

    return `${endHour}:${endMinute}:00`;
  }

  storeValueSelected(evt: any, id: number) {
    let flagCustomValue: any = 1;

    if (evt.target.checked) {
      this.storeAreasPackageId.push(id);
      console.log('holla aqui es custom areas');
      console.log('paquetre: ', this.tempPackageSelected);
      console.log('temp sessionn: ', this.tempCompleteSession);
      console.log('Time  end: ', this.timeSession);

      const data12 = this.tempPackageSelected[0].package.filter((d: any) => {
        console.log('d data: ', d);
      });

      const dataAreasSelected = this.storeAreasPackageId.map((inner: any) => {
        console.log('datos: ', inner);
        return this.tempPackageSelected[0].package.filter(
          (inner2: any) => inner === inner2.sess_id
        );
      });
      console.log('aqui esta los datos de obtener:', dataAreasSelected);
      const finalTime = this.getDurationSum(dataAreasSelected);

      const dateToSet = new Date(`${this.timeSession} ${finalTime}`);
      console.log('dataToSet: ', dateToSet);
      const timeStarted = dateToSet
        .toLocaleTimeString('es-ES', { hour12: false })
        .split(':');
      console.log('time Started: ', timeStarted);

      this.timeEnd = {
        hour: parseInt(timeStarted[0]),
        minute: parseInt(timeStarted[1]),
        second: parseInt(timeStarted[2]),
      };
      this.endSession = this.timeEnd;
    } else {
      const index = this.storeAreasPackageId.indexOf(id);
      console.log('holas aqui es all areas');

      if (index !== -1) {
        this.storeAreasPackageId.splice(index, 1);
      }

      if (this.storeAreasPackageId.length == 0) {
        flagCustomValue = 0;
        this.flagSimpleAreas = false;
      }

      this.endSession = this.tempCompleteTime;
    }

    this.appoinmentExists.patchValue({
      customAreasSessions: this.storeAreasPackageId,
      flagCustom: flagCustomValue,
    });

    console.log('ids: ', this.storeAreasPackageId);
  }

  showFormEditSession(evt: any) {
    this.editHourSession = !this.editHourSession;
  }

  updateDataSession() {
    if (this.updateFormSession.invalid) {
      return;
    }

    const obj = {
      sessionId: this.updateFormSession.value.sess_id,
      cabinOrigin: this.updateFormSession.value.cabinNumOrigin,
      cabinNum: +this.updateFormSession.value.cabinNum,
      startHour: this.startSession.hour,
      startMinute: this.startSession.minute,
      endHour: this.endSession.hour,
      endMinute: this.endSession.minute,
    };

    console.log(obj);
    this.api
      .update('update_session', this.updateFormSession.value.sess_id, obj)
      .subscribe({
        next: (data) => {
          this.modalClose.close();
          this.fetchEvents();
        },
        error: (err) => {},
      });
  }

  deleteCapacitationSession(id: number) {
    this.api.delete('sessions/capacitation', id).subscribe({
      next: (data) => {
        if (data) {
          this.fetchEvents();
          this.modalClose.close();
          this.fetchEvents();
          console.log('holas');
        }
      },
      error: (err) => {},
    });
  }

  /**
   * check if should be deleted
   */
  eventDropped({
    event,
    newStart,
    newEnd,
    allDay,
  }: CalendarEventTimesChangedEvent) {
    console.log('evento: ', event);
    console.log('allDay: ', allDay);
    console.log('newStart: ', newStart);
    console.log('newEnd: ', newEnd);

    const externalIndex = this.externalEvents.indexOf(event);
    console.log('External index: ', externalIndex);

    if (typeof allDay !== 'undefined') {
      event.allDay = allDay;
    }

    if (externalIndex > -1) {
      console.log('entro  aqui?');
      const data1 = this.externalEvents.splice(externalIndex, 1);
      console.log('datos: ', this.externalEvents.splice(externalIndex, 1));
      console.log('data1: ', data1);

      /* this.events1$
        .pipe(
          tap((event1: any) => {
            console.log('que pedo: ', event1, event);
            event1.push(event);
          })
        )
        .subscribe(() => console.log('data updated')); */

      this.api.get('testsess').subscribe({
        next: (data) => {
          if (data) {
            //this.modalClose.close();
            this.refresh.next();
            this.fetchEvents();
          }
        },
        error: (err) => {},
      });
    }

    /* event.start = newStart;
    if (newEnd) {
      event.end = newEnd;
    }
    if (this.view === 'month') {
      this.viewDate = newStart;
      this.activeDayIsOpen = true;
    }
    this.events1$.pipe(
      tap((event1: any) => {
        console.log('test: ', event1, event);
        event1.push(event);
      })
    ); */
  }

  externalDrop(event: CalendarEvent) {
    console.log('holas external drop');
    if (this.externalEvents.indexOf(event) === -1) {
      //this.events = this.events.filter((iEvent) => iEvent !== event);
      //this.externalEvents.push(event);
    }
  }

  /**
   * Method that will be used to create new vacations
   * into the system, was moved to other template
   */
  openVacationsTemplate(evt: any): void {
    this.startVacations = {
      hour: 9,
      minute: 0,
      second: 0,
    };
    this.endVacations = {
      hour: 21,
      minute: 0,
      second: 0,
    };

    this.modalClose = this.modal.open(this.modalVacations, { size: 'lg' });
  }
}
