<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Crear paquete</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-3">
    <a [routerLink]="['/packages/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de paquetes
    </a>
  </div>
  <hr />
  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <ngb-alert
      #selfClosingAlert
      type="danger"
      *ngIf="generalMessage != '' && (isDisplayed || isAbleToPackage)"
    >
      {{ generalMessage }}
    </ngb-alert>
    <ngb-alert
      #selfClosingAlert
      type="success"
      *ngIf="generalMessage != '' && isSaved"
    >
      {{ generalMessage }}
    </ngb-alert>

    <form [formGroup]="packageForm" (ngSubmit)="createPackage()" novalidate>
      <input type="hidden" name="office_id" formControlName="office_id" />
      <div class="accordion" id="packages_sessions">
        <div class="accordion-item">
          <h2 class="accordion-header" id="package-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Creaci&oacute;n del Paquete </span>
              <span
                style="margin-left: 42.5rem !important"
                *ngIf="rolekey == 'admin'"
              >
                Fecha de compra: {{ date_displayed }}
              </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row ms-2 mb-2 fs-6">
                <div class="col-md-6 text-danger">
                  Campos marcados con asterisco (*) son requerios.
                </div>
                <div class="col-md-6">
                  <div class="float-end form-check-inline ms-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      formControlName="is_shared"
                      (change)="sharedPackage($event)"
                      id="is_shared"
                    />
                    <label class="form-check-label ms-2" for="is_shared">
                      Paquete compartido
                    </label>
                  </div>
                  <div class="float-end form-check-inline ms-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      formControlName="isOld"
                      (change)="sharedIsOld($event)"
                      id="isOld"
                    />
                    <label class="form-check-label ms-2" for="isOld">
                      Paquete Liquidado
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-floating mb-3 mt-2 col-md-6">
                  <select
                    #sellsValue
                    name="type_package"
                    id="type_package"
                    formControlName="type_package"
                    class="form-select"
                    aria-label="Venta por *"
                    required
                    (change)="changeTypeSell($event, true)"
                    [ngClass]="{
                      'is-invalid': submitted && f.type_package!.errors,
                      'is-valid': submitted && !f.type_package!.errors
                    }"
                  >
                    <option value="">Seleccione una venta</option>
                    <option value="1">Depilacion</option>
                    <option value="4" *ngIf="officeID == 3 || officeID == 1">
                      Hidrafacial
                    </option>
                    <option value="5" *ngIf="officeID == 3 || officeID == 2">
                      Radiofrecuencia
                    </option>
                  </select>
                  <label for="type_package" class="ms-2"> Venta por * </label>
                </div>

                <div class="form-floating mb-3 mt-2 col-md-6">
                  <select
                    #sellsValue
                    name="subtype_package"
                    id="subtype_package"
                    formControlName="subtype_package"
                    class="form-select"
                    aria-label="Tipo de Venta *"
                    required
                    (change)="changeSubTypeSell($event, false)"
                    [ngClass]="{
                      'is-invalid': submitted && f.subtype_package!.errors,
                      'is-valid': submitted && !f.subtype_package!.errors
                    }"
                  >
                    <option value="">Seleccione un tipo de venta</option>
                    <option value="1">Venta</option>
                    <option value="2">Reventa</option>
                    <!-- <option value="3">Reventa Retoques</option> -->
                  </select>
                  <label for="subtype_package" class="ms-2">
                    Tipo de venta *
                  </label>
                </div>

                <!-- <div class="form-check form-check-inline ms-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="type_package"
                    id="type_package1"
                    value="1"
                    [checked]="true"
                    formControlName="type_package"
                    (change)="changeTypeSell($event)"
                  />
                  <label class="form-check-label" for="type_package1">
                    Paquete nuevo
                  </label>
                </div>
                <div class="form-check form-check-inline ms-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="type_package"
                    id="type_package2"
                    value="2"
                    formControlName="type_package"
                    (change)="changeTypeSell($event)"
                  />
                  <label class="form-check-label" for="type_package2">
                    Reventa
                  </label>
                </div>
                <div class="form-check form-check-inline ms-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="type_package"
                    id="type_package3"
                    value="3"
                    formControlName="type_package"
                    (change)="changeTypeSell($event)"
                  />
                  <label class="form-check-label" for="type_package3">
                    Reventa retoques
                  </label>
                </div>
                <div
                  class="form-check form-check-inline ms-2"
                  *ngIf="
                    countryCode == 'jal' && (officeID == 3 || officeID == 2)
                  "
                >
                  <input
                    type="radio"
                    class="form-check-input"
                    name="type_package"
                    id="type_package4"
                    value="4"
                    formControlName="type_package"
                    (change)="changeTypeSell($event)"
                  />
                  <label for="type_package4" class="form-check-label">
                    Hidrafacial
                  </label>
                </div>
                <div
                  class="form-check form-check-inline ms-2"
                  *ngIf="countryCode == 'jal' && officeID == 3"
                >
                  <input
                    type="radio"
                    class="form-check-input"
                    name="type_package"
                    id="type_package4"
                    value="5"
                    formControlName="type_package"
                    (change)="changeTypeSell($event)"
                  />
                  <label for="type_package4" class="form-check-label">
                    Radiofrecuencia
                  </label>
                </div> -->
              </div>

              <div class="row mt-3" *ngIf="!flagDisabled">
                <div class="col-md-{{ classNumber }}">
                  <div class="form-floating mb-3 mt-2">
                    <select
                      #sellsValue
                      name="sells"
                      id="sells"
                      formControlName="sells"
                      class="form-select"
                      aria-label="Venta de *"
                      required
                      (change)="changeFieldsDynamically($event)"
                      [ngClass]="{
                        'is-invalid': submitted && f.sells!.errors,
                        'is-valid': submitted && !f.sells!.errors
                      }"
                    >
                      <option value="">Seleccione un tipo de venta</option>
                      <option
                        value="Reventa nuevas zonas"
                        *ngIf="typeSellPackage != 1"
                      >
                        Reventa nuevas zonas
                      </option>
                      <option
                        value="Reventa retoques"
                        *ngIf="typeSellPackage != 1"
                      >
                        Reventa retoques
                      </option>
                      <option value="Ingreso" *ngIf="typeSellPackage == 1">
                        Ingreso
                      </option>
                      <option
                        value="Redes Sociales"
                        *ngIf="typeSellPackage == 1"
                      >
                        Redes Sociales
                      </option>
                      <option value="Referido" *ngIf="typeSellPackage == 1">
                        Referido
                      </option>
                      <option value="Valoracion" *ngIf="typeSellPackage != 1">
                        Valoraci&oacute;n
                      </option>
                      <option value="Negritas" *ngIf="typeSellPackage != 1">
                        Recuperaci&oacute;n de Negritas
                      </option>
                      <!-- <option
                        value="{{ name }}"
                        *ngFor="let name of sellsFor"
                      >
                        {{ name }}
                      </option> -->
                    </select>
                    <label for="sells" class=""> Venta de * </label>
                  </div>
                  <div>
                    <div
                      *ngIf="
                        f.sells?.invalid &&
                        flagErrors &&
                        (f.sells?.errors || f.sells?.touched)
                      "
                    >
                      <div
                        class="ms-2 mt-1 mb-2 text-danger"
                        style="margin-top: -15px !important"
                        *ngIf="f.sells?.errors.required"
                      >
                        La selecci&oacute;n del tipo de venta es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Social Networks -->
                <div class="col-md-{{ classNumber }}" *ngIf="socialFlag">
                  <div class="form-floating mb-3 mt-2">
                    <select
                      name="social"
                      id="social"
                      formControlName="social"
                      class="form-select"
                      aria-label="Redes Sociales *"
                      (change)="updateOptions($event, 'Social')"
                      required
                      [ngClass]="{
                        'is-invalid': submitted && f.social?.errors,
                        'is-valid': submitted && !f.social?.errors
                      }"
                    >
                      <option value="">Selecciona una Red Social</option>
                      <option value="{{ sn }}" *ngFor="let sn of optionsSells">
                        {{ sn }}
                      </option>
                    </select>
                    <label for="social_network" class="ms-2">
                      Selecciona una Red Social *
                    </label>
                  </div>
                  <div>
                    <div
                      *ngIf="
                        f.social?.invalid &&
                        flagErrors &&
                        (f.social?.errors || f.social?.touched)
                      "
                    >
                      <div
                        class="ms-2 mt-1 mb-2 text-danger"
                        style="margin-top: -15px !important"
                        *ngIf="f.social?.errors.required"
                      >
                        La selecci&oacute;n de la red social es obligatoria.
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Social Networks -->
                <!-- Client Referer -->
                <div
                  class="form-floating mb-3 col-md-{{ classNumber }}"
                  *ngIf="referalFlag"
                >
                  <input
                    type="hidden"
                    name="client_referer"
                    id="client_referer"
                    class="form-control"
                    required
                    formControlName="client_referer"
                  />
                  <select2
                    [data]="clientsFilter"
                    displaySearchStatus="always"
                    style="width: 100%"
                    placeholder="Clientes *"
                    resettable
                    listPosition="below"
                    formControlName="areas_dynamic"
                    styleMode="material"
                    noResultMessage="Cliente no encontrado"
                    (update)="updateOptions($event, 'Referido')"
                    [ngClass]="{
                      'is-invalid': submitted && f.client_referer.errors,
                      'is-valid': submitted && !f.client_referer.errors
                    }"
                  ></select2>
                  <div>
                    <div
                      *ngIf="
                        f.client_referer?.invalid &&
                        flagErrors &&
                        (f.client_referer?.errors || f.client_referer?.touched)
                      "
                    >
                      <div
                        class="ms-2 mt-1 mb-2 text-danger"
                        style="margin-top: -15px !important"
                        *ngIf="f.client_referer?.errors.required"
                      >
                        El cliente es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-floating mb-3 col-md-{{ classNumber }}"
                  *ngIf="referalFlag"
                >
                  <input
                    type="hidden"
                    name="employeeId"
                    formControlName="employeeId"
                    id="employeeId"
                  />
                  <select2
                    [data]="employeesFilter"
                    style="width: 100%"
                    displaySearchStatus="always"
                    placeholder="Colaborador que realizo la venta *"
                    formControlName="employee_id"
                    listPosition="below"
                    styleMode="material"
                    noResultMessage="Colaborador no encontrado"
                    hideSelectedItems
                    (update)="updateOptions($event, 'Referido', true)"
                  ></select2>
                  <div>
                    <div
                      *ngIf="
                        f.employeeId?.invalid &&
                        flagErrors &&
                        (f.employeeId?.errors || f.employeeId?.touched)
                      "
                    >
                      <div
                        class="ms-2 mt-1 mb-2 text-danger"
                        style="margin-top: -15px !important"
                        *ngIf="f.employeeId?.errors.required"
                      >
                        El colaborador es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Client Referer -->
                <!-- Reventa -->
                <div
                  class="form-floating mb-3 mt-2 col-md-{{ classNumber }}"
                  *ngIf="resaleFlag"
                >
                  <input
                    type="hidden"
                    name="employeeId"
                    formControlName="employeeId"
                    id="employeeId"
                  />
                  <select2
                    [data]="employeesFilter"
                    style="width: 100%"
                    displaySearchStatus="always"
                    placeholder="Colaborador que realizo la reventa *"
                    formControlName="employee_id"
                    listPosition="below"
                    styleMode="material"
                    noResultMessage="Colaborador no encontrado"
                    hideSelectedItems
                    (update)="updateOptions($event, 'Reventa')"
                  ></select2>
                  <div>
                    <div
                      *ngIf="
                        f.employeeId?.invalid &&
                        flagErrors &&
                        (f.employeeId?.errors || f.employeeId?.touched)
                      "
                    >
                      <div
                        class="ms-2 mt-1 mb-2 text-danger"
                        style="margin-top: -15px !important"
                        *ngIf="f.employeeId?.errors.required"
                      >
                        El colaborador es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Reventa -->
                <!-- Ingreso -->
                <div class="col-md-{{ classNumber }}" *ngIf="gioFlag">
                  <div class="form-floating mb-3 mt-2">
                    <input
                      type="text"
                      name="resale"
                      id="resale"
                      class="form-control"
                      formControlName="resale"
                      placeholder="Promotor / GIO *"
                      (blur)="updateOptions($event, 'Ingreso')"
                      required
                      [ngClass]="{
                        'is-invalid': submitted && f.resale.errors,
                        'is-valid': submitted && !f.resale.errors
                      }"
                    />
                    <label for="turno" class="ms-2">Promotor / GIO *</label>
                  </div>
                  <div>
                    <div
                      *ngIf="
                        f.resale?.invalid &&
                        flagErrors &&
                        (f.resale?.errors || f.resale?.touched)
                      "
                    >
                      <div
                        class="ms-2 mt-1 mb-2 text-danger"
                        style="margin-top: -15px !important"
                        *ngIf="f.resale?.errors.required"
                      >
                        El promotor / GIO es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Ingreso -->
                <!-- Valoracion y Referido Enf -->
                <div
                  class="form-floating mb-3 col-md-{{ classNumber }}"
                  *ngIf="
                    (typeSellPackage == 2 ||
                      typeSellPackage == 4 ||
                      typeSellPackage == 5) &&
                    (valoration || negritasFlag)
                  "
                >
                  <input
                    type="hidden"
                    name="employeeId"
                    formControlName="employeeId"
                    id="employeeId"
                  />
                  <select2
                    [data]="employeesFilter"
                    displaySearchStatus="always"
                    style="width: 100%"
                    placeholder="Colaboradora *"
                    resettable
                    listPosition="below"
                    formControlName="employee_id"
                    styleMode="material"
                    noResultMessage="Cliente no encontrado"
                    (update)="updateOptions($event, 'Valoracion')"
                    [ngClass]="{
                      'is-invalid': submitted && f.client_referer.errors,
                      'is-valid': submitted && !f.client_referer.errors
                    }"
                  ></select2>
                  <div>
                    <div
                      *ngIf="
                        f.employee_id?.invalid &&
                        flagErrors &&
                        (f.employee_id?.errors || f.employee_id?.touched)
                      "
                    >
                      <div
                        class="ms-2 mt-1 mb-2 text-danger"
                        style="margin-top: -15px !important"
                        *ngIf="f.employee_id?.errors.required"
                      >
                        La colaboradora es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div
                  class="form-floating mb-3 col-md-{{ classNumber }}"
                  *ngIf="valoration"
                >
                  <input
                    type="hidden"
                    name="employeeId"
                    formControlName="employeeId"
                    id="employeeId"
                  />
                  <select2
                    [data]="employeesFilter"
                    displaySearchStatus="always"
                    style="width: 100%"
                    placeholder="colaboradora *"
                    resettable
                    listPosition="below"
                    formControlName="employee_id"
                    styleMode="material"
                    noResultMessage="Cliente no encontrado"
                    (update)="updateOptions($event, 'Valoracion')"
                    [ngClass]="{
                      'is-invalid': submitted && f.client_referer.errors,
                      'is-valid': submitted && !f.client_referer.errors
                    }"
                  ></select2>
                  <div>
                    <div
                      *ngIf="
                        f.employee_id?.invalid &&
                        flagErrors &&
                        (f.employee_id?.errors || f.employee_id?.touched)
                      "
                    >
                      <div
                        class="ms-2 mt-1 mb-2 text-danger"
                        style="margin-top: -15px !important"
                        *ngIf="f.employee_id?.errors.required"
                      >
                        La colaboradora es obligatorio.
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- Valoracion y Referido Enf -->
              </div>

              <div class="row">
                <div class="form-floating mb-1 col-md-6">
                  <input
                    type="hidden"
                    name="clientId"
                    id="clientId"
                    class="form-control"
                    formControlName="clientId"
                    required
                  />
                  <select2
                    [data]="clientsFilter"
                    displaySearchStatus="always"
                    style="width: 100%"
                    placeholder="Clientes *"
                    resettable
                    listPosition="below"
                    styleMode="material"
                    noResultMessage="Cliente no encontrado"
                    formControlName="client_one"
                    (update)="updateClient($event)"
                    [ngClass]="{
                      'is-invalid': submitted && f.clientId.errors,
                      'is-valid': submitted && !f.clientId.errors
                    }"
                  ></select2>
                  <div>
                    <div
                      *ngIf="
                        f.clientId?.invalid &&
                        flagErrors &&
                        (f.clientId?.errors || f.clientId?.touched)
                      "
                    >
                      <div
                        class="ms-2 text-danger"
                        style="margin-top: -20px !important"
                        *ngIf="f.clientId?.errors.required"
                      >
                        La selecci&oacute;n del cliente es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="mb-1 form-floating col-md-6"
                  *ngIf="rolekey != 'admin'"
                >
                  <p class="fs-2 mt-1 ms-3 text-danger">
                    Fecha de compra: {{ date_displayed }}
                  </p>
                  <input
                    type="hidden"
                    formControlName="date_purchased"
                    id="date_purchased"
                  />
                </div>
                <div
                  class="mb-1 mt-4 col-md-6"
                  *ngIf="rolekey == 'admin' && !flagSharedPackage"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="is_empty"
                    (change)="disabledFields($event)"
                    id="is_empty"
                  />
                  <label class="form-check-label ms-2" for="is_empty">
                    Paquete de regalo
                  </label>
                </div>
              </div>

              <div class="row" *ngIf="flagSharedPackage">
                <div class="form-floating col-md-12">
                  <input
                    type="hidden"
                    name="clientShared"
                    id="clientShared"
                    class="form-control"
                    formControlName="clientShared"
                  />
                  <!-- (update)="updateClient($event)" -->
                  <select2
                    [data]="sharedFilter"
                    displaySearchStatus="always"
                    style="width: 100%"
                    placeholder="Con quien compartir paquete *"
                    multiple
                    listPosition="below"
                    styleMode="material"
                    noResultMessage="Cliente no encontrado"
                    formControlName="client_two"
                    (update)="updatedSharedClient($event)"
                    [ngClass]="{
                      'is-invalid': submitted && f.clientShared.errors,
                      'is-valid': submitted && !f.clientShared.errors
                    }"
                  ></select2>
                </div>
              </div>

              <!-- <div class="col-md-12">
                <input
                  type="hidden"
                  name="areas_sessions"
                  formControlName="areas_sessions"
                  id="areas_sessions"
                />
                <select2
                  [data]="packagesFilter"
                  style="width: 100%"
                  displaySearchStatus="always"
                  placeholder="Areas *"
                  formControlName="areas_one"
                  multiple
                  listPosition="below"
                  styleMode="material"
                  noResultMessage="Area no encontrada"
                  hideSelectedItems
                  (update)="updateAreas($event)"
                ></select2>
                <div>
                  <div
                    *ngIf="
                      f.areas_sessions?.invalid &&
                      flagErrors &&
                      (f.areas_sessions?.errors || f.areas_sessions?.touched)
                    "
                  >
                    <div
                      class="ms-2 mb-1 text-danger"
                      style="margin-top: -20px !important"
                      *ngIf="f.areas_sessions?.errors.required"
                    >
                      La selecci&oacute;n de las areas de sesi&oacute;n son
                      obligatorias.
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="col-md-12" *ngIf="!hidroFlag">
                <input
                  type="hidden"
                  name="areas_sessions"
                  formControlName="areas_sessions"
                  id="areas_sessions"
                />
                <select2
                  [data]="packagesFilter"
                  style="width: 100%"
                  displaySearchStatus="always"
                  placeholder="Areas *"
                  formControlName="areas_one"
                  multiple
                  listPosition="below"
                  styleMode="material"
                  noResultMessage="Area no encontrada"
                  hideSelectedItems
                  (update)="updateAreas($event)"
                ></select2>
                <div>
                  <div
                    *ngIf="
                      f.areas_sessions?.invalid &&
                      flagErrors &&
                      (f.areas_sessions?.errors || f.areas_sessions?.touched)
                    "
                  >
                    <div
                      class="ms-2 mb-1 text-danger"
                      style="margin-top: -20px !important"
                      *ngIf="f.areas_sessions?.errors.required"
                    >
                      La selecci&oacute;n de las areas de sesi&oacute;n son
                      obligatorias.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12" *ngIf="hidroFlag">
                <input
                  type="hidden"
                  name="areas_sessions"
                  formControlName="areas_sessions"
                  id="areas_sessions"
                />
                <select2
                  [data]="hidroFacialFilter"
                  style="width: 100%"
                  displaySearchStatus="always"
                  placeholder="Areas *"
                  formControlName="areas_one"
                  multiple
                  listPosition="below"
                  styleMode="material"
                  noResultMessage="Area no encontrada"
                  hideSelectedItems
                  (update)="updateAreasHidro($event)"
                ></select2>
                <div>
                  <div
                    *ngIf="
                      f.areas_sessions?.invalid &&
                      flagErrors &&
                      (f.areas_sessions?.errors || f.areas_sessions?.touched)
                    "
                  >
                    <div
                      class="ms-2 mb-1 text-danger"
                      style="margin-top: -20px !important"
                      *ngIf="f.areas_sessions?.errors.required"
                    >
                      La selecci&oacute;n de las areas de sesi&oacute;n son
                      obligatorias.
                    </div>
                  </div>
                </div>
              </div>

              <input
                type="hidden"
                name="size"
                formControlName="size"
                id="size"
              />
              <div class="card mb-2" *ngIf="ocultar">
                <div class="card-body">
                  <!--h5 class="card-title">Sesiones por area</h5-->
                  <div class="row">
                    <div class="ms-1 col-md-4">
                      <h5>Sesiones por area</h5>
                    </div>
                    <div class="col-md-4">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="size"
                        id="size0"
                        value="0"
                        [attr.checked]="true"
                        (change)="updatePriceWithGiftArea($event, 0, 0)"
                      />
                      <label class="form-check-label ms-1" for="type1">
                        Sin area de regalo
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div
                    class="row mb-2"
                    *ngFor="let fields of areasNames; let idx = index"
                  >
                    <div class="col-md-2 form-floating">
                      <input
                        type="text"
                        name="custom-area-{{ fields.value }}"
                        id="custom-area-{{ fields.value }}"
                        class="form-control"
                        placeholder="Area *"
                        value="{{ fields.label }}"
                        readonly
                      />
                      <label for="" class="ms-2"> Area * </label>
                    </div>
                    <div class="col-md-4 form-floating">
                      <!-- <input
                        type="text"
                        name="custom-session-{{ fields.value }}"
                        id="custom-session-{{ fields.value }}"
                        class="form-control"
                        placeholder="Numero de Sesiones *"
                        (keypress)="numbersOnly($event)"
                        (blur)="setValueCustomAreas($event, fields.value)"
                      /> -->
                      <select
                        name="custom-session-{{ fields.value }}"
                        id="custom-session-{{ fields.value }}"
                        class="form-control"
                        placeholder="Numero de Sesiones"
                        (change)="setValueCustomAreas($event, fields.value)"
                      >
                        <option value="" selected>
                          Seleccione el numero de sesiones *
                        </option>
                        <option
                          value="{{ i }}"
                          *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                        >
                          {{ i }} {{ i == 1 ? "Sesion" : "Sesiones" }}
                        </option>
                      </select>
                      <label for="" class="ms-2"> Numero de Sesiones * </label>
                    </div>
                    <div class="col-md-3 form-floating">
                      <select
                        name="custom-client-{{ fields.value }}"
                        id="custom-client-{{ fields.value }}"
                        class="form-control"
                        placeholder="Cliente"
                        (change)="setValueCustomClients($event, fields.value)"
                      >
                        <option value="" selected>Seleccione un cliente</option>
                        <option
                          value="{{ i.id }}"
                          *ngFor="let i of completeClientsList"
                        >
                          {{ i.name }}
                        </option>
                      </select>
                      <label for="" class="ms-2">Selecciona un cliente</label>
                    </div>
                    <div class="col-md-1 mt-2" *ngIf="flagSharedPackage">
                      <button
                        class="btn btn-primary btn-lg"
                        type="button"
                        (click)="addExtraData(fields.value)"
                        ngbTooltip="Agregar nueva area"
                        placement="top"
                      >
                        <i class="bi bi-plus-lg"></i>
                      </button>
                    </div>
                    <div class="col-md-{{ classAreas }}">
                      <div
                        class="ms-2 mt-3 text-danger"
                        *ngIf="fields.size != 3"
                      >
                        No disponible para area de regalo
                      </div>
                      <div
                        class="form-check ms-2 mt-3"
                        *ngIf="fields.size == 3"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="size"
                          id="size{{ idx }}"
                          value="{{ fields.value }}"
                          (change)="
                            updatePriceWithGiftArea($event, 1, fields.value)
                          "
                        />
                        <label class="form-check-label" for="type1">
                          Area de regalo
                        </label>
                      </div>
                    </div>
                  </div>

                  <div formArrayName="extraData" *ngIf="flagSharedPackage">
                    <div
                      class="row mb-2"
                      *ngFor="
                        let field of totalExtraData().controls;
                        let i = index
                      "
                      [formGroupName]="i"
                    >
                      <div class="col-md-2 form-floating">
                        <input
                          type="hidden"
                          name="areaId"
                          formControlName="areaId"
                        />
                        <input
                          type="text"
                          id="areaName"
                          formControlName="areaName"
                          name="areaName"
                          class="form-control"
                          placeholder="Area *"
                          readonly
                        />
                        <label for="" class="ms-2"> Area * </label>
                      </div>
                      <div class="col-md-4 form-floating">
                        <select
                          #extraNumberSess
                          name="numberSession"
                          id="numberSession"
                          formControlName="numberSession"
                          class="form-control"
                          placeholder="Numero de Sesiones"
                          (change)="updateExtraAreas(i, extraNumberSess.value)"
                        >
                          <option value="" selected>
                            Seleccione el numero de sesiones *
                          </option>
                          <option
                            value="{{ idx }}"
                            *ngFor="let idx of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                          >
                            {{ idx }} {{ idx == 1 ? "Sesion" : "Sesiones" }}
                          </option>
                        </select>
                        <label for="" class="ms-2">
                          Numero de Sesiones *
                        </label>
                      </div>
                      <div class="col-md-3 form-floating">
                        <select
                          name="clientId"
                          id="clientId"
                          formControlName="clientId"
                          class="form-control"
                          placeholder="Cliente"
                        >
                          <option value="" selected>
                            Seleccione un cliente
                          </option>
                          <option
                            value="{{ i.id }}"
                            *ngFor="let i of completeClientsList"
                          >
                            {{ i.name }}
                          </option>
                        </select>
                        <label for="" class="ms-2">Selecciona un cliente</label>
                      </div>
                      <div class="col-md-1 mt-2">
                        <button
                          class="btn btn-danger btn-lg"
                          type="button"
                          (click)="removeExtraData(i)"
                          ngbTooltip="Eliminar nueva area"
                          placement="top"
                        >
                          <i class="bi bi-x-lg"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--div class="col-md-12">
                <input
                  type="hidden"
                  name="areas_gifts"
                  formControlName="areas_gifts"
                  id="areas_gifts"
                />
                <select2
                  [data]="packagesGiftsFilter"
                  style="width: 100%"
                  displaySearchStatus="always"
                  placeholder="Areas de regalo"
                  formControlName="areas_two"
                  multiple
                  listPosition="below"
                  styleMode="material"
                  noResultMessage="Area de regalo no encontrada"
                  hideSelectedItems
                  (update)="updateAreasGift($event)"
                ></select2>
              </div-->

              <div class="col-md-12">
                <div class="row">
                  <div class="form-floating mb-3 mt-2 col-md-6">
                    <!--select
                      name="session_number"
                      id="session_number"
                      class="form-control"
                      formControlName="session_number"
                      placeholcer="Numero de sesiones *"
                      (change)="updatePrice($event)"
                      [ngClass]="{
                        'is-invalid': submitted && f.session_number.errors,
                        'is-valid': submitted && !f.session_number.errors
                      }"
                    >
                      <option value="" selected>
                        Selecciona el numero de sesiones
                      </option>
                      <option
                        value="{{ i }}"
                        *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      >
                        {{ i }} {{ i == 1 ? "Sesion" : "Sesiones" }}
                      </option>
                    </select -->
                    <input
                      type="text"
                      name="session_number"
                      id="session_number"
                      formControlName="session_number"
                      placeholder="Total de sesiones del paquete *"
                      class="form-control"
                      readonly
                    />
                    <label for="session_number" class="ms-2">
                      Total de sesiones del paquete *
                    </label>
                    <div>
                      <div
                        *ngIf="
                          f.session_number?.invalid &&
                          flagErrors &&
                          (f.session_number?.errors ||
                            f.session_number?.touched)
                        "
                      >
                        <div
                          class="ms-2 mt-1 text-danger"
                          *ngIf="f.session_number?.errors.required"
                        >
                          El numero de sesiones es obligatorio.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="duration" class="form-label"
                      >Duraci&oacute;n</label
                    >
                    <div class="input-group mb-3">
                      <input
                        type="number"
                        name="hours"
                        id="hours"
                        formControlName="hours"
                        class="form-control"
                        placeholder="Ejemplo: 5"
                        [ngClass]="{
                          'is-invalid': submitted && f.hours.errors,
                          'is-valid': submitted && !f.hours.errors
                        }"
                      />
                      <span class="input-group-text">horas</span>
                      <input
                        type="number"
                        name="minutes"
                        id="minutes"
                        formControlName="minutes"
                        class="form-control"
                        placeholder="Ejemplo: 30"
                        [ngClass]="{
                          'is-invalid': submitted && f.minutes.errors,
                          'is-valid': submitted && !f.minutes.errors
                        }"
                      />
                      <span class="input-group-text">minutos</span>
                    </div>
                    <div>
                      <div
                        *ngIf="
                          f.hours?.invalid &&
                          f.minutes?.invalid &&
                          flagErrors &&
                          (f.hours?.errors ||
                            f.minutes?.errors ||
                            f.hours?.touched ||
                            f.minutes?.touched)
                        "
                      >
                        <div
                          class="ms-2 text-danger"
                          style="margin-top: -15px !important"
                          *ngIf="
                            f.hours?.errors.required ||
                            f.minutes?.errors.required
                          "
                        >
                          La duraci&oacute;n es obligatoria.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="!flagDisabled">
                  <div class="col-md-4">
                    <input
                      type="hidden"
                      name="realPrice"
                      formControlName="realPrice"
                      id="realPrice"
                    />
                    <label for="price" class="form-label">Total *</label>
                    <div class="input-group mb-3">
                      <span class="input-group-text">$</span>
                      <input
                        type="text"
                        class="form-control"
                        id="price"
                        name="price"
                        formControlName="price"
                        placeholder="Ejemplo: $4,560.75"
                        required
                        readonly
                        [ngClass]="{
                          'is-invalid': submitted && f.price.errors,
                          'is-valid': submitted && !f.price.errors
                        }"
                      />
                    </div>
                    <div>
                      <div
                        *ngIf="
                          f.price?.invalid &&
                          flagErrors &&
                          (f.price?.errors || f.price?.touched)
                        "
                      >
                        <div
                          class="ms-2 text-danger"
                          style="margin-top: -15px !important"
                          *ngIf="f.price?.errors.required"
                        >
                          El total es obligatorio.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="subtotal" class="form-label">
                      Total con descuento *
                    </label>
                    <div class="input-group mb-3">
                      <span class="input-group-text">$</span>
                      <input
                        type="text"
                        class="form-control"
                        id="subtotal"
                        name="subtotal"
                        formControlName="subtotal"
                        placeholder="Ejemplo: $4,560.75"
                        (keyup)="calculatePrice($event)"
                        required
                        [ngClass]="{
                          'is-invalid': submitted && f.subtotal.errors,
                          'is-valid': submitted && !f.subtotal.errors
                        }"
                      />
                    </div>
                    <div>
                      <div
                        *ngIf="
                          f.subtotal?.invalid &&
                          flagErrors &&
                          (f.subtotal?.errors || f.subtotal?.touched)
                        "
                      >
                        <div
                          class="ms-2 text-danger"
                          style="margin-top: -15px !important"
                          *ngIf="f.subtotal?.errors.required"
                        >
                          El total con descuento es obligatorio.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="" class="form-label">
                      Descuento aplicado %
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="percentage"
                        name="percentage"
                        formControlName="percentage"
                        placeholder="Ejemplo: 10%"
                        required
                        readonly
                        [ngClass]="{
                          'is-invalid': submitted && f.percentage.errors,
                          'is-valid': submitted && !f.percentage.errors
                        }"
                      />
                      <span class="input-group-text">%</span>
                    </div>
                    <div>
                      <div
                        *ngIf="
                          f.percentage?.invalid &&
                          flagErrors &&
                          (f.percentage?.errors || f.percentage?.touched)
                        "
                      >
                        <div
                          class="ms-2 mb-2 text-danger"
                          style="margin-top: -15px !important"
                          *ngIf="f.percentage?.errors.required"
                        >
                          El descuento aplicado es obligatorio.
                        </div>
                      </div>
                      <div *ngIf="flagErrors && flagPercentage">
                        <div class="ms-2 mb-2 text-danger">
                          Porcentaje invalido, excede el porcentaje de acuerdo a
                          las areas seleccionadas.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--{{ this.packageForm.value | json }}-->
                <div class="row" *ngIf="!flagDisabled">
                  <div class="col-md-6">
                    <label for="price" class="form-label"> Anticipo * </label>
                    <div class="input-group mb-3">
                      <span class="input-group-text">$</span>
                      <input
                        type="text"
                        class="form-control"
                        id="advance_payments"
                        name="advance_payments"
                        formControlName="advance_payments"
                        placeholder="0.00"
                        (keyup)="calculateRemaining($event)"
                        required
                        [ngClass]="{
                          'is-invalid': submitted && f.advance_payments.errors,
                          'is-valid': submitted && !f.advance_payments.errors
                        }"
                      />
                    </div>
                    <div class="ms-2 mb-2 text-danger" *ngIf="flagMinimal">
                      {{ minimalMessage }}
                    </div>
                    <div>
                      <div
                        *ngIf="
                          f.advance_payments?.invalid &&
                          flagErrors &&
                          (f.advance_payments?.errors ||
                            f.advance_payments?.touched)
                        "
                      >
                        <div
                          class="ms-2 mb-2 text-danger"
                          style="margin-top: -15px !important"
                          *ngIf="f.advance_payments?.errors.required"
                        >
                          El anticipo es obligatorio.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="price" class="form-label">
                      Restante a pagar
                    </label>
                    <div class="input-group mb-3">
                      <span class="input-group-text">$</span>
                      <input
                        type="text"
                        class="form-control"
                        id="remaining_total"
                        name="remaining_total"
                        formControlName="remaining_total"
                        placeholder="0.00"
                        readonly
                        required
                        [ngClass]="{
                          'is-invalid': submitted && f.remaining_total.errors,
                          'is-valid': submitted && !f.remaining_total.errors
                        }"
                      />
                    </div>
                    <div>
                      <div
                        *ngIf="
                          f.remaining_total?.invalid &&
                          flagErrors &&
                          (f.remaining_total?.errors ||
                            f.remaining_total?.touched)
                        "
                      >
                        <div
                          class="ms-2 mb-2 text-danger"
                          style="margin-top: -15px !important"
                          *ngIf="f.remaining_total?.errors.required"
                        >
                          El restante a pagar es obligatorio.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="!flagDisabled">
                  <div class="form-floating mb-3 col-md-{{ classPayments }}">
                    <select
                      name="paymentMethodId"
                      id="paymentMethodId"
                      class="form-control"
                      formControlName="paymentMethodId"
                      placeholcer="Metodo de pago *"
                      (change)="checkPaymentMethods($event)"
                      required
                      [ngClass]="{
                        'is-invalid': submitted && f.paymentMethodId.errors,
                        'is-valid': submitted && !f.paymentMethodId.errors
                      }"
                    >
                      <option value="" selected>
                        Seleccione un metodo de pago
                      </option>
                      <option
                        value="{{ paymentMethod.id }}"
                        *ngFor="let paymentMethod of payment_methods"
                      >
                        {{ paymentMethod.payment_name }}
                      </option>
                    </select>
                    <label for="paymentMethodId" class="ms-2">
                      Seleccione un metodo de pago *
                    </label>
                    <div>
                      <div
                        *ngIf="
                          f.paymentMethodId?.invalid &&
                          flagErrors &&
                          (f.paymentMethodId?.errors ||
                            f.paymentMethodId?.touched)
                        "
                      >
                        <div
                          class="ms-2 mt-1 text-danger"
                          *ngIf="f.paymentMethodId?.errors.required"
                        >
                          El metodo de pago es obligatorio.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-floating mb-3 col-md-{{ classPayments }}"
                    *ngIf="paymentsFilter.length > 0"
                  >
                    <select
                      name="paymentId"
                      id="paymentId"
                      class="form-control"
                      formControlName="paymentId"
                      placeholcer="Opcion de pago *"
                      required
                      [ngClass]="{
                        'is-invalid': submitted && f.paymentId.errors,
                        'is-valid': submitted && !f.paymentId.errors
                      }"
                    >
                      <option value="0" selected>
                        Seleccione la opci&oacute;n de pago
                      </option>
                      <option
                        value="{{ payment.id }}"
                        *ngFor="let payment of paymentsFilter"
                      >
                        {{ payment.name }}
                      </option>
                    </select>
                    <label for="paymentMethodId" class="ms-2"
                      >Opci&oacute;n de pago *</label
                    >
                  </div>
                  <div
                    class="form-floating mb-3 col-md-{{ classPayments }}"
                    *ngIf="paymentsFilter.length > 0"
                  >
                    <select
                      name="terminal"
                      id="terminal"
                      class="form-control"
                      formControlName="terminal"
                      placeholcer="Selecciona una terminal *"
                      required
                      [ngClass]="{
                        'is-invalid': submitted && f.terminal.errors,
                        'is-valid': submitted && !f.terminal.errors
                      }"
                    >
                      <option value="0" selected>
                        Seleccione una terminal
                      </option>
                      <option value="Banorte">Banorte</option>
                      <option value="BBVA">BBVA</option>
                      <option value="Firstdata">Firstdata</option>
                      <option value="Netpay">Netpay</option>
                      <option value="Getnet">Getnet</option>
                    </select>
                    <label for="paymentMethodId" class="ms-2">
                      Selecciona una terminal *</label
                    >
                  </div>
                </div>

                <div class="row" *ngIf="!flagDisabled">
                  <div class="col-md-6">
                    <input
                      type="hidden"
                      name="userId"
                      formControlName="userId"
                      id="userId"
                    />
                    <select2
                      [data]="employeesFilter"
                      style="width: 100%"
                      displaySearchStatus="always"
                      placeholder="Colaborador *"
                      formControlName="user_id"
                      listPosition="below"
                      styleMode="material"
                      noResultMessage="Colaborador no encontrado"
                      hideSelectedItems
                      (update)="updateEmployees($event)"
                    ></select2>
                    <div>
                      <div
                        *ngIf="
                          f.userId?.invalid &&
                          flagErrors &&
                          (f.userId?.errors || f.userId?.touched)
                        "
                      >
                        <div
                          class="ms-2 mt-1 mb-2 text-danger"
                          style="margin-top: -15px !important"
                          *ngIf="f.userId?.errors.required"
                        >
                          El encargado de sucursal es obligatorio.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-floating mb-3 mt-3">
                      <select
                        name="totalPayments"
                        id="totalPayments"
                        class="form-select"
                        aria-label="floating label select"
                        formControlName="totalPayments"
                        (change)="generateArray($event)"
                        [ngClass]="{
                          'is-invalid': submitted && f.totalPayments.errors,
                          'is-valid': submitted && !f.totalPayments.errors
                        }"
                      >
                        <option value="" selected>
                          Seleccion el numero de pagos
                        </option>
                        <option
                          value="{{ i }}"
                          *ngFor="let i of arrayTotalSessions"
                        >
                          {{ i }}
                        </option>
                      </select>
                      <label for="totalPayments" class="">
                        Numero de pagos *
                      </label>
                    </div>
                    <div>
                      <div
                        *ngIf="
                          f.totalPayments?.invalid &&
                          flagErrors &&
                          (f.totalPayments?.errors || f.totalPayments?.touched)
                        "
                      >
                        <div
                          class="ms-2 mt-1 mb-2 text-danger"
                          style="margin-top: -15px !important"
                          *ngIf="f.totalPayments?.errors.required"
                        >
                          La selecci&oacute;n del numero de pagos es
                          obligatorio.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <input
                      type="hidden"
                      name="edsId"
                      formControlName="edsId"
                      id="edsId"
                    />
                    <select2
                      [data]="edsFilter"
                      style="width: 100%"
                      displaySearchStatus="always"
                      placeholder="Encargado de sucursal *"
                      formControlName="edsTempId"
                      listPosition="below"
                      styleMode="material"
                      noResultMessage="Colaborador no encontrado"
                      hideSelectedItems
                      (update)="updateEmployeesEds($event)"
                    ></select2>
                  </div>
                </div>

                <!--div class="row">
                  <div class="form-floating mb-3 col-md-12">
                    <select
                      name="totalPayments"
                      id="totalPayments"
                      class="form-select"
                      aria-label="floating label select"
                      formControlName="totalPayments"
                      (change)="generateArray($event)"
                      [ngClass]="{
                        'is-invalid': submitted && f.totalPayments.errors,
                        'is-valid': submitted && !f.totalPayments.errors
                      }"
                    >
                      <option value="" selected>
                        Seleccion el numero de pagos
                      </option>
                      <option
                        value="{{ i }}"
                        *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]"
                      >
                        {{ i }}
                      </option>
                    </select>
                    <label for="totalPayments" class="ms-3">
                      Numero de pagos
                    </label>
                  </div>
                </div -->
                <div class="row" *ngIf="arrayNumber.length !== 0">
                  <input
                    type="hidden"
                    name="number_payments"
                    id="number_payments"
                    formControlName="number_payments"
                  />
                  <div class="col-md-8 mb-2">
                    <div style="width: 25rem">
                      <label for="every_pay" class="ms-3"> Pagos</label>
                      <ul
                        class="list-group list-group-flush"
                        *ngFor="let i of arrayNumber"
                      >
                        <li class="list-group-item">
                          <div class="col-md-12">
                            <div class="form-floating col">
                              <input
                                type="text"
                                name="pay-{{ i }}"
                                id="pay-{{ i }}"
                                class="form-control"
                                placeholder="Pago {{ i }} *"
                                (keypress)="numbersOnly($event)"
                                (blur)="setValuePays($event, i)"
                                (keyup)="calculatePayments($event, i)"
                              />
                              <label for="pay" class="ms-3">
                                Pago {{ i }} *
                              </label>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div
                        *ngIf="
                          f.number_payments?.invalid &&
                          f.number_payments?.errors &&
                          (f.number_payments?.dirty ||
                            f.number_payments?.touched)
                        "
                      >
                        <div
                          class="text-danger"
                          *ngIf="f.number_payments.errors.required"
                        >
                          Por favor, ingrese la cantidad de cada pago.
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <p class="text-end fs-2 fw-bold {{ totalLabelColor }}">
                        Total: {{ totalLabel | currency }}
                      </p>
                    </div>
                  </div>
                </div>
                <!---->
              </div>

              <div class="col-md-12">
                <div class="mb-3">
                  <div class="form-floating">
                    <textarea
                      class="form-control"
                      placeholder="Deja un comentario aqui"
                      id="floatingTextarea"
                      formControlName="observations"
                      style="height: 100px"
                    ></textarea>
                    <label for="floatingTextarea">Comentarios</label>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="mb-3">
                  <label for="formFile" class="form-label">
                    Selecciona una imagen *
                  </label>
                  <input
                    class="form-control"
                    type="file"
                    id="formFile"
                    #myFile
                    (change)="onFileChange($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end"
        *ngIf="!isAbleToPackage"
      >
        <button
          type="button"
          class="btn btn-danger"
          (click)="resetPackageForm()"
        >
          <i class="bi bi-eraser"></i>
          Limpiar Informaci&oacute;n
        </button>
        <button type="submit" class="btn btn-success" [disabled]="flagMinimal">
          <!-- disabledButton || -->
          <i class="bi bi-plus-circle"></i>
          Crear
        </button>
      </div>
    </form>

    <ngb-alert
      #selfClosingAlert
      type="danger"
      *ngIf="generalMessage != '' && isDisplayed"
    >
      {{ generalMessage }}
    </ngb-alert>
    <ngb-alert
      #selfClosingAlert
      type="success"
      *ngIf="generalMessage != '' && isSaved"
    >
      {{ generalMessage }}
    </ngb-alert>
  </div>
</div>
