import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-cabins',
  templateUrl: './edit-cabins.component.html',
  styleUrls: ['./edit-cabins.component.scss'],
})
export class EditCabinsComponent implements OnInit {
  cabinsForm: any = FormGroup;
  breadcrumbs: any = [];
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  paramId: string | null = '';
  offices: any = [];
  employees: any = [];
  employeesFilter: any = [];
  flagEnabled: boolean = false;
  cabinsDataValues: any = [];
  empId: any = 0;
  flagErrors: boolean = false;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramId = this.router.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de cabinas',
        path: '/cabins/list',
        link: true,
      },
      {
        name: 'Editar cabina',
        link: false,
      },
    ];

    this.getCabinsData();
    this.getOffices();
    this.getUsers();
    this.validations();
  }

  validations() {
    this.cabinsForm = this.fb.group({
      officeId: ['', [Validators.required]],
      userId: [''],
      cabinName: ['', [Validators.required]],
      isEnable: [''],
      employee: [''],
    });
  }

  getCabinsData() {
    this.api.getById('cabin', Number(this.paramId)).subscribe({
      next: (cabinData) => {
        this.cabinsDataValues = cabinData.cabin;
        this.empId = this.cabinsDataValues.userId;
        this.cabinsForm.patchValue({
          cabinName: this.cabinsDataValues.name,
          officeId: this.cabinsDataValues.officeId,
          userId: this.cabinsDataValues.userId,
          isEnable: this.cabinsDataValues.enabled,
          employee: this.cabinsDataValues.userId,
        });
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  getUsers() {
    this.api.get('employees/office').subscribe({
      next: (employees) => {
        this.employees = employees.employees;
        const employeesPackage = this.employees.map((employeeData: any) => {
          return {
            value: employeeData.id,
            label: `${employeeData.first_name} ${employeeData.last_name}`,
          };
        });
        this.employeesFilter = employeesPackage;
      },
      error: (err) => {},
    });
  }

  get f() {
    return this.cabinsForm.controls;
  }

  updateEmployee(evt: any): void {
    this.cabinsForm.patchValue({
      userId: evt.value,
    });
  }

  updateCabins() {
    this.submitted = true;
    this.flagErrors = false;

    if (this.cabinsForm.invalid) {
      console.log(this.cabinsForm);
      this.flagErrors = true;
      return;
    }

    const obj = {
      officeId: this.cabinsForm.value.officeId,
      userId: this.cabinsForm.value.userId,
      cabinName: this.cabinsForm.value.cabinName,
      isEnabled: this.cabinsForm.value.isEnable,
    };

    this.api.update('cabin', Number(this.paramId), obj).subscribe({
      next: (cabinData) => {
        if (cabinData.cabin.enabled == 1) {
          this.api.setTotalCabins(this.api.getTotalCabinsPerOffice + 1);
        } else {
          this.api.setTotalCabins(this.api.getTotalCabinsPerOffice - 1);
        }

        this.generalMessage = cabinData.message;
        this.submitted = false;
        this.isSaved = true;
        this.isDisplayed = false;
        this.flagErrors = false;

        setTimeout(() => {
          this.isSaved = false;
          this.isDisplayed = false;
          this.submitted = false;
          this.generalMessage = '';
          this.flagErrors = false;
        }, 4000);
      },
      error: (err) => {},
    });
  }
}
