import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { formatDate } from 'src/app/util/dates';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-create-packages',
  templateUrl: './create-packages.component.html',
  styleUrls: ['./create-packages.component.scss'],
})
export class CreatePackagesComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('myFile') myFile!: ElementRef;
  packageForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  responseMessage: string = '';
  breadcrumbs: any;
  clientsFilter: any = [];
  clients: any = [];
  packagesFilter: any = [];
  hidroFacialFilter: any = [];
  packagedCustomFilter: any = [];
  packages: any = [];
  hidrofacial: any = [];
  packagesGiftsFilter: any = [];
  packagesGifts: any = [];
  totalPrice: number = 0;
  minutesAreas: number = 0;
  minutesGifts: number = 0;
  arrayTotalSessions: number[] = [];
  arrayNumber: number[] = [];
  arrayPayments: any[] = [];
  arraySessions: any[] = [];
  arrayClients: any[] = [];
  totalLabel: number = 0;
  totalLabelColor: string = '';
  disabledButton: boolean = true;
  arraySumValidate: number[] = [];
  date_displayed: any = '';
  rolename: any = '';
  rolekey: any = '';
  flagDisabled: boolean = false;
  employeesFilter: any = [];
  edsFilter: any = [];
  employees: any = [];
  flagZero: boolean = false;
  classNumber: number = 12;
  sellsFor: string[] = [
    'Reventa',
    'Ingreso',
    'Redes Sociales',
    'Referido',
    'Referido Enfermera',
    'Valoracion',
  ];
  optionsSells: string[] = ['Facebook', 'Instagram'];
  socialFlag: boolean = false;
  referalFlag: boolean = false;
  resaleFlag: boolean = false;
  negritasFlag: boolean = false;
  gioFlag: boolean = false;
  valoration: boolean = false;
  date_purchased_format: any;
  hideCustomFlag: boolean = false;
  hideGeneralFlag: boolean = false;
  areasNames: any = [];
  totalFields: any = [];
  ocultar: boolean = false;
  payment_methods: any = [];
  paymentsFilter: any = [];
  payments: any = [];
  classPayments: number = 12;
  classAreas: number = 4;
  typeSellPackage: number = 1;
  flagGift: boolean = false;
  flagErrors: boolean = false;
  flagPercentage: boolean = false;
  flagMinimal: boolean = false; // flag for minimal amount to pay in advance
  status: boolean = false;
  imageURL: any = '';
  fullBody: any = [];
  totalSum: number = 0;
  areaId: number = 0;
  countryCode: string = '';
  officeID: number = 0;
  emailUserLoggedIn: string = '';
  minimalMessage: string = '';
  cashPaymentTotal: number = 0;
  flagSharedPackage: boolean = false;
  flagIsOldPackage: boolean = false;
  sharedFilter: any = [];
  filterClients: any = [];
  sharedClients: any = [];
  clientsPackagesAreas: any[] = [];
  temporalClientList: any[] = [];
  completeClientsList: any[] = [];
  flagPackagePayed: boolean = false;
  hidroFlag: boolean = false;
  extraPrices: any = [];
  totalExtraPrice: number = 0;
  isAbleToPackage: boolean = false;
  private _error = new Subject<string>();

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    this._error.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._error.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isDisplayed = false;
        this.isSaved = false;
        this.generalMessage = '';
        this.responseMessage = '';
      }
    });

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de paquetes',
        path: '/packages/list',
        link: true,
      },
      {
        name: 'Crear paquete',
        link: false,
      },
    ];

    this.validations();
    this.getClients();
    this.getAreas();
    this.roleInfo();
    this.getEmployees();
    this.getPaymentMethods();
    this.getPayments();
    this.packagesFullBody();
  }

  validations() {
    this.packageForm = this.fb.group({
      price: ['', [Validators.required]],
      hours: ['', [Validators.required]],
      minutes: ['', [Validators.required]],
      clientId: ['', Validators.required],
      clientShared: [''], // clients IDs will be used to share package
      userId: ['', Validators.required],
      session_number: ['', [Validators.required]],
      date_purchased: ['', [Validators.required]],
      percentage: ['', [Validators.required]],
      areas_sessions: ['', [Validators.required]],
      areas_gifts: [''],
      subtotal: ['', [Validators.required]],
      totalPayments: ['', [Validators.required]],
      number_payments: ['', [Validators.required]],
      areas_one: [''],
      areas_two: [''],
      client_one: [''],
      client_two: [''],
      office_id: [''],
      user_id: [''],
      advance_payments: ['', [Validators.required]],
      remaining_total: [''],
      is_empty: [''],
      is_shared: [''], // check if is shared the package
      realPrice: [''],
      sells: ['', [Validators.required]],
      social: ['', [Validators.required]],
      resale: ['', [Validators.required]],
      employeeId: ['', [Validators.required]],
      type: ['', [Validators.required]],
      client_referer: ['', [Validators.required]],
      employee_id: [''],
      areas_dynamic: [''],
      custom_sessions: [''],
      custom_clients: [''], // set the custom clients ID
      paymentMethodId: ['', [Validators.required]],
      paymentId: ['0', [Validators.required]],
      terminal: ['0'],
      size: [''],
      type_package: ['', [Validators.required]],
      subtype_package: ['', [Validators.required]],
      imageData: [''],
      observations: [''],
      isOld: [''],
      extraData: this.fb.array([]),
      //new fields
      edsId: [''],
      edsTempId: [''],
    });

    // disabled by default the suboptions
    this.packageForm.get('subtype_package').disable();
  }

  packagesFullBody() {
    this.api.get('areas/fullbody').subscribe({
      next: (areas) => {
        this.fullBody = areas.areas;
      },
      error: (err) => {},
    });
  }

  roleInfo() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
        this.countryCode = roles.user.office.country_code;
        this.officeID = roles.user.office.id;
        this.emailUserLoggedIn = roles.user.user_email;
      },
      error: (err) => {},
    });
  }

  getClients() {
    this.api.get('clients').subscribe({
      next: (clients) => {
        this.clients = clients.clients;
        const clientsFormat = this.clients.map((client: any) => {
          return {
            value: client.id,
            label: `${client.first_name} ${client.last_name}`,
          };
        });
        this.clientsFilter = [{ options: clientsFormat }];

        //Get dates
        const dateToday = new Date();
        this.date_displayed = `${formatDate(
          dateToday.getDate(),
          2
        )} - ${formatDate(dateToday.getMonth() + 1, 2)} - ${formatDate(
          dateToday.getFullYear(),
          4
        )}`;

        this.date_purchased_format = {
          year: formatDate(dateToday.getFullYear(), 4),
          month: formatDate(dateToday.getMonth(), 2),
          day: formatDate(dateToday.getDate(), 2),
        };
        this.packageForm.patchValue({
          date_purchased: this.date_purchased_format,
        });
      },
      error: (err) => {},
    });
  }

  getEmployees() {
    this.api.get('employees/office').subscribe({
      next: (employee) => {
        this.employees = employee.employees;
        const employeesFormat = this.employees.map((employeesName: any) => {
          return {
            value: employeesName.id,
            label: `${employeesName.first_name} ${employeesName.last_name}`,
          };
        });

        this.employees.map((edsNames: any) => {
          if (edsNames.roles.key === 'eds') {
            const data = {
              value: edsNames.id,
              label: `${edsNames.first_name} ${edsNames.last_name}`,
            };

            this.edsFilter.push(data);
          }
        });

        employeesFormat.push({ value: 0, label: 'Ninguno' });
        console.log(employeesFormat);

        this.employeesFilter = [{ options: employeesFormat }];
      },
      error: (err) => {},
    });
  }

  getAreas() {
    this.api.get('areas/catalog').subscribe({
      next: (packages) => {
        this.packages = packages.areas;
        const packagesFormat = this.packages.map((packageName: any) => {
          return {
            value: packageName.id,
            label: packageName.area,
            size: packageName.area_type,
          };
        });
        this.packagesFilter = [{ options: packagesFormat }];
      },
      error: (err) => {},
    });

    this.api.get('areas/catalog/hidro').subscribe({
      next: (hidrofacial) => {
        this.hidrofacial = hidrofacial.areas;
        const hidrofacialFormat = this.hidrofacial.map((hidro: any) => {
          return {
            value: hidro.id,
            label: hidro.area,
            size: hidro.area_type,
          };
        });
        this.hidroFacialFilter = [{ options: hidrofacialFormat }];
      },
      error: (err) => {},
    });

    this.api.get('areas/catalog/small').subscribe({
      next: (packageSmall) => {
        this.packagesGifts = packageSmall.areas;
        const packageGiftsFormat = this.packagesGifts.map(
          (packageGiftName: any) => {
            return {
              value: packageGiftName.id,
              label: packageGiftName.area,
            };
          }
        );
        this.packagesGiftsFilter = [{ options: packageGiftsFormat }];
      },
      error: (err) => {},
    });
  }

  getPaymentMethods() {
    this.api.get('payment_methods').subscribe({
      next: (paymentMethods) => {
        this.payment_methods = paymentMethods.payment_methods;
      },
      error: (err) => {},
    });
  }

  getPayments() {
    this.api.get('payments').subscribe({
      next: (payments) => {
        this.payments = payments.payments;
      },
      error: (err) => {},
    });
  }

  checkPaymentMethods(evt: any): void {
    this.arrayNumber = [];
    this.packageForm.get('totalPayments').setValue('');
    this.totalLabel = 0;

    const numberCash =
      this.countryCode == 'sin' || this.countryCode == 'jal' ? 6 : 5;

    const dataPayments = this.payment_methods.filter(
      (data: any) => data.id == evt.target.value
    );

    this.arrayTotalSessions = dataPayments[0].payment_name
      .toLowerCase()
      .includes('contado')
      ? Array.from({ length: numberCash }, (_, i) => i + 1)
      : Array.from({ length: 8 }, (_, i) => i + 1);
    console.log(this.arrayTotalSessions);

    this.paymentsFilter = this.payments
      .sort((data1: any, data2: any) => data1.order - data2.order)
      .filter(
        (data: any) => data.payment_method_id === Number(evt.target.value)
      );

    if (this.paymentsFilter.length > 0) {
      this.classPayments = 4;
    } else {
      this.classPayments = 12;
    }
  }

  private populateSharedClient(id: number): void {
    const filterArray = this.clients.filter((client: any) => client.id !== id);
    this.filterClients = filterArray;
    const clientsShared = filterArray.map((client: any) => {
      return {
        value: client.id,
        label: `${client.first_name} ${client.last_name}`,
      };
    });

    this.sharedFilter = [{ options: clientsShared }];
  }

  updateClient(evt: any) {
    this.completeClientsList = [];
    this.isAbleToPackage = false;

    if (evt.value !== undefined) {
      const clientData = this.clients.filter((c: any) => c.id === evt.value);
      console.log(clientData);
      clientData.forEach((element: any) => {
        const firstObject = {
          id: element.id,
          name: `${element.first_name} ${element.last_name}`,
        };

        this.completeClientsList.push(firstObject);
      });

      const flagActive = this.api.checkEmailsAdmins(this.emailUserLoggedIn);
      console.log('flag:', flagActive);
      if (
        clientData[0].hasPendingPayment.totalDebts > 2 &&
        clientData[0].hasPendingPayment.totalPackages > 2 &&
        flagActive
      ) {
        this.isAbleToPackage = true;
        this.generalMessage =
          'Actualmente cuenta con mas de dos paquetes activos con deuda. Por el momento no se puede vender un nuevo paquete al cliente.';
      }

      this.packageForm.patchValue({
        clientId: evt.value,
        office_id: clientData[0].office.id,
      });

      if (this.flagSharedPackage) {
        this.populateSharedClient(evt.value);
      }
    }
  }

  updatedSharedClient(evt: any): void {
    if (evt.value !== undefined) {
      this.packageForm.patchValue({
        clientShared: evt.value,
      });

      if (evt.value.length > 0) {
        const sharedClients = this.clients.filter(
          (data: any) => data.id === evt.value[evt.value.length - 1]
        );

        sharedClients.forEach((element: any) => {
          const secondObject = {
            id: element.id,
            name: `${element.first_name} ${element.last_name}`,
          };

          this.completeClientsList.push(secondObject);
        });
      }
    }
  }

  /**
   * Get data and update price from extra areas
   *
   * @param idx number
   * @param totalSessions number
   */
  updateExtraAreas(idx: number, totalSessions: string) {
    const dataObj = this.packageForm.value.extraData[idx];
    const areaData = this.getAreasData(Number(dataObj.areaId));
    const price = this.packageForm.value.price;
    this.getTimeAreas(dataObj.timeArea);

    const percData = Number(totalSessions) >= 8 ? 8 : Number(totalSessions);
    const perc = (percData / 8) * 100;
    const extraPrice = (perc * areaData.price) / 100;
    const finalPrice = Number(extraPrice) + Number(price);

    const formArr = <FormArray>this.totalExtraData();
    formArr.at(idx).patchValue({
      tempPrice: extraPrice,
    });

    this.packageForm.patchValue({
      price: finalPrice,
    });
  }

  updateAreasHidro(evt: any) {
    const sizeArr = evt.value.length - 1;
    this.minutesAreas = this.calculateTime(evt.value, sizeArr, 2);
    const price = this.getPricePerArea(evt.value, sizeArr, 2);
    const totalMinutes = this.minutesAreas + this.minutesGifts;
    const hoursCalc = Math.floor(totalMinutes / 60);
    const minutesCalc = totalMinutes % 60;

    if (this.totalExtraPrice == 0) {
      this.getExtraPriceData();
    }

    if (evt.value.length == 0) {
      this.totalExtraData().reset();
      this.totalExtraPrice = 0;
    }

    this.packageForm.patchValue({
      areas_sessions: evt.value,
      hours: hoursCalc,
      minutes: minutesCalc,
      price: price + this.totalExtraPrice,
      realPrice: price,
    });

    this.ocultar =
      this.packageForm.value.areas_sessions.length > 0 ? true : false;
    this.totalFields = this.packageForm.value.areas_sessions;
    this.areasNames = [];
    this.hidroFacialFilter.map((data: any) => {
      this.totalFields.find((idx: any) => {
        data.options.filter((dt: any) => {
          if (dt.value == idx) {
            const f = this.areasNames.some((el: any) => el.value == idx);
            if (!f) {
              this.areasNames.push(dt);
            }
          }
        });
      });
    });
  }

  updateAreas(evt: any) {
    const sizeArr = evt.value.length - 1;
    this.minutesAreas = this.calculateTime(evt.value, sizeArr);
    const price = this.getPricePerArea(evt.value, sizeArr);
    const totalMinutes = this.minutesAreas + this.minutesGifts;
    const hoursCalc = Math.floor(totalMinutes / 60);
    const minutesCalc = totalMinutes % 60;
    const areasGift = this.packageForm.value.size;

    if (areasGift !== 0) {
      this.flagGift = false;
    }

    if (this.totalExtraPrice == 0) {
      this.getExtraPriceData();
    }

    if (evt.value.length == 0) {
      this.totalExtraData().reset();
      this.totalExtraPrice = 0;
    }

    this.packageForm.patchValue({
      areas_sessions: evt.value,
      hours: hoursCalc,
      minutes: minutesCalc,
      price: price + this.totalExtraPrice,
      realPrice: price,
    });

    this.ocultar =
      this.packageForm.value.areas_sessions.length > 0 ? true : false;
    this.totalFields = this.packageForm.value.areas_sessions;
    this.areasNames = [];
    this.packagesFilter.map((data: any) => {
      this.totalFields.find((idx: any) => {
        data.options.filter((dt: any) => {
          if (dt.value == idx) {
            const f = this.areasNames.some((el: any) => el.value == idx);
            if (!f) {
              this.areasNames.push(dt);
            }
          }
        });
      });
    });
  }

  updateEmployees(evt: any) {
    /* if (this.flagZero) {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    } */

    this.packageForm.patchValue({
      userId: evt.value,
    });
  }

  updateEmployeesEds(evt: any) {
    this.packageForm.patchValue({
      edsId: evt.value,
    });
  }

  updateAreasGift(evt: any) {
    const sizeArr = evt.value.length - 1;
    this.minutesGifts = this.calculateTime(evt.value, sizeArr);
    const totalMinutes = this.minutesAreas + this.minutesGifts;
    const hoursCalc = Math.floor(totalMinutes / 60);
    const minutesCalc = totalMinutes % 60;

    this.packageForm.patchValue({
      areas_gifts: evt.value,
      hours: hoursCalc,
      minutes: minutesCalc,
    });
  }

  private calculateTime(arr: [], sizeArr: number, type: number = 1): number {
    let totalMinutes: number = 0;
    for (let i = 0; i <= sizeArr; i++) {
      const specificData =
        type == 1
          ? this.packages.filter((data: any) => data.id === arr[i])
          : this.hidrofacial.filter((data: any) => data.id === arr[i]);
      const splitData = specificData[0].duration.split(':');
      const hoursToMinutes = parseInt(splitData[0]) * 60;
      const minutesFor = parseInt(splitData[1]) + hoursToMinutes;
      totalMinutes += minutesFor;
    }

    return totalMinutes;
  }

  private getPricePerArea(arr: [], sizeArr: number, type: number = 1): number {
    let total: number = 0;

    for (let i = 0; i <= sizeArr; i++) {
      const price =
        type == 1
          ? this.packages.filter((p: any) => p.id === arr[i])
          : this.hidrofacial.filter((p: any) => p.id === arr[i]);
      total += Number(price[0].price);
    }

    return total;
  }

  // private method to get package total final
  private getTotalFinal() {
    this.totalSum = 0;

    this.arraySessions.filter((data: any, index: any) => {
      if (this.packageForm.value.type_package != 4) {
        this.packages.filter((innerData: any) => {
          if (innerData.id === index) {
            const percData = data >= 8 ? 8 : data;
            const perc = (percData / 8) * 100;
            const final = (perc * innerData.price) / 100;

            this.totalSum += final;
          }
        });
      } else {
        this.hidrofacial.filter((innerData: any) => {
          if (innerData.id === index) {
            const percData = data >= 8 ? 8 : data;
            const perc = (percData / 8) * 100;
            const final = (perc * (innerData.price * 2)) / 100;

            this.totalSum += final;
          }
        });
      }
    });

    return this.totalSum;
  }

  updatePrice(maxNumberSession: number) {
    console.log('numeros: ', maxNumberSession);
    if (this.packageForm.value.session_number != '') {
      if (
        this.packageForm.value.type_package == 1 ||
        this.packageForm.value.type_package == 2 ||
        this.packageForm.value.type_package == 3 ||
        this.packageForm.value.type_package == 4
      ) {
        // suma de valores correctos
        let sumaTotal = 0;

        if (this.packageForm.value.type_package != 4) {
          this.packages.filter((data: any) => {
            if (this.arraySessions.hasOwnProperty(data.id)) {
              const sessionsNumberData =
                this.arraySessions[data.id] > 8
                  ? 8
                  : this.arraySessions[data.id];
              const percentagePerArea = (Number(sessionsNumberData) / 8) * 100;
              const pricePerArea =
                (percentagePerArea * Number(data.price)) / 100;
              sumaTotal += pricePerArea; //Number(data.price); //pricePerArea;
            }
          });
        } else {
          this.hidrofacial.filter((data: any) => {
            if (this.arraySessions.hasOwnProperty(data.id)) {
              const sessionsNumberData =
                this.arraySessions[data.id] > 8
                  ? 8
                  : this.arraySessions[data.id];
              const percentagePerArea = (Number(sessionsNumberData) / 8) * 100;
              const pricePerArea =
                (percentagePerArea * Number(data.price)) / 100;
              sumaTotal += pricePerArea; //Number(data.price); //pricePerArea;
            }
          });
        }

        console.log('price total: ', sumaTotal);
        this.packageForm.patchValue({
          price: Number(sumaTotal) + Number(this.totalExtraPrice),
        });
        console.log('suma1: ', sumaTotal, this.totalExtraPrice);

        let totalPriceFinal = this.getTotalFinal();
        let total = this.packageForm.value.realPrice;

        // =====
        const areasGift = this.packageForm.value.size;
        if (areasGift != 0) {
          const filterData = this.packages.filter(
            (data: any) => data.id === this.areaId
          );

          this.arraySessions.filter((data: any, index: any) => {
            if (filterData[0].id === index) {
              const perc = (data / 8) * 100;
              const final = (perc * filterData[0].price) / 100;

              this.totalSum -= final;
            }
          });

          console.log('suma2: ', this.totalSum, this.totalExtraPrice);
          totalPriceFinal = this.totalSum;
        }

        console.log(
          'suma3: ',
          Number(totalPriceFinal) + Number(this.totalExtraPrice)
        );
        this.packageForm.patchValue({
          price: Number(totalPriceFinal) + Number(this.totalExtraPrice),
        });
      }

      this.clearData();
    }
  }

  private getCalcsSin(total: number, percentage: number, area: any) {
    if (this.fullBody.length == total) {
      let sum = 0;
      this.packageForm.value.areas_sessions.map((area: any) => {
        return this.fullBody.map((areaFB: any) => {
          if (area === areaFB.id) {
            sum++;
          }
        });
      });

      if (sum == total && this.fullBody.length == sum) {
        if (
          this.packageForm.value.subtotal >= 60000 &&
          this.packageForm.value.advance_payments >= 6000
        ) {
          return 4;
        }
      }
    }

    let dataArea: any;
    if (total == 1) {
      const idArea = this.packageForm.value.areas_sessions[0];
      dataArea = this.packages.filter((data: any) => data.id == idArea);
    }

    let intReturned: any = 0;
    if (this.packageForm.value.type_package == 1) {
      if (total == 1 && dataArea[0].area_type == 2) {
        return 2;
      }

      if (total == 1 && percentage <= 55) {
        // 40
        intReturned = 1;
      } else if (total == 2 && percentage <= 60) {
        // 50
        intReturned = 2;
      }
      if (
        (total >= 3 && percentage <= 65) ||
        (total >= 3 && area != 0 && percentage <= 65)
      ) {
        // 60 - 55
        intReturned = 3;
      }
    } else if (this.packageForm.value.type_package == 2) {
      if (total == 1 && percentage <= 55) {
        // 50
        intReturned = 1;
      } else if (
        (total == 2 && percentage <= 60) ||
        (total >= 3 && area != 0 && percentage <= 60) // 55
      ) {
        intReturned = 2;
      }
      if (total >= 3 && percentage <= 65 && area == 0) {
        // 60
        intReturned = 3;
      }
    }

    return intReturned;
  }

  private getCalcsTj(total: number, percentage: number, area: any) {
    return 3;
    /* if (this.fullBody.length == total) {
      let sum = 0;
      this.packageForm.value.areas_sessions.map((area: any) => {
        return this.fullBody.map((areaFB: any) => {
          if (area === areaFB.id) {
            sum++;
          }
        });
      });

      if (sum == total && this.fullBody.length == sum) {
        if (
          this.packageForm.value.subtotal >= 60000 &&
          this.packageForm.value.advance_payments >= 6000
        ) {
          return 4;
        }
      }
    }

    let intReturned: any = 0;
    if (this.packageForm.value.type_package == 1) {
      if (total == 1 && percentage <= 40) {
        intReturned = 1;
      } else if (total == 2 && percentage <= 50) {
        intReturned = 2;
      }
      if (
        (total >= 3 && percentage <= 55) ||
        (total >= 3 && area != 0 && percentage <= 55)
      ) {
        intReturned = 3;
      }
    } else if (this.packageForm.value.type_package == 2) {
      if (total == 1 && percentage <= 50) {
        intReturned = 1;
      } else if (
        (total == 2 && percentage <= 55) ||
        (total >= 3 && area != 0 && percentage <= 55)
      ) {
        intReturned = 2;
      }
      if (total >= 3 && percentage <= 60) {
        intReturned = 3;
      }
    } else if (this.packageForm.value.type_package == 3) {
      if (
        (total == 1 && percentage <= 55) ||
        (total >= 3 && area != 0 && percentage <= 55)
      ) {
        intReturned = 1;
      } else if (total == 2 && percentage <= 60) {
        intReturned = 2;
      }
      if (total >= 3 && percentage <= 65) {
        intReturned = 3;
      }
    }

    return intReturned; */
  }

  private getCalcsGdl(total: number, percentage: number, area: any) {
    if (this.fullBody.length >= total && total >= 16) {
      let sum = 0;
      this.packageForm.value.areas_sessions.map((area: any) => {
        return this.fullBody.map((areaFB: any) => {
          if (area === areaFB.id) {
            sum++;
          }
        });
      });

      if (sum == total && this.fullBody.length >= sum) {
        if (
          this.packageForm.value.subtotal >= 50000 &&
          this.packageForm.value.advance_payments >= 5000
        ) {
          return 4;
        }
      }
    }

    let intReturned: any = 0;
    if (total == 1) {
      // && percentage <= 55 && area == 0) {
      // 50
      intReturned = 1;
    } else if (
      total == 2 || // && percentage <= 60 && area == 0) ||
      (total >= 3 && area != 0) // && percentage <= 60) // 55
    ) {
      intReturned = 2;
    } else if (total >= 3) {
      // && percentage <= 65 && area == 0) {
      // 60
      intReturned = 3;
    }

    return intReturned;
  }

  private maxPercentage(percentage: number) {
    const total = this.packageForm.value.areas_sessions.length;
    const area = this.packageForm.value.size; // area de regalo
    let valor;

    switch (this.countryCode) {
      case 'sin':
        valor = this.getCalcsSin(total, percentage, area);
        break;
      case 'jal':
        valor = this.getCalcsGdl(total, percentage, area);
        break;
      case 'bc':
        valor = this.getCalcsTj(total, percentage, area);
        break;
    }

    return valor;

    /*if (this.countryCode == 'sin' || this.countryCode == 'bc') {
      if (this.fullBody.length == total) {
        let sum = 0;
        const isRealList = this.packageForm.value.areas_sessions.map(
          (area: any) => {
            return this.fullBody.map((areaFB: any) => {
              if (area === areaFB.id) {
                sum++;
              }
            });
          }
        );

        if (sum == total && this.fullBody.length == sum) {
          if (
            this.packageForm.value.subtotal >= 60000 &&
            this.packageForm.value.advance_payments >= 6000
          ) {
            return 4;
          }
        }
      }

      if (total == 1 && percentage <= 40) {
        return 1;
      } else if (total == 2 && percentage <= 50) {
        return 2;
      }
      if (total >= 3 && percentage <= 55) {
        return 3;
      } else {
        return 0;
      }
    } else {
      if (this.fullBody.length == total) {
        let sum = 0;
        const isRealList = this.packageForm.value.areas_sessions.map(
          (area: any) => {
            return this.fullBody.map((areaFB: any) => {
              if (area === areaFB.id) {
                sum++;
              }
            });
          }
        );

        if (sum == total && this.fullBody.length == sum) {
          if (
            this.packageForm.value.subtotal >= 50000 &&
            this.packageForm.value.advance_payments >= 5000
          ) {
            return 4;
          }
        }
      }

      if (total == 1 && percentage <= 50 && area == 0) {
        return 1;
      } else if (
        (total == 2 && percentage <= 55 && area == 0) ||
        (total >= 3 && area != 0 && percentage <= 55)
      ) {
        return 2;
      } else if (total >= 3 && percentage <= 60 && area == 0) {
        return 3;
      } else {
        return 0;
      }
    }*/
  }

  updatePriceWithGiftArea(evt: any, type: number, area_id: number): void {
    this.areaId = area_id;
    if (type === 1) {
      if (!this.flagGift) {
        const filterData = this.packages.filter(
          (data: any) => data.id === area_id
        );

        this.arraySessions.filter((data: any, index: any) => {
          if (filterData[0].id === index) {
            const perc = (data / 8) * 100;
            const final = (perc * filterData[0].price) / 100;

            this.totalSum -= final;
          }
        });

        this.flagGift = true;
        this.packageForm.patchValue({
          price: this.totalSum + this.totalExtraPrice,
          size: area_id,
        });
      }
    } else {
      if (this.flagGift) {
        const totalSum = this.getTotalFinal();

        this.flagGift = false;
        this.packageForm.patchValue({
          price: totalSum + this.totalExtraPrice,
          size: 0,
        });
      }
    }
  }

  numbersOnly(evt: any): boolean {
    const charCode = evt.which ? evt.which : evt.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  private getFinalPrice() {
    const total = this.packageForm.value.price;
    const discount = total - this.packageForm.value.subtotal;
    const percentage = (discount / total) * 100;

    if (this.packageForm.value.subtotal.length == 0) {
      this.packageForm.patchValue({
        advance_payments: '',
        remaining_total: '',
      });
    }

    if (
      this.packageForm.value.subtotal != '' &&
      this.packageForm.value.remaining_total != ''
    ) {
      const remaining =
        this.packageForm.value.subtotal -
        this.packageForm.value.advance_payments;
      this.disabledButton = true;
      this.arrayNumber = [];
      this.packageForm.get('totalPayments').reset();
      this.totalLabel = 0;

      this.packageForm.patchValue({
        remaining_total: remaining,
        percentage: percentage.toFixed(2),
      });
    } else {
      this.packageForm.patchValue({
        percentage: percentage.toFixed(2),
      });
    }
    /* let priceTotalCalc: number =
      this.packageForm.value.subtotal !== 0
        ? this.packageForm.value.subtotal
        : 0;

    const subtotalPrice = this.packageForm.value.subtotal;
    const totalSessions =
      this.packageForm.value.session_number !== ''
        ? Number(this.packageForm.value.session_number)
        : 0;
    const applyPercentage =
      this.packageForm.value.percentage !== 0
        ? Number(this.packageForm.value.percentage)
        : 0;

    if (totalSessions !== 0) {
      const percentageValue = (this.packageForm.value.session_number * 100) / 8;
      priceTotalCalc = (subtotalPrice * percentageValue) / 100;
    }

    if (applyPercentage !== 0) {
      const finalPercentage = (100 - applyPercentage) / 100;
      priceTotalCalc = priceTotalCalc * finalPercentage;
    }

    this.packageForm.patchValue({
      price: priceTotalCalc,
    }); */
  }

  calculatePrice(evt: any) {
    this.getFinalPrice();
  }

  /**
   * start new code
   */
  private getAreasData(id: number) {
    const areaName = this.packages.find((areaData: any) => areaData.id === id);

    return areaName;
  }

  private getTimeAreas(duration: string) {
    const split = duration.split(':');
    const currentHours = Number(split[0]) * 60;
    const currentMinutes = Number(split[1]);
    const hoursMinutes = this.packageForm.value.hours * 60;
    const minutesExists = this.packageForm.value.minutes;
    const currentTotal =
      hoursMinutes + minutesExists + currentHours + currentMinutes;

    const hoursCalc = Math.floor(currentTotal / 60);
    const minutesCalc = currentTotal % 60;

    this.packageForm.patchValue({
      hours: hoursCalc,
      minutes: minutesCalc,
    });
  }

  newExtraData(areaId: number): FormGroup {
    const areaName = this.getAreasData(areaId);

    return this.fb.group({
      clientId: '',
      areaName: areaName.area,
      areaId: areaId,
      numberSession: '',
      timeArea: areaName.duration,
      tempPrice: '',
    });
  }

  totalExtraData(): FormArray {
    return this.packageForm.get('extraData') as FormArray;
  }

  addExtraData(areaId: number): void {
    this.totalExtraData().push(this.newExtraData(areaId));
  }

  removeExtraData(i: number): void {
    this.substractPrice(i);
    this.removeTimeAreaDeleted(i);

    this.totalExtraData().removeAt(i);
  }

  private substractPrice(i: number) {
    const tempPrice = this.totalExtraData().at(i).value.tempPrice;
    this.packageForm.patchValue({
      price: this.packageForm.value.price - tempPrice,
    });
  }

  private removeTimeAreaDeleted(i: number) {
    const split = this.totalExtraData().at(i).value.timeArea.split(':');
    const currentHours = Number(split[0]) * 60;
    const currentMinutes = Number(split[1]);
    const hoursMinutes = this.packageForm.value.hours * 60;
    const minutesExists = this.packageForm.value.minutes;
    const finalTime =
      hoursMinutes + minutesExists - (currentHours + currentMinutes);

    const hoursCalc = Math.floor(finalTime / 60);
    const minutesCalc = finalTime % 60;

    this.packageForm.patchValue({
      hours: hoursCalc,
      minutes: minutesCalc,
    });
  }

  private getExtraPriceData() {
    if (this.flagSharedPackage) {
      if (this.totalExtraData().length > 0) {
        for (let i = 0; i < this.totalExtraData().length; i++) {
          console.log('valor: ', this.totalExtraData().at(i));
          this.totalExtraPrice += this.totalExtraData().at(i).value.tempPrice;
        }
      }
    }
  }

  /**
   * end new code
   */

  get f() {
    return this.packageForm.controls;
  }

  generateArray(evt: any) {
    const numbersPayments = Number(this.packageForm.value.totalPayments);
    this.arrayNumber = [];

    for (let i = 1; i <= numbersPayments; i++) {
      this.arrayNumber.push(i);
    }
  }

  calculatePayments(evt: any, position: number) {
    this.arraySumValidate[position - 1] = evt.target.value;
    this.totalLabel = this.arraySumValidate.reduce(
      (acc, cur) => Number(acc) + Number(cur),
      0
    );

    if (
      Number(this.totalLabel) === Number(this.packageForm.value.remaining_total)
    ) {
      const returnValue = this.maxPercentage(
        Number(this.packageForm.value.percentage)
      );

      if (this.packageForm.value.type_package == 3) {
        this.totalLabelColor = 'text-success';
        //this.disabledButton = false;
      } else if (returnValue > 0) {
        this.totalLabelColor = 'text-success';
        //this.disabledButton = false;
      } else {
        this.totalLabelColor = 'text-danger';
        //this.disabledButton = true;
      }
    } else {
      this.totalLabelColor = 'text-danger';
      this.disabledButton = true;
    }
  }

  calculateRemaining(evt: any) {
    const total = this.packageForm.value.subtotal;
    const remaining = total - evt.target.value;

    if (remaining === 0) {
      this.flagZero = true;
      this.packageForm.controls['totalPayments'].disable();
    } else {
      this.flagZero = false;
      this.packageForm.controls['totalPayments'].enable();
    }

    // checks minimal amount to pay for purchasing package
    this.minimalAmount();

    this.packageForm.patchValue({
      remaining_total: remaining,
    });
  }

  minimalAmount(): void {
    const minimalAmount = this.countryCode == 'sin' ? 1000 : 2000;
    const subtotal = this.packageForm.value.subtotal;
    const advance = this.packageForm.value.advance_payments;
    const minimalAmountPerc = (subtotal * 10) / 100;
    this.flagMinimal = false;
    this.minimalMessage = '';
    const newTypePackage =
      this.packageForm.value.type_package == 1
        ? this.packageForm.value.subtype_package
        : this.packageForm.value.type_package;

    if (this.countryCode == 'bc') {
      return;
    }

    if (this.countryCode == 'jal') {
      if (newTypePackage == 1) {
        /* if (subtotal <= 20000 && advance < minimalAmount) {
          this.flagMinimal = true;
          this.minimalMessage =
            'El anticipo debe ser al menos de $2,000 pesos.';
          return;
        }

        if (subtotal > 20000 && advance < minimalAmountPerc) {
          this.flagMinimal = true;
          this.minimalMessage =
            'El anticipo debe ser minimo del 10% del valor del paquete.';
          return;
        } */

        return;
      } else if (newTypePackage == 2) {
        if (subtotal <= 10000 && advance < 1000) {
          this.flagMinimal = true;
          this.minimalMessage =
            'El Anticipo debe ser al menos de $1,000 pesos.';
          return;
        }

        if (subtotal > 10000 && advance < minimalAmountPerc) {
          this.flagMinimal = true;
          this.minimalMessage =
            'El anticipo debe ser minimo del 10% del valor de la reventa.';
          return;
        }

        return;
      } else {
        if (advance < minimalAmountPerc) {
          this.flagMinimal = true;
          this.minimalMessage =
            'El anticipo debe ser minimo del 10% del valor del retoque.';
          return;
        }

        return;
      }
    } else {
      if (newTypePackage == 1 || newTypePackage == 2) {
        if (subtotal <= 20000 && advance < minimalAmount) {
          this.flagMinimal = true;
          this.minimalMessage =
            'El Anticipo debe ser al menos de $1,000 pesos.';
          return;
        }

        if (subtotal > 20000 && advance < minimalAmountPerc) {
          this.flagMinimal = true;
          this.minimalMessage =
            'El anticipo debe ser minimo del 10% del valor de la reventa.';
          return;
        }

        return;
      } else {
        if (advance < minimalAmountPerc) {
          this.flagMinimal = true;
          this.minimalMessage =
            'El anticipo debe ser minimo del 10% del valor del retoque.';
          return;
        }

        return;
      }
    }
  }

  setValuePays(evt: any, position: number): void {
    this.arrayPayments[position - 1] = evt.target.value;

    this.packageForm.patchValue({
      number_payments: this.arrayPayments,
    });
  }

  setValueCustomAreas(evt: any, index: number): void {
    this.arraySessions[index] = Number(evt.target.value);
    console.log('sesiones: ', this.arraySessions);
    this.getExtraPriceData();

    let newArray = [];
    for (const i of this.arraySessions) {
      if (typeof i !== 'undefined') newArray.push(i);
    }
    const maxNumberSess = Math.max(...newArray);

    this.packageForm.patchValue({
      custom_sessions: this.arraySessions,
      session_number: maxNumberSess,
    });

    this.updatePrice(maxNumberSess);
  }

  setValueCustomClients(evt: any, index: number): void {
    this.arrayClients[index] = Number(evt.target.value);

    console.log('clientes: ', this.arrayClients);

    this.packageForm.patchValue({
      custom_clients: this.arrayClients,
    });
  }

  disabledFields(evt: any): void {
    if (evt.target.checked) {
      this.flagDisabled = true;
      this.disabledButton = false;
    } else {
      this.flagDisabled = false;
      this.disabledButton = true;
    }
  }

  updateOptions(evt: any, type: string, flag: boolean = false) {
    switch (type) {
      case 'Social':
        this.packageForm.patchValue({
          type: type,
          social: evt.target.value,
          client_referer: 'N/A',
          resale: 'N/A',
          employeeId: 'N/A',
        });
        break;
      case 'Referido':
        if (!flag) {
          this.packageForm.patchValue({
            type: type,
            social: 'N/A',
            client_referer: evt.value,
            resale: 'N/A',
          });
        } else {
          this.packageForm.patchValue({
            type: type,
            social: 'N/A',
            employeeId: evt.value,
            resale: 'N/A',
          });
        }
        break;
      case 'Reventa':
      case 'Valoracion':
        if (!flag) {
          this.packageForm.patchValue({
            type: type,
            social: 'N/A',
            client_referer: 'N/A',
            resale: 'N/A',
            employeeId: evt.value,
          });
        } else {
          this.packageForm.patchValue({
            type: type,
            social: 'N/A',
            resale: 'N/A',
            employeeId: evt.value,
          });
        }
        break;
      case 'Ingreso':
        this.packageForm.patchValue({
          type: type,
          social: 'N/A',
          client_referer: 'N/A',
          employeeId: 'N/A',
          resale: evt.target.value,
        });
        break;
    }
  }

  // Sell type package
  changeTypeSell(evt: any, flag: boolean): void {
    this.packageForm.patchValue({
      areas_sessions: '',
      hours: '',
      minutes: '',
      price: '',
      realPrice: '',
    });
    this.areasNames = [];
    this.arraySessions = [];

    this.typeSellPackage = evt.target.value;
    this.hidroFlag = false;

    if (Number(evt.target.value) == 4 || Number(evt.target.value) == 5) {
      this.hidroFlag = true;
    }

    // Call this function to check if should
    // be disabled the second dropdown
    const type = Number(evt.target.value);
    this.disabledSecondDropdown(type, flag);
  }

  changeSubTypeSell(evt: any, flag: boolean): void {
    this.packageForm.patchValue({
      subtype_package: evt.target.value,
    });

    this.typeSellPackage = evt.target.value;
    this.thirdDropdownValues(flag);
  }

  /**
   * Method for disabling the second dropdown
   * or enabled
   *
   * @return void
   */
  private disabledSecondDropdown(type: number, flag: boolean) {
    if (type == 1 || type == 2 || type == 3) {
      this.packageForm.get('subtype_package').enable();
    } else {
      this.packageForm.get('subtype_package').disable();
      this.packageForm.patchValue({
        subtype_package: 0,
      });
    }

    this.packageForm.get('sells').setValue('');
    this.thirdDropdownValues(flag);
  }

  /**
   * Reset parameters of the third dropdown
   *
   * @param void
   */
  private thirdDropdownValues(flag: boolean) {
    if (flag) {
      this.packageForm.get('subtype_package').setValue('');
    }

    this.socialFlag = false;
    this.referalFlag = false;
    this.resaleFlag = false;
    this.gioFlag = false;
    this.valoration = false;
    this.negritasFlag = false;
    this.classNumber = 12;
  }

  changeFieldsDynamically(evt: any): void {
    const value = evt.target.value;

    this.packageForm.patchValue({
      social: '',
      client_referer: '',
      resale: '',
      employeeId: '',
    });

    switch (value) {
      case 'Redes Sociales':
        this.socialFlag = true;
        this.referalFlag = false;
        this.resaleFlag = false;
        this.gioFlag = false;
        this.valoration = false;
        this.negritasFlag = false;
        this.classNumber = 6;
        break;
      case 'Referido':
        this.referalFlag = true;
        this.socialFlag = false;
        this.resaleFlag = false;
        this.gioFlag = false;
        this.valoration = false;
        this.negritasFlag = false;
        this.classNumber = 4;
        break;
      case 'Reventa nuevas zonas':
        this.socialFlag = false;
        this.referalFlag = false;
        this.resaleFlag = true;
        this.gioFlag = false;
        this.valoration = false;
        this.negritasFlag = false;
        this.classNumber = 6;
        /*this.packageForm.patchValue({
          subtype_package: 2,
        });*/
        break;
      case 'Reventa retoques':
        this.socialFlag = false;
        this.referalFlag = false;
        this.resaleFlag = true;
        this.gioFlag = false;
        this.valoration = false;
        this.negritasFlag = false;
        this.classNumber = 6;
        /*this.packageForm.patchValue({
          subtype_package: 3,
        });*/
        break;
      case 'Ingreso':
        this.socialFlag = false;
        this.gioFlag = true;
        this.resaleFlag = false;
        this.referalFlag = false;
        this.valoration = false;
        this.negritasFlag = false;
        this.classNumber = 6;
        break;
      case 'Enfermera':
      case 'Valoracion':
        this.valoration = true;
        this.socialFlag = false;
        this.gioFlag = false;
        this.resaleFlag = false;
        this.referalFlag = false;
        this.negritasFlag = false;
        this.classNumber = 6;
        break;
      case 'Negritas':
        this.valoration = false;
        this.socialFlag = false;
        this.gioFlag = false;
        this.resaleFlag = false;
        this.referalFlag = false;
        this.negritasFlag = true;
        this.classNumber = 6;
        break;
      default:
        this.valoration = false;
        this.socialFlag = false;
        this.gioFlag = false;
        this.resaleFlag = false;
        this.referalFlag = false;
        this.negritasFlag = false;
        this.classNumber = 12;
        break;
    }
  }

  sharedPackage(evt: any): void {
    this.flagSharedPackage = !this.flagSharedPackage;

    this.packageForm.patchValue({
      is_shared: this.flagSharedPackage,
    });

    this.classAreas = this.flagSharedPackage ? 2 : 3;

    if (this.flagSharedPackage) {
      this.populateSharedClient(this.packageForm.value.clientId);
    }
  }

  sharedIsOld(evt: any): void {
    this.flagIsOldPackage = !this.flagIsOldPackage;

    this.packageForm.patchValue({
      isOld: this.flagIsOldPackage,
    });
  }

  async onFileChange(evt: any) {
    this.disabledButton = true;
    this.status = false;
    const file = evt.target.files[0];
    this.status = evt.target.files.length > 0 ? true : false;

    if (this.status) {
      if (this.flagZero) {
        this.disabledButton = false;
      } else {
        this.disabledButton = true;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.packageForm.patchValue({
          imageData: reader.result,
        });
        this.disabledButton = false;
      };
    }
  }

  validateFieldsRequired(type: number, obj: any) {
    let flagValidation = false;

    if (type == 1) {
      for (let single of obj) {
        if (
          this.packageForm.value[single].length === 0 ||
          this.packageForm.value[single] === null
        ) {
          flagValidation = true;
          break;
        }
      }

      return flagValidation;
    }

    return flagValidation;
  }

  createPackage() {
    this.submitted = true;
    this.flagErrors = false;
    let obj, objValidation;
    let typeCheck = 0;

    console.log(this.packageForm.value['areas_sessions']);

    if (this.packageForm.value.is_empty) {
      typeCheck = 1;
      objValidation = [
        'areas_sessions',
        'hours',
        'minutes',
        'clientId',
        'session_number',
        'office_id',
        'date_purchased',
        'is_empty',
        'type_package',
      ];

      console.log('userId: ', this.packageForm.value.userId);
      obj = {
        areas_sessions: this.packageForm.value.areas_sessions,
        gift_areas: this.packageForm.value.areas_gifts,
        price: 0,
        subtotal: 0,
        duration: `${this.packageForm.value.hours}:${this.packageForm.value.minutes}`,
        clientId: this.packageForm.value.clientId,
        userId: this.packageForm.value.userId,
        sessions: this.packageForm.value.session_number,
        percentage: 100,
        office_id: this.packageForm.value.office_id,
        date_purchased: `${this.packageForm.value.date_purchased.year}-${this.packageForm.value.date_purchased.month}-${this.packageForm.value.date_purchased.day}`,
        totalPayments: 0,
        number_payments: 0,
        advancePayment: 0,
        remainingTotal: 0,
        sells: '',
        social: '',
        resale: '',
        type: '',
        client_referer: '',
        employeeId: '',
        is_gift: this.packageForm.value.is_empty,
        custom_sessions: this.packageForm.value.custom_sessions,
        paymentMethodId: 0,
        paymentId: 0,
        terminal: '',
        imageData: this.packageForm.value.imageData,
        type_package: Number(this.packageForm.value.type_package),
        subtype_package: Number(this.packageForm.value.subtype_package),
        observations: this.packageForm.value.observations,
        custom_clients: '',
        is_shared: false,
        clients_shared: [],
        isOld: this.packageForm.value.isOld,
        extraAreas: '',
        edsId: this.packageForm.value.edsId,
      };
    } else {
      if (this.packageForm.value.remaining_total === 0) {
        // check other fields here
        this.extraChecks();

        if (this.flagPercentage) {
          return;
        }

        obj = {
          areas_sessions: this.packageForm.value.areas_sessions,
          gift_areas: this.packageForm.value.areas_gifts,
          price: this.packageForm.value.price,
          subtotal: this.packageForm.value.subtotal,
          duration: `${this.packageForm.value.hours}:${this.packageForm.value.minutes}`,
          clientId: this.packageForm.value.clientId,
          userId: this.packageForm.value.userId,
          sessions: this.packageForm.value.session_number,
          percentage: this.packageForm.value.percentage,
          office_id: this.packageForm.value.office_id,
          date_purchased: `${this.packageForm.value.date_purchased.year}-${this.packageForm.value.date_purchased.month}-${this.packageForm.value.date_purchased.day}`,
          totalPayments: 0,
          number_payments: '',
          advancePayment: this.packageForm.value.advance_payments,
          remainingTotal: this.packageForm.value.remaining_total,
          is_gift: this.packageForm.value.is_empty,
          sells: this.packageForm.value.sells,
          social: this.packageForm.value.social,
          resale: this.packageForm.value.resale,
          type: this.packageForm.value.type,
          employeeId: this.packageForm.value.employee_id,
          client_referer: this.packageForm.value.client_referer,
          custom_sessions: this.packageForm.value.custom_sessions,
          paymentMethodId: Number(this.packageForm.value.paymentMethodId),
          paymentId: Number(this.packageForm.value.paymentId),
          type_package: Number(this.packageForm.value.type_package),
          imageData: this.packageForm.value.imageData,
          observations: this.packageForm.value.observations,
          custom_clients: this.packageForm.value.custom_clients,
          is_shared: this.packageForm.value.is_shared,
          clients_shared: this.packageForm.value.clientShared,
          terminal:
            this.packageForm.value.terminal.length === 0
              ? '0'
              : this.packageForm.value.terminal,
          isOld: this.packageForm.value.isOld,
          extraAreas: this.packageForm.value.extraData,
          edsId: this.packageForm.value.edsId,
          subtype_package: this.packageForm.value.subtype_package,
        };
      } else {
        console.log('paquete:', this.packageForm);
        if (this.packageForm.invalid) {
          this.flagErrors = true;
          this.extraChecks();

          return;
        }

        // check other fields here
        this.extraChecks();

        if (this.flagPercentage) {
          return;
        }

        obj = {
          areas_sessions: this.packageForm.value.areas_sessions,
          gift_areas: this.packageForm.value.areas_gifts,
          price: this.packageForm.value.price,
          subtotal: this.packageForm.value.subtotal,
          duration: `${this.packageForm.value.hours}:${this.packageForm.value.minutes}`,
          clientId: this.packageForm.value.clientId,
          userId: this.packageForm.value.userId,
          sessions: this.packageForm.value.session_number,
          percentage: this.packageForm.value.percentage,
          office_id: this.packageForm.value.office_id,
          date_purchased: `${this.packageForm.value.date_purchased.year}-${this.packageForm.value.date_purchased.month}-${this.packageForm.value.date_purchased.day}`,
          totalPayments: this.packageForm.value.totalPayments,
          number_payments: this.packageForm.value.number_payments,
          advancePayment: this.packageForm.value.advance_payments,
          remainingTotal: this.packageForm.value.remaining_total,
          is_gift: this.packageForm.value.is_empty,
          sells: this.packageForm.value.sells,
          social: this.packageForm.value.social,
          resale: this.packageForm.value.resale,
          type: this.packageForm.value.type,
          employeeId: this.packageForm.value.employee_id,
          client_referer: this.packageForm.value.client_referer,
          custom_sessions: this.packageForm.value.custom_sessions,
          paymentMethodId: Number(this.packageForm.value.paymentMethodId),
          paymentId: Number(this.packageForm.value.paymentId),
          type_package: Number(this.packageForm.value.type_package),
          imageData: this.packageForm.value.imageData,
          observations: this.packageForm.value.observations,
          custom_clients: this.packageForm.value.custom_clients,
          is_shared: this.packageForm.value.is_shared,
          clients_shared: this.packageForm.value.clientShared,
          terminal:
            this.packageForm.value.terminal === 0
              ? '0'
              : this.packageForm.value.terminal,
          isOld: this.packageForm.value.isOld,
          extraAreas: this.packageForm.value.extraData,
          edsId: this.packageForm.value.edsId,
          subtype_package: this.packageForm.value.subtype_package,
        };
      }
    }

    /*const flagResults = this.validateFieldsRequired(typeCheck, objValidation);

    if (flagResults) {
      this.flagErrors = true;
      this.packageForm.invalid;
      return;
    }*/

    this.api.create('packages', obj).subscribe({
      next: (packages) => {
        this.submitted = false;
        this.flagErrors = false;
        this.isSaved = true;
        this.totalLabel = 0;

        if (packages.code == 501) {
          this.responseMessage = packages.message;
          this._error.next(this.responseMessage);
        } else {
          this.isDisplayed = false;
          this.responseMessage =
            packages.message + ' La pagina se recargara en unos instantes...';
          this._error.next(this.responseMessage);
          this.resetPackageForm();
        }
      },
      error: (err) => {},
    });
  }

  extraChecks(): void {
    const newTypePackage =
      this.packageForm.value.type_package == 1
        ? this.packageForm.value.subtype_package
        : this.packageForm.value.type_package;
    /*if (
      this.packageForm.value.type_package == 2 ||
      this.packageForm.value.type_package == 1
    )*/
    if (newTypePackage == 1 || newTypePackage == 2) {
      const result = this.maxPercentage(this.packageForm.value.percentage);

      if (result === 0) {
        this.flagErrors = true;
        this.flagPercentage = true;

        return;
      }

      this.flagErrors = false;
      this.flagPercentage = false;
      return;
    }

    this.flagErrors = false;
    this.flagPercentage = false;
    return;
  }

  resetPackageForm() {
    this.packageForm.reset();

    // reset form array
    this.totalExtraData().reset();

    this.areaId = 0;
    this.packageForm.get('client_one').reset();
    this.packageForm.get('areas_one').reset();
    this.packageForm.get('areas_two').reset();
    this.packageForm.get('user_id').reset();
    this.packageForm.get('areas_dynamic').reset();
    this.packageForm.get('employee_id').reset();
    this.packageForm.get('imageData').reset();
    this.packageForm.patchValue({
      clientId: '',
      userId: '',
      areas_sessions: '',
      areas_gifts: '',
      client_referer: '',
      imageData: '',
      paymentId: 0,
    });
    this.myFile.nativeElement.value = '';

    this.socialFlag = false;
    this.referalFlag = false;
    this.resaleFlag = false;
    this.ocultar = false;
    this.flagSharedPackage = false;
    this.areasNames = [];
    this.totalFields = [];
    this.arrayNumber = [];
    this.totalLabel = 0;
    this.totalLabelColor = '';
    this.disabledButton = true;
    this.classNumber = 12;
    this.packageForm.patchValue({
      date_purchased: this.date_purchased_format,
    });

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  private clearData() {
    this.packageForm.get('subtotal').reset();
    this.packageForm.get('percentage').reset();
    this.packageForm.get('advance_payments').reset();
    this.packageForm.get('remaining_total').reset();
    this.packageForm.get('totalPayments').reset();
    this.packageForm.get('employee_id').reset();

    this.arrayNumber = [];
    this.totalLabel = 0;
    this.totalLabelColor = '';
    this.disabledButton = this.packageForm.value.is_empty ? false : true;
    this.packageForm.patchValue({
      date_purchased: this.date_purchased_format,
    });
  }
}
