<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Asignar empleados a cabina</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/cabins/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de cabinas
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ message }}
    </div>
    <form
      [formGroup]="cabinAssignForm"
      (ngSubmit)="updateUsersCabins()"
      novalidate
    >
      <div class="accordion" id="cabins_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cabin-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text">
                Actualizaci&oacute;n de usuarios por cabina
              </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <input type="hidden" name="cabinId" formControlName="cabinId" />
              <div class="row">
                <div class="col-md-5 mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="employeeOne"
                    name="employeeOne"
                    formControlName="employeeOne"
                  >
                    <option selected value="" class="">
                      Selecciona colaborador
                    </option>
                    <option
                      *ngFor="let employee of employees"
                      value="{{ employee.id }}"
                    >
                      {{ employee.first_name }} {{ employee.last_name }}
                    </option>
                  </select>
                  <label class="ms-2" for="employeeOne">
                    Selecciona una sucursal
                  </label>
                </div>
                <div class="col-md-5 mb-3 form-floating">
                  <input
                    type="hidden"
                    name="dayOne"
                    id="dayOne"
                    formControlName="dayOne"
                  />
                  <input
                    type="text"
                    disabled
                    value="{{ days[0].name }}"
                    class="form-control"
                  />
                  <label class="ms-2">Selecciona un dia</label>
                </div>
                <div class="col-md-2 mb-3 mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="restOne"
                    id="restOne"
                  />
                  <label class="form-check-label ms-2" for="restOne">
                    D&iacute;a de descanso
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="employeeTwo"
                    name="employeeTwo"
                    formControlName="employeeTwo"
                  >
                    <option selected value="" class="">
                      Selecciona colaborador
                    </option>
                    <option
                      *ngFor="let employee of employees"
                      value="{{ employee.id }}"
                    >
                      {{ employee.first_name }} {{ employee.last_name }}
                    </option>
                  </select>
                  <label class="ms-2" for="employeeTwo">
                    Selecciona una sucursal
                  </label>
                </div>
                <div class="col-md-5 mb-3 form-floating">
                  <input
                    type="hidden"
                    name="dayTwo"
                    id="dayTwo"
                    formControlName="dayTwo"
                  />
                  <input
                    type="text"
                    disabled
                    value="{{ days[1].name }}"
                    class="form-control"
                  />
                  <label class="ms-2">Selecciona un dia</label>
                </div>
                <div class="col-md-2 mb-3 mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="restTwo"
                    id="restTwo"
                  />
                  <label class="form-check-label ms-2" for="restTwo">
                    D&iacute;a de descanso
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="employeeThree"
                    name="employeeThree"
                    formControlName="employeeThree"
                  >
                    <option selected value="" class="">
                      Selecciona colaborador
                    </option>
                    <option
                      *ngFor="let employee of employees"
                      value="{{ employee.id }}"
                    >
                      {{ employee.first_name }} {{ employee.last_name }}
                    </option>
                  </select>
                  <label class="ms-2" for="employeeThree">
                    Selecciona una sucursal
                  </label>
                </div>
                <div class="col-md-5 mb-3 form-floating">
                  <input
                    type="hidden"
                    name="dayThree"
                    id="dayThree"
                    formControlName="dayThree"
                  />
                  <input
                    type="text"
                    disabled
                    value="{{ days[2].name }}"
                    class="form-control"
                  />
                  <label class="ms-2">Selecciona un dia</label>
                </div>
                <div class="col-md-2 mb-3 mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="restThree"
                    id="restThree"
                  />
                  <label class="form-check-label ms-2" for="restThree">
                    D&iacute;a de descanso
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="employeeFour"
                    name="employeeFour"
                    formControlName="employeeFour"
                  >
                    <option selected value="" class="">
                      Selecciona colaborador
                    </option>
                    <option
                      *ngFor="let employee of employees"
                      value="{{ employee.id }}"
                    >
                      {{ employee.first_name }} {{ employee.last_name }}
                    </option>
                  </select>
                  <label class="ms-2" for="employeeFour">
                    Selecciona una sucursal
                  </label>
                </div>
                <div class="col-md-5 mb-3 form-floating">
                  <input
                    type="hidden"
                    name="dayFour"
                    id="dayFour"
                    formControlName="dayFour"
                  />
                  <input
                    type="text"
                    disabled
                    value="{{ days[3].name }}"
                    class="form-control"
                  />
                  <label class="ms-2">Selecciona un dia</label>
                </div>
                <div class="col-md-2 mb-3 mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="restFour"
                    id="restFour"
                  />
                  <label class="form-check-label ms-2" for="restFour">
                    D&iacute;a de descanso
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="employeeFive"
                    name="employeeFive"
                    formControlName="employeeFive"
                  >
                    <option selected value="" class="">
                      Selecciona colaborador
                    </option>
                    <option
                      *ngFor="let employee of employees"
                      value="{{ employee.id }}"
                    >
                      {{ employee.first_name }} {{ employee.last_name }}
                    </option>
                  </select>
                  <label class="ms-2" for="employeeFive">
                    Selecciona una sucursal
                  </label>
                </div>
                <div class="col-md-5 mb-3 form-floating">
                  <input
                    type="hidden"
                    name="dayFive"
                    id="dayFive"
                    formControlName="dayFive"
                  />
                  <input
                    type="text"
                    disabled
                    value="{{ days[4].name }}"
                    class="form-control"
                  />
                  <label class="ms-2">Selecciona un dia</label>
                </div>
                <div class="col-md-2 mb-3 mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="restFive"
                    id="restFive"
                  />
                  <label class="form-check-label ms-2" for="restFive">
                    D&iacute;a de descanso
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="employeeSix"
                    name="employeeSix"
                    formControlName="employeeSix"
                  >
                    <option selected value="" class="">
                      Selecciona colaborador
                    </option>
                    <option
                      *ngFor="let employee of employees"
                      value="{{ employee.id }}"
                    >
                      {{ employee.first_name }} {{ employee.last_name }}
                    </option>
                  </select>
                  <label class="ms-2" for="employeeSix">
                    Selecciona una sucursal
                  </label>
                </div>
                <div class="col-md-5 mb-3 form-floating">
                  <input
                    type="hidden"
                    name="daySix"
                    id="daySix"
                    formControlName="daySix"
                  />
                  <input
                    type="text"
                    disabled
                    value="{{ days[5].name }}"
                    class="form-control"
                  />
                  <label class="ms-2">Selecciona un dia</label>
                </div>
                <div class="col-md-2 mb-3 mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="restSix"
                    id="restSix"
                  />
                  <label class="form-check-label ms-2" for="restSix">
                    D&iacute;a de descanso
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="employeeSeven"
                    name="employeeSeven"
                    formControlName="employeeSeven"
                  >
                    <option selected value="" class="">
                      Selecciona colaborador
                    </option>
                    <option
                      *ngFor="let employee of employees"
                      value="{{ employee.id }}"
                    >
                      {{ employee.first_name }} {{ employee.last_name }}
                    </option>
                  </select>
                  <label class="ms-2" for="employeeSeven">
                    Selecciona una sucursal
                  </label>
                </div>
                <div class="col-md-5 mb-3 form-floating">
                  <input
                    type="hidden"
                    name="daySeven"
                    id="daySeven"
                    formControlName="daySeven"
                  />
                  <input
                    type="text"
                    disabled
                    value="{{ days[6].name }}"
                    class="form-control"
                  />
                  <label class="ms-2">Selecciona un dia</label>
                </div>
                <div class="col-md-2 mb-3 mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="restSeven"
                    id="restSeven"
                  />
                  <label class="form-check-label ms-2" for="restSeven">
                    D&iacute;a de descanso
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Actualizar
        </button>
      </div>
    </form>
  </div>
</div>
