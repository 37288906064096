<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de cabinas</h3>
  </div>
  <hr />
  <div class="mb-3 row align-items-center">
    <!-- Search field section -->
    <div class="col-md-6">
      <div class="input-group">
        <input
          type="text"
          id="table-complete-search"
          name="searchText"
          class="form-control"
          placeholder="Buscar cabinas"
          [(ngModel)]="filterText"
          (keyup)="filterRecords()"
        />

        <select
          class="form-select ms-1"
          name="currentOfficeId"
          [(ngModel)]="currentOfficeId"
          id="currentOfficeId"
          (change)="setCurrentOffice($event)"
          *ngIf="rolekey == 'admin'"
        >
          <option value="0" selected="selected">Selecciona sucursal</option>
          <option
            *ngFor="let office of offices"
            value="{{ office.id }}"
            selected="{ office.id == roleofficeid ? 'selected' : '' }"
          >
            {{ office.name }}
          </option>
        </select>

        <button
          class="btn btn-outline-danger ms-1"
          type="button"
          (click)="clearSearch()"
        >
          Limpiar busqueda
        </button>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-grip gap-1 d-md-flex justify-content-md-end">
        <a [routerLink]="['/cabins/create']" class="btn btn-outline-success">
          <i class="bi bi-plus-circle"></i>
          Crear cabina
        </a>
      </div>
    </div>
  </div>

  <div class="alert alert-success" role="alert" *ngIf="disabledFlag">
    {{ message }}
  </div>
  <hr />

  <form>
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col">ID</th>
          <th class="col">Nombre de la cabina</th>
          <th class="col">Empleado(s) asignado(s)</th>
          <th class="col">Oficina</th>
          <th class="col">Activo?</th>
          <th class="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let cabin of cabinsFilter
              | paginate : { itemsPerPage: 15, currentPage: page }
          "
        >
          <td scope="row">
            {{ cabin.id }}
          </td>
          <td>
            {{ cabin.name }}
          </td>
          <td>
            {{ cabin.employee }}
          </td>
          <td>
            {{ cabin.office }}
          </td>
          <td>
            {{ cabin.enabled ? "Si" : "No" }}
          </td>
          <td>
            <div class="d-flex d-flex-row">
              <div>
                <a
                  [routerLink]="['/cabins/edit', cabin.id]"
                  placement="top"
                  ngbTooltip="Editar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-pencil-square"></i>
                </a>
              </div>
              <div class="ms-2">
                <a
                  (click)="disabledEnabledRecord(cabin.id)"
                  placement="top"
                  ngbTooltip="Habilitar / Deshabilitar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-eye-fill" *ngIf="cabin.enabled"></i>
                  <i class="bi bi-eye-slash-fill" *ngIf="!cabin.enabled"></i>
                </a>
              </div>
              <div
                class="ms-2"
                *ngIf="
                  rolekey == 'admin' || rolekey == 'gte' || rolekey == 'eds'
                "
              >
                <a
                  [routerLink]="['/cabins/users', cabin.id, cabin.office_id]"
                  placement="top"
                  ngbTooltip="Usuarios de cabina"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-people-fill"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12 d-flex align-items-center justify-content-center">
      <pagination-controls
        (pageChange)="page = $event"
        nextLabel="Siguiente"
        previousLabel="Anterior"
        class="mt-3"
      ></pagination-controls>
    </div>
  </form>
</div>

<ng-template #modalCabin let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">{{ messageDisEn }} cabina</h4>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Deseas {{ messageDisEn }} la cabina <strong>{{ nameCabin }}</strong>
    </p>
    <div class="row">
      <div class="d-grip gap-2 d-md-flex justify-content-md-end">
        <button
          type="button"
          (click)="disabledEnabledFunction(idDisabled)"
          class="btn btn-outline-success"
        >
          Confirmar
        </button>
        <button class="btn btn-outline-danger" type="button" (click)="close()">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</ng-template>
