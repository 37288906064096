import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-users-cabins',
  templateUrl: './users-cabins.component.html',
  styleUrls: ['./users-cabins.component.scss'],
})
export class UsersCabinsComponent implements OnInit {
  breadcrumbs: any = [];
  flagTypeForm: boolean = false;
  cabinAssignForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  message: string = '';
  paramId: string | null = '';
  officeId: string | null = '';
  employees: any = [];
  days: any = [
    { id: 1, name: 'Lunes' },
    { id: 2, name: 'Martes' },
    { id: 3, name: 'Miercoles' },
    { id: 4, name: 'Jueves' },
    { id: 5, name: 'Viernes' },
    { id: 6, name: 'Sabado' },
    { id: 0, name: 'Domingo' },
  ];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private caf: FormBuilder
  ) {}

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.officeId = this.route.snapshot.paramMap.get('officeId');

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de cabinas',
        path: '/cabins/list',
        link: true,
      },
      {
        name: 'Usuarios de la cabina',
        link: false,
      },
    ];

    this.validations();
    this.getEmployees();
    this.checkIfRecordExists();
  }

  validations() {
    this.cabinAssignForm = this.caf.group({
      cabinId: ['', [Validators.required]],
      dayOne: [1, [Validators.required]],
      dayTwo: [2, [Validators.required]],
      dayThree: [3, [Validators.required]],
      dayFour: [4, [Validators.required]],
      dayFive: [5, [Validators.required]],
      daySix: [6, [Validators.required]],
      daySeven: [0, [Validators.required]],
      employeeOne: ['', [Validators.required]],
      employeeTwo: ['', [Validators.required]],
      employeeThree: ['', [Validators.required]],
      employeeFour: ['', [Validators.required]],
      employeeFive: ['', [Validators.required]],
      employeeSix: ['', [Validators.required]],
      employeeSeven: ['', [Validators.required]],
      restOne: [''],
      restTwo: [''],
      restThree: [''],
      restFour: [''],
      restFive: [''],
      restSix: [''],
      restSeven: [''],
      cabinIdOne: [''],
      cabinIdTwo: [''],
      cabinIdThree: [''],
      cabinIdFour: [''],
      cabinIdFive: [''],
      cabinIdSix: [''],
      cabinIdSeven: [''],
    });

    this.cabinAssignForm.patchValue({
      cabinId: this.paramId,
    });
  }

  checkIfRecordExists() {
    this.api
      .get(`getAssigns/users/${this.officeId}/${this.paramId}`)
      .subscribe({
        next: (dataExists) => {
          if (dataExists.total === 1) {
            this.flagTypeForm = true;
            this.cabinAssignForm.patchValue({
              employeeOne: dataExists.cabin[0].userId,
              employeeTwo: dataExists.cabin[1].userId,
              employeeThree: dataExists.cabin[2].userId,
              employeeFour: dataExists.cabin[3].userId,
              employeeFive: dataExists.cabin[4].userId,
              employeeSix: dataExists.cabin[5].userId,
              employeeSeven: dataExists.cabin[6].userId,
              dayOne: dataExists.cabin[0].cabinDay,
              dayTwo: dataExists.cabin[1].cabinDay,
              dayThree: dataExists.cabin[2].cabinDay,
              dayFour: dataExists.cabin[3].cabinDay,
              dayFive: dataExists.cabin[4].cabinDay,
              daySix: dataExists.cabin[5].cabinDay,
              daySeven: dataExists.cabin[6].cabinDay,
              restOne: dataExists.cabin[0].restDay ? true : false,
              restTwo: dataExists.cabin[1].restDay ? true : false,
              restThree: dataExists.cabin[2].restDay ? true : false,
              restFour: dataExists.cabin[3].restDay ? true : false,
              restFive: dataExists.cabin[4].restDay ? true : false,
              restSix: dataExists.cabin[5].restDay ? true : false,
              restSeven: dataExists.cabin[6].restDay ? true : false,
              cabinIdOne: dataExists.cabin[0].id,
              cabinIdTwo: dataExists.cabin[1].id,
              cabinIdThree: dataExists.cabin[2].id,
              cabinIdFour: dataExists.cabin[3].id,
              cabinIdFive: dataExists.cabin[4].id,
              cabinIdSix: dataExists.cabin[5].id,
              cabinIdSeven: dataExists.cabin[6].id,
            });
          }
        },
        error: (err) => {},
      });
  }

  getEmployees() {
    this.api.get(`v1/employees/office/${this.officeId}`).subscribe({
      next: (employees) => {
        this.employees = employees.employees;
      },
      error: (err) => {},
    });
  }

  get f() {
    return this.cabinAssignForm.controls;
  }

  updateUsersCabins() {
    this.submitted = true;

    if (this.cabinAssignForm.invalid) {
      this.submitted = false;
      return;
    }

    const users = [
      this.cabinAssignForm.value.employeeOne,
      this.cabinAssignForm.value.employeeTwo,
      this.cabinAssignForm.value.employeeThree,
      this.cabinAssignForm.value.employeeFour,
      this.cabinAssignForm.value.employeeFive,
      this.cabinAssignForm.value.employeeSix,
      this.cabinAssignForm.value.employeeSeven,
    ];
    const restDays = [
      this.cabinAssignForm.value.restOne ? true : false,
      this.cabinAssignForm.value.restTwo ? true : false,
      this.cabinAssignForm.value.restThree ? true : false,
      this.cabinAssignForm.value.restFour ? true : false,
      this.cabinAssignForm.value.restFive ? true : false,
      this.cabinAssignForm.value.restSix ? true : false,
      this.cabinAssignForm.value.restSeven ? true : false,
    ];
    const cabinDay = [
      this.cabinAssignForm.value.dayOne,
      this.cabinAssignForm.value.dayTwo,
      this.cabinAssignForm.value.dayThree,
      this.cabinAssignForm.value.dayFour,
      this.cabinAssignForm.value.dayFive,
      this.cabinAssignForm.value.daySix,
      this.cabinAssignForm.value.daySeven,
    ];

    let cabinsId;
    if (this.flagTypeForm) {
      cabinsId = [
        this.cabinAssignForm.value.cabinIdOne,
        this.cabinAssignForm.value.cabinIdTwo,
        this.cabinAssignForm.value.cabinIdThree,
        this.cabinAssignForm.value.cabinIdFour,
        this.cabinAssignForm.value.cabinIdFive,
        this.cabinAssignForm.value.cabinIdSix,
        this.cabinAssignForm.value.cabinIdSeven,
      ];
    }

    const obj = {
      employees: users,
      days: restDays,
      assignations: cabinDay,
      officeId: this.officeId,
      cabinId: this.cabinAssignForm.value.cabinId,
      cabinsId: this.flagTypeForm ? cabinsId : [],
    };

    const urlSendData = this.flagTypeForm
      ? 'update/assing/users/cabin'
      : 'assign/users/cabin';
    this.api.create(urlSendData, obj).subscribe({
      next: (data) => {
        this.message = data.message;
        this.isSaved = true;
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.message = '';
      this.isSaved = false;
      this.submitted = false;
    }, 3000);
  }
}
