<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Actualizar areas</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/areas/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de areas
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ message }}
    </div>

    <form [formGroup]="areasUpdate" (ngSubmit)="updateAreas()" novalidate>
      <div class="accordion" id="areas_accordion">
        <h2 class="accordion-header" id="cities-information">
          <button
            class="accordion-button color-header-collapse"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            disabled
          >
            <span class="color-text"> Actualizaci&oacute;n de Area </span>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div class="row">
              <input type="hidden" formControlName="officeId" id="officeId" />
              <input type="hidden" formControlName="areaId" id="areaId" />
              <div class="col-md-6 mb-3 form-floating">
                <input
                  type="text"
                  id="areasName"
                  formControlName="areasName"
                  class="form-control"
                  placeholder="Nombre del area"
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.areasName.errors,
                    'is-valid': submitted && !f.areasName.errors
                  }"
                />
                <label for="area" class="ms-2">Nombre del area</label>
              </div>

              <div class="col-md-6 mb-3 form-floating">
                <select
                  class="form-select"
                  aria-label="floating label select"
                  id="areasSize"
                  name="areasSize"
                  formControlName="areasSize"
                >
                  <option selected value="">Selecciona un tama&ntilde;o</option>
                  <option value="3">Chica</option>
                  <option value="2">Mediana</option>
                  <option value="1">Grande</option>
                </select>
                <label for="areasSize" class="ms-2">
                  Selecciona un tama&ntilde;o
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3 form-floating">
                <div class="input-group mb-3">
                  <span class="input-group-text">Duraci&oacute;n</span>
                  <input
                    style="height: 3.5rem !important"
                    type="text"
                    class="form-control"
                    id="durationHour"
                    formControlName="durationHour"
                    placeholder="Horas"
                    aria-label="Horas"
                  />
                  <span class="input-group-text">:</span>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="durationMin"
                    id="durationMin"
                    placeholder="Minutos"
                    aria-label="Minutos"
                  />
                </div>
              </div>
              <div class="col-md-6 mb-3 form-floating">
                <input
                  type="text"
                  id="price"
                  formControlName="price"
                  class="form-control"
                  placeholder="Precio del area"
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.price.errors,
                    'is-valid': submitted && !f.price.errors
                  }"
                />
                <label for="price" class="ms-2">Precio del area</label>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3 mt-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="fullBody"
                  id="fullBody"
                />
                <label class="form-check-label ms-2" for="fullBody">
                  Es full body el area?
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Actualizar
        </button>
      </div>
    </form>
  </div>
</div>
